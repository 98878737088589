import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import { BookDemoFromLead, DemoDetails } from 'managers/Demos.js';
import AltFieldMapper, { AltFieldItem, validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import Button from 'views/Button.js';
import CallLog from 'classes/CallLog.js';
import Checkbox from 'views/Checkbox.js';
import Content from 'managers/Content.js';
import Cookies from 'js-cookie';
import CreditsPicker from 'views/CreditsPicker.js';
import CustomListField from 'views/CustomListField.js';
import Dealership from 'classes/Dealership.js';
import DealershipLookupField from 'views/DealershipLookupField.js';
import { DealershipSelector, DealershipDetails } from 'managers/Dealerships.js';
import Demo from 'classes/Demo.js';
import FieldMapper, { formatFields } from 'views/FieldMapper.js';
import FilePickerField from 'views/FilePickerField.js';
import Layer, { LayerItem, ToolbarHeight, getLayerSizingHeight } from 'structure/Layer.js';
import Lead from 'classes/Lead.js';
import LeadTypePickerField from 'views/LeadTypePickerField.js';
import LeadScriptPickerField from 'views/LeadScriptPickerField.js';
import LeadSubTypePickerField from 'views/LeadSubTypePickerField.js';
import LeadScriptEditor, { RebuttalsCollection, formatLeadScriptContent } from 'views/LeadScriptEditor.js';
import ListField from 'views/ListField.js';
import LottieView from 'views/Lottie.js';
import { Map } from 'views/MapElements.js';
import { MessageComponent } from 'managers/Messaging.js';
import Panel from 'structure/Panel.js';
import PageControl from 'views/PageControl.js';
import { PaymentsOverview, SystemEventDetails, SystemEventsLayerItem, UserDetails } from 'managers/Users.js';
import PrintContent from 'views/PrintContent.js';
import Program from 'classes/Program.js';
import { ProgramDetails } from 'managers/Programs.js';
import ProgressBar from 'views/ProgressBar.js';
import LeadLookupField from 'views/LeadLookupField.js';
import Request from 'files/Request.js';
import Scheduler from 'views/Scheduler.js';
import StatusCodeFilters from 'views/StatusCodeFilters.js';
import SystemEvent from 'classes/SystemEvent.js';
import TableListHeader from 'views/TableListHeader.js';
import TagLookupField from 'views/TagLookupField.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import UserLookupField from 'views/UserLookupField.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views, { AltBadge, AsyncViewComponent } from 'views/Main.js';

export const CallLogs = ({ index, options, utils }) => {

    const panelID = 'call_logs';
    const limit = 15;
    const offset = useRef(0);
    const sorting = useRef(null);

    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState([]);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onLogClick = log => {
        utils.layer.open({
            abstract: Abstract.create({
                object: log,
                type: 'call_log'
            }),
            Component: CallLogDetails,
            id: `call_log_details_${log.id}`,
            permissions: ['calls.details']
        });
    }

    const onPrintLogs = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { logs } = await Request.get(utils, '/dealerships/', {
                    type: 'call_logs',
                    search_text: searchText,
                    ...props
                })

                setLoading(false);
                resolve(logs.map(log => CallLog.create(log)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const getContent = () => {
        if(logs.length === 0) {
            return (
                Views.entry({
                    title: 'No Call or Emails Found',
                    subTitle: 'No calls or emails were found in the system',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {logs.map((log, index) => {
                        return getFields(log, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (log, index) => {

        let target = log || {};
        let fields = [{
            key: 'lead_full_name',
            title: 'Lead',
            value: target.lead_full_name
        },{
            key: 'assign_to',
            title: 'Assigned To',
            value: target.assign_to ? target.assign_to.full_name : 'Not Assigned'
        },{
            key: 'start_date',
            title: 'Date',
            value: target.start_date ? moment(target.start_date).format('MMMM Do, YYYY [at] h:mma') : null
        },{
            key: 'method',
            title: 'Method',
            value: target.method ? Utils.ucFirst(target.method) : null
        },{
            key: 'direction',
            title: 'Direction',
            value: (
                <div style={{
                    alignItems: 'center',
                    background: Appearance.colors.softGradient(target.direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green),
                    border: `1px solid ${target.direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green}`,
                    borderRadius: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    maxWidth: 95,
                    overflow: 'hidden',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: 'white',
                        fontWeight: '600',
                        width: '100%'
                    }}>{target.direction ? Utils.ucFirst(target.direction) : null}</span>
                </div>
            )
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!log) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => {
                    sorting.current = props;
                    fetchLogs();
                }}
                value={sorting.current} />
            )
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}
                        onClick={onLogClick.bind(this, log)}>
                            <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                        </td>
                    )
                })}
            </tr>
        )
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintLogs,
            onRenderItem: (item, index, items) => ({
                lead_full_name: item.lead_full_name,
                assign_to: item.assign_to ? item.assign_to.full_name : 'Not Assigned',
                start_date: item.start_date ? moment(item.start_date).format('MMMM Do, YYYY [at] h:mma') : null,
                method: item.method ? Utils.ucFirst(item.method) : null,
                direction: item.direction ? Utils.ucFirst(item.direction) : null
            }),
            headers: [{
                key: 'lead_full_name',
                title: 'Lead'
            },{
                key: 'assign_to',
                title: 'Assigned To'
            },{
                key: 'start_date',
                title: 'Date'
            },{
                key: 'method',
                title: 'Method'
            },{
                key: 'direction',
                title: 'Direction'
            }]
        }
    }

    const fetchLogs = async () => {
        try {
            setLoading(true);
            let { logs, paging } = await Request.get(utils, '/dealerships/', {
                type: 'call_logs',
                offset: offset.current,
                search_text: searchText,
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setLogs(logs.map(log => CallLog.create(log)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of call logs. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        offset.current = 0;
        fetchLogs();
    }, [searchText]);


    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchLogs);
        utils.content.subscribe(panelID, ['call_log'], {
            onFetch: fetchLogs,
            onUpdate: abstract => {
                setLogs(logs => logs.map(prevLog => {
                    return prevLog.id === abstract.getID() ? abstract.object : prevLog;
                }))
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchLogs);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Calls and Emails'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            search: {
                placeholder: 'Search by Lead name or Assignment name...',
                onChange: setSearchText
            },
            print: getPrintProps(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchLogs();
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const CallLogsCalendar = ({ index, options, utils }) => {

    const panelID = 'call_logs_calendar';
    const limit = 15;

    const [loading, setLoading] = useState(null);
    const [logs, setLogs] = useState([]);
    const [offset, setOffset] = useState(0);
    const [operatingHours, setOperatingHours] = useState(Utils.getDefaultOperatingHours());
    const [paging, setPaging] = useState(null);
    const [panelWidth, setPanelWidth] = useState(0);
    const [resources, setResources] = useState([]);
    const [showInactive, setShowInactive] = useState(false);
    const [targetDate, setTargetDate] = useState(moment());

    const onEventClick = evt => {
        utils.layer.open({
            abstract: Abstract.create({
                object: evt.log,
                type: 'call_log'
            }),
            Component: CallLogDetails,
            id: `call_log_details_${evt.log.id}`,
            permissions: ['calls.details']
        });
    }

    const onLogAdded = data => {
        try {
            if(data.from_user === utils.user.get().user_id) {
                return;
            }

            let { log } = data;
            let nextLogs = [{
                id: log.id,
                start: log.start_date,
                end: log.end_date,
                resource_id: log.assign_to ? log.assign_to.user_id : -1,
                title: `${Utils.ucFirst(log.direction)} ${log.method === 'phone' ? 'Phone Call' : 'Email'} for ${log.lead_full_name}`,
                color: log.direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green,
                log: CallLog.create(log)
            }];
            if(log.follow_up_start_date) {
                nextLogs.push({
                    id: `follow_up_${log.id}`,
                    start: log.follow_up_start_date,
                    end: moment(log.follow_up_start_date).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                    resource_id: log.assign_to ? log.assign_to.user_id : -1,
                    title: `Follow Up Call for ${log.lead_full_name}`,
                    color: Appearance.colors.grey(),
                    log: CallLog.create(log)
                })
            }
            setLogs(logs => update(logs, {
                $push: nextLogs
            }))

        } catch(e) {
            console.error(e.message);
        }
    }

    const onLogUpdated = data => {
        try {
            setLogs(logs => logs.map(log => {
                if(log.id === data.log.id || log.id === `follow_up_${data.log.id}`) {
                    return {
                        ...log,
                        ...data.log,
                        start: data.log.start_date,
                        end: data.log.end_date,
                        color: data.log.direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green
                    }
                }
                return log;
            }))
        } catch(e) {
            console.error(e.message);
        }
    }

    const onLogDeleted = data => {
        try {
            setLogs(logs => logs.filter(log => {
                return log.id !== data.id && log.id !== `follow_up_${data.log.id}`;
            }))
        } catch(e) {
            console.error(e.message);
        }
    }

    const onNewEvent = evt => {

        let lead = null;
        let method = null;
        let direction = null;

        utils.alert.showAsync({
            title: 'New Call Log',
            message: 'Please choose a Lead, method, and direction from the lists below to continue',
            content: (
                <div style={{
                    width: '100%',
                    padding: 12
                }}>
                    <LeadLookupField
                    utils={utils}
                    placeholder={'Search for a Lead...'}
                    onChange={selected => lead = selected}
                    containerStyle={{
                        marginBottom: 12
                    }}/>

                    <select
                    className={`custom-select ${window.theme}`}
                    onChange={e => {
                        method = Utils.attributeForKey.select(e, 'id');
                    }}
                    style={{
                        marginBottom: 12
                    }}>
                        <option>{'Choose a method...'}</option>
                        <option id={'email'}>{'Email'}</option>
                        <option id={'phone'}>{'Phone Call'}</option>
                    </select>

                    <select
                    className={`custom-select ${window.theme}`}
                    onChange={e => {
                        direction = Utils.attributeForKey.select(e, 'id');
                    }}
                    style={{
                        marginBottom: 12
                    }}>
                        <option>{'Choose a direction...'}</option>
                        <option id={'inbound'}>{'Inbound'}</option>
                        <option id={'outbound'}>{'Outbound'}</option>
                    </select>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    if(!lead || !method || !direction) {
                        return;
                    }
                    onNewEventConfirm(evt, lead, method, direction);
                    return;
                }
            }
        })
    }

    const onNewEventConfirm = async (evt, lead, method, direction) => {
        try {

            setLoading(true);
            await Utils.sleep(0.5);

            let { id, log } = await Request.post(utils, '/dealerships/', {
                type: 'new_call_log',
                start_date: moment(evt.start).format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(evt.end).format('YYYY-MM-DD HH:mm:ss'),
                author: utils.user.get().user_id,
                method: method,
                direction: direction,
                assign_to: evt.id,
                lead_id: lead.id
            });

            setLoading(false);
            utils.content.fetch('call_log');

            let resource = resources.find(resource => resource.id === evt.id);
            setLogs(events => update(events, {
                $push: [{
                     id: id,
                     available: true,
                     start: evt.start,
                     end: evt.end,
                     resource_id: evt.id,
                     title: `${Utils.ucFirst(direction)} ${method === 'phone' ? 'Phone Call' : 'Email'} for ${lead.full_name}`,
                     color: direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green,
                     log: CallLog.create(log)
                }]
            }))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating the call log. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onRenderCallout = ({ evt, title, start, end, color }) => {
        return (
            <div style={{
                maxWidth: 350
            }}>
                {Views.entry({
                    title: title,
                    subTitle: `${moment(start).format('h:mma')} to ${moment(end).format('h:mma')}`,
                    hideIcon: evt.log.assign_to ? false : true,
                    icon: {
                        path: evt.log.assign_to ? evt.log.assign_to.avatar : null
                    },
                    borderBottom: false
                })}
            </div>
        )
    }

    const onRenderEvent = (evt, props) => {
        let { color, log } = evt;
        return (
            <div
            {...props}
            className={`pinstripes ${props.className || ''}`}
            style={{
                backgroundColor: color,
                borderRadius: 25,
                overflow: 'hidden',
                ...props.style
            }}>
                <div style={{
                    paddingTop: 6,
                    paddingBottom: 6
                }}>
                    <img
                    src={log.method === 'email' ? 'images/email-icon-white-small.png' : 'images/phone-icon-white-small.png'}
                    style={{
                        height: 20,
                        width: log.method === 'email' ? 18 : 20,
                        objectFit: 'contain',
                        marginLeft: 12,
                        marginRight: 12
                    }} />
                </div>
            </div>
        )
    }

    const onRenderResource = resource => {
        return (
            Views.entry({
                title: utils.groups.apply([ 'first_name', 'last_name' ], User.Group.categories.users, resource.full_name),
                subTitle: utils.groups.apply('phone_number', User.Group.categories.users, resource.phone_number),
                icon: {
                    path: resource.avatar,
                    imageStyle: {
                        boxShadow: null
                    }
                },
                bottomBorder: false,
                onClick: onUserClick.bind(this, resource.user_id)
            })
        )
    }

    const onUpdateEvent = ({ prev_evt, next_evt }) => {

        // build array of changes
        let changes = [];
        if(prev_evt.start !== next_evt.start || prev_evt.end !== next_evt.end) {
            changes.push(`reschedule this ${next_evt.log.method === 'email' ? 'email' : 'phone call'} for ${Utils.formatDate(next_evt.start)} to ${Utils.formatDate(next_evt.end)}`);
        }
        if(prev_evt.resource_id !== next_evt.resource_id) {
            let prev_user = resources.find(resource => resource.user_id === prev_evt.resource_id);
            let next_user = resources.find(resource => resource.user_id === next_evt.resource_id);
            changes.push(`change the assignment from ${prev_user.full_name} to ${next_user.full_name}`);
        }

        // prevent moving forward if no valid changes were found
        if(changes.length === 0) {
            return;
        }

        // request confirmation to save changes
        utils.alert.show({
            title: `Update ${next_evt.log.method === 'email' ? 'Email' : 'Phone Call'}`,
            message: `Are you sure that you want to ${Utils.oxfordImplode(changes)}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUpdateEventConfirm(next_evt);
                    return;
                }
            }
        })
    }

    const onUpdateEventConfirm = async evt => {
        try {

            // update follow up call date
            if(isNaN(evt.id)) {
                let logID = evt.id.replace('follow_up_', '');
                await Request.post(utils, '/dealerships/', {
                    type: 'update_call_log',
                    id: logID,
                    direction: evt.log.direction,
                    method: evt.log.method,
                    assign_to: evt.resource_id || (evt.log.assign_to ? evt.log.assign_to.user_id : null),
                    start_date: moment(evt.log.start_date).format('YYYY-MM-DD HH:mm:ss'),
                    end_date: moment(evt.log.end_date).format('YYYY-MM-DD HH:mm:ss'),
                    follow_up_start_date: moment(evt.start).format('YYYY-MM-DD HH:mm:ss'),
                    follow_up_end_date: moment(evt.end).format('YYYY-MM-DD HH:mm:ss'),
                })
                setLogs(logs => {
                    return logs.map(prev_log => {
                        if(prev_log.id === logID) {
                            prev_log.start = evt.start;
                            prev_log.end = evt.end;
                            prev_log.resource_id = evt.resource_id;
                            prev_log.log.follow_up_start_date = moment(evt.start);
                            prev_log.log.follow_up_end_date = moment(evt.end);
                            prev_log.log.assign_to = resources.find(resource => {
                                return resource.id === evt.resource_id;
                            })
                        }
                        return prev_log;
                    })
                });
                return;
            }

            // update base call log
            await Request.post(utils, '/dealerships/', {
                type: 'update_call_log',
                id: evt.id,
                direction: evt.log.direction,
                method: evt.log.method,
                assign_to: evt.resource_id || (evt.log.assign_to ? evt.log.assign_to.user_id : null),
                start_date: moment(evt.start).format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(evt.end).format('YYYY-MM-DD HH:mm:ss'),
                follow_up_start_date: evt.log.follow_up_start_date ? moment(evt.log.follow_up_start_date).format('YYYY-MM-DD HH:mm:ss') : null,
                follow_up_end_date: evt.log.follow_up_end_date ? moment(evt.log.follow_up_end_date).format('YYYY-MM-DD HH:mm:ss') : null,
            })

            setLogs(logs => {
                return logs.map(prev_log => {
                    if(prev_log.id === evt.id) {
                        prev_log.start = evt.start;
                        prev_log.end = evt.end;
                        prev_log.resource_id = evt.resource_id;
                        prev_log.log.start_date = moment(evt.start);
                        prev_log.log.end_date = moment(evt.end);
                        prev_log.log.assign_to = resources.find(resource => {
                            return resource.id === evt.resource_id;
                        })
                    }
                    return prev_log;
                })
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this call. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUserClick = async userID => {
        try {
            let user = await User.get(utils, userID);
            utils.layer.open({
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${userID}`,
                permissions: ['users.details']
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getLogs = user => {
        return logs.filter(entry => {
            return entry.log.assign_to && entry.log.assign_to.user_id === user.user_id;
        })
    }

    const fetchLogs = async () => {
        try {
            let { logs, operating_hours, paging, users } = await Request.get(utils, '/dealerships/', {
                type: 'call_log_calendar',
                limit: limit,
                offset: offset,
                start_date: targetDate.format('YYYY-MM-DD'),
                end_date: moment(targetDate).add(7, 'days').format('YYYY-MM-DD'),
                show_inactive: showInactive
            });

            setPaging(paging);
            setResources(users);
            if(operating_hours) {
                setOperatingHours(operating_hours);
            }

            setLogs(logs.reduce((array, log) => {
                let nextLogs = [{
                    id: log.id,
                    start: log.start_date,
                    end: log.end_date,
                    resource_id: log.assign_to ? log.assign_to.user_id : null,
                    title: `${Utils.ucFirst(log.direction)} ${log.method === 'phone' ? 'Phone Call' : 'Email'} for ${log.lead_full_name}`,
                    color: log.direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green,
                    log: CallLog.create(log)
                }];
                if(log.follow_up_start_date) {
                    nextLogs.push({
                        id: `follow_up_${log.id}`,
                        start: log.follow_up_start_date,
                        end: log.follow_up_end_date || moment(log.follow_up_start_date).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                        resource_id: log.assign_to ? log.assign_to.user_id : null,
                        title: `Follow Up Call for ${log.lead_full_name}`,
                        color: Appearance.colors.grey(),
                        log: CallLog.create(log)
                    })
                }
                return array.concat(nextLogs);
            }, []))

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the calls calendar. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchLogs();
    }, [offset, showInactive, targetDate ]);

    useEffect(() => {

        utils.events.on(panelID, 'dealership_change', fetchLogs);
        utils.events.on(panelID, 'dealership_preferences_update', fetchLogs);
        utils.content.subscribe(panelID, ['call_log'], {
            onFetch: fetchLogs,
            onUpdate: fetchLogs
        });

        let dealershipID = utils.dealership.get().id;
        utils.sockets.on('dealerships', `on_add_call_log_${dealershipID}`, onLogAdded);
        utils.sockets.on('dealerships', `on_update_call_log_${dealershipID}`, onLogUpdated);
        utils.sockets.on('dealerships', `on_delete_call_log_${dealershipID}`, onLogDeleted);

        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_preferences_update', fetchLogs);
            utils.events.off(panelID, 'dealership_change', fetchLogs);
            utils.sockets.off('dealerships', `on_add_call_log_${dealershipID}`, onLogAdded);
            utils.sockets.off('dealerships', `on_update_call_log_${dealershipID}`, onLogUpdated);
            utils.sockets.off('dealerships', `on_delete_call_log_${dealershipID}`, onLogDeleted);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        index={index}
        utils={utils}
        name={'Call and Email Assignments'}
        options={{
            ...options,
            loading: loading,
            onSizeChange: ({ width }) => setPanelWidth(width - 30),
            buttons: [{
                key: 'active',
                title: `${showInactive ? 'Hide' : 'Show'} Inactive`,
                style: showInactive ? 'default' : 'grey',
                onClick: () => {
                    setOffset(0);
                    setShowInactive(status => !status);
                }
            }]
        }}>
            <div style={{
                overflow: 'hidden',
                width: '100%'
            }}>
                <Scheduler
                minuteSteps={5}
                width={panelWidth}
                utils={utils}
                events={logs}
                resources={resources}
                operatingHours={operatingHours}
                onDateChange={date => setTargetDate(date)}
                onNew={onNewEvent}
                onClick={onEventClick}
                onRenderEvent={onRenderEvent}
                onRenderCallout={onRenderCallout}
                onRenderResource={onRenderResource}
                onUpdate={onUpdateEvent}
                options={{
                    headerLabel: 'First and Last Name',
                    resizable: true,
                    moveable: true,
                    crossResourceMove: true
                }} />
                <PageControl
                data={paging}
                limit={limit}
                offset={offset}
                onClick={next => setOffset(next)}
                style={{
                    borderTop: 'none',
                    paddingTop: 0
                }}/>
            </div>
        </Panel>
    )
}

export const CallEmailLogs = ({ upcoming }, { index, options, utils }) => {

    const panelID = `${upcoming ? 'upcoming_' : ''}call_email_logs`;
    const limit = 15;

    const _filterProps = useRef({});
    const textTimeout = useRef(null);
    const _searchProps = useRef({ text: null });

    const [callLogs, setCallLogs] = useState([]);
    const [filterProps, setFilterProps] = useState(null);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState([]);
    const [searchProps, setSearchProps] = useState({});
    const [selecting, setSelecting] = useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedOffset, setSelectedOffset] = useState(0);
    const [selectedPaging, setSelectedPaging] = useState(null);
    const [sorting, setSorting] = useState(null);

    const onAssignToUser = () => {

        let tmpValue = null;
        utils.alert.show({
            title: 'Assign to User',
            message: `Who would you like to assign ${selected.length === 1 ? 'this call log':'these call logs'} to?`,
            content: (
                <div style={{
                    width: '100%',
                    padding: 12
                }}>
                    <UserLookupField
                    utils={utils}
                    placeholder={'Search by first or last name...'}
                    onChange={user => tmpValue = user} />
                </div>
            ),
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Assign',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'done' && tmpValue) {
                    onAssignToUserConfirm(tmpValue);
                    return;
                }
            }
        })
    }

    const onAssignToUserConfirm = async user => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/dealerships/', {
                type: 'assign_call_logs_batch',
                ids: selected.map(log => log.id),
                user_id: user.user_id
            });

            setLoading(false);
            utils.content.fetch('call_log');
            utils.alert.show({
                title: 'All Done!',
                message: `We have assigned ${selected.length === 1 ? 'this call log':'these call logs'} to ${user.full_name}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue assigning ${selected.length === 1 ? 'this call log':'these call logs'} to ${user.full_name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onClearCalls = () => {
        utils.alert.show({
            title: 'Clear Call Logs',
            message: 'Are you sure that you want to clear your list of selected calls and emails?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Clear',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setSelected([]);
                    return;
                }
            }
        })
    }

    const onClick = log => {

        // add to list of selected leads if "selecting" is enabled
        if(selecting) {
            if(selected.find(prevLog => {
                return prevLog.id === log.id;
            })) {
                return;
            }
            let nextLogs = update(selected, {
                $unshift: [log]
            });
            setSelected(nextLogs);
            return;
        }

        // open details for lead if "selecting" is not enabled
        utils.layer.open({
            abstract: Abstract.create({
                object: log,
                type: 'call_log'
            }),
            Component: CallLogDetails,
            id: `call_log_details_${log.id}`,
            permissions: ['calls.details']
        })
    }

    const onContinueClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'print',
                title: 'Print',
                style: 'default'
            },{
                key: 'download',
                title: 'Download',
                style: 'default'
            },{
                key: 'assign',
                title: 'Assign to User',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'print') {
                onPrintLogs();
                return
            }
            if(key === 'download') {
                utils.alert.dev();
                return;
            }
            if(key === 'assign') {
                onAssignToUser();
                return
            }
        })
    }

    const onLogClick = log => {
        utils.layer.open({
            abstract: Abstract.create({
                object: log,
                type: 'call_log'
            }),
            Component: CallLogDetails,
            id: `call_log_details_${log.id}`,
            permissions: ['calls.details']
        });
    }

    const onPrintLogs = () => {

        utils.layer.open({
            id: 'print_content',
            Component: PrintContent.bind(this, {
                title: 'Call Logs',
                onFetch: async () => new Promise(resolve => resolve(selected)), // promise used to conform to panel print requirements
                onRenderItem: (item, index, items) => ({
                    lead_full_name: item.lead_full_name,
                    assign_to: item.assign_to ? item.assign_to.full_name : 'Not Assigned',
                    follow_up_start_date: item.follow_up_start_date ? moment(item.follow_up_start_date).format('MMMM Do, YYYY [at] h:mma') : null,
                    method: item.method ? Utils.ucFirst(item.method) : null,
                    direction: item.direction ? Utils.ucFirst(item.direction) : null
                }),
                headers: [{
                    key: 'lead_full_name',
                    title: 'Lead'
                },{
                    key: 'assign_to',
                    title: 'Assigned To'
                },{
                    key: 'follow_up_start_date',
                    title: 'Follow Up Date'
                },{
                    key: 'method',
                    title: 'Method'
                },{
                    key: 'direction',
                    title: 'Direction'
                }]
            })
        })
    }

    const onRemoveLog = log => {
        let nextLogs = update(selected, {
            $apply: logs => logs.filter(prevLog => {
                return prevLog.id !== log.id;
            })
        });
        setSelected(nextLogs);
    }

    const onSelectAllResults = async () => {
        try {
            let results = await fetchAllResults();
            setSelected(logs => {
                return logs.concat(results.filter(log => {
                    return logs.find(l => l.id === log.id) ? false : true;
                }))
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your search results. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onShowFilters = () => {
        utils.layer.open({
            id: 'call_email_filters',
            Component: CallEmailFilters.bind(this, {
                onChange: result => setFilterProps(result),
                upcoming: upcoming,
                value: filterProps
            })
        });
    }

    const onTextChange = text => {

        // clear previous search timeout if applicable
        if(textTimeout.current) {
            clearTimeout(textTimeout.current);
        }

        // start loading and update search props for text search
        setLoading(true);
        onUpdateSearchProps('text', text);
        textTimeout.current = setTimeout(fetchResults, 250);
    }

    const onUpdateSearchProps = (key, value) => {
        let nextProps = update(_searchProps.current, {
            [key]: {
                $set: value
            }
        });
        _searchProps.current = nextProps;
        setSearchProps(nextProps);
    }

    const getBadge = (key, value) => {
        return callLogs.reduce((total, log) => {
            switch(key) {
                case 'assign_to':
                if(!log.assign_to && value === -1) {
                    total++;
                }
                if(log.assign_to && log.assign_to.user_id === value) {
                    total++;
                }
                break;

                case 'author':
                if(log.author && log.author.user_id === value) {
                    total++;
                }
                break;

                case 'direction':
                if(log.direction === value) {
                    total++;
                }
                break;

                case 'follow_up':
                if(value === 'scheduled' && log.follow_up_start_date) {
                    total++;
                }
                if(value === 'not_scheduled' && !log.follow_up_start_date) {
                    total++;
                }
                break;

                case 'method':
                if(log.method === value) {
                    total++;
                }
                break;
            }
            return total;
        }, 0);
    }

    const getCalls = () => {
        if(results.length === 0) {
            return (
                Views.entry({
                    title: 'No Call Logs Found',
                    subTitle: 'No call logs were found in the system',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {results.map((log, index) => {
                        return getFields(log, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getContent = () => {

        // return standard list of calls if "selecting" is not enabled
        if(selecting === false) {
            return (
                <div className={`col-12 p-0 m-0`}>
                    <div style={Appearance.styles.unstyledPanel()}>
                        {getCalls()}
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset}
                        onClick={nextOffset => setOffset(nextOffset)}/>
                    </div>
                </div>
            )
        }

        // return selection resources if "selecting" is enabled
        return (
            <>
            <div className={`col-12 col-md-6 col-lg-8 pr-md-1 p-0 m-0`}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {getCalls()}
                    <PageControl
                    data={paging}
                    limit={limit}
                    offset={offset}
                    onClick={nextOffset => setOffset(nextOffset)}/>
                </div>
            </div>

            <div className={'col-12 col-md-6 col-lg-4 p-0 pl-md-1 m-0'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    height: '100%'
                }}>
                    <div
                    className={'px-3 py-2'}
                    style={{
                        borderBottom: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                        }}>{'Selected Calls'}</span>
                    </div>
                    <div style={{
                        flexGrow: 1
                    }}>
                        {getSelectedCalls()}
                    </div>
                    <PageControl
                    data={selectedPaging}
                    limit={limit}
                    offset={selectedOffset}
                    onClick={nextOffset => setSelectedOffset(nextOffset)}/>

                    <div
                    className={'row m-0'}
                    style={{
                        padding: 12,
                        width: '100%',
                        borderTop: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        {getSelectionButtons()}
                    </div>
                </div>
            </div>
            </>
        )
    }

    const getFields = (log, index) => {

        let target = log || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: log.author ? log.author.full_name : 'Name not available',
                    subTitle: moment(log.follow_up_start_date).format('MMMM Do, YYYY [at] h:mma'),
                    badge: [{
                        text: log.direction,
                        color: log.direction === 'outbound' ? Appearance.colors.grey() : Appearance.colors.primary()
                    },{
                        text: log.method,
                        color: Appearance.colors.secondary()
                    }],
                    hideIcon: true,
                    bottomBorder: true,
                    onClick: onLogClick.bind(this, log)
                })
            )
        }

        let fields = [{
            key: 'lead_full_name',
            title: 'Lead',
            value: target.lead_full_name
        },{
            key: 'assign_to',
            title: 'Assigned To',
            value: target.assign_to ? target.assign_to.full_name : 'Not Assigned'
        },{
            key: 'follow_up_start_date',
            permissions: ['calls.details.follow_up'],
            title: 'Follow Up Date',
            value: target.follow_up_start_date ? moment(target.follow_up_start_date).format('MMMM Do, YYYY [at] h:mma') : null,
        },{
            key: 'method',
            title: 'Method',
            value: target.method ? Utils.ucFirst(target.method) : null
        },{
            key: 'direction',
            title: 'Direction',
            value: (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    maxWidth: 95,
                    textAlign: 'center',
                    border: `1px solid ${target.direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green}`,
                    background: Appearance.colors.softGradient(target.direction === 'outbound' ? Appearance.colors.primary() : Appearance.colors.green),
                    borderRadius: 5,
                    overflow: 'hidden'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: 'white',
                        fontWeight: '600',
                        width: '100%'
                    }}>{target.direction ? Utils.ucFirst(target.direction) : null}</span>
                </div>
            )
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!log) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'lead_full_name',
                [Content.sorting.type.ascending]: 'follow_up_start_date',
                [Content.sorting.type.descending]: 'follow_up_start_date'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={props => setSorting(props)}
                {...sorting && sorting.general === true && {
                    value: {
                        key: sorts[sorting.sort_type],
                        direction: sorting.sort_type
                    }
                }} />
            )
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}
                        onClick={onClick.bind(this, log)}>
                            <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                        </td>
                    )
                })}
            </tr>
        )
    }

    const getSelectedCalls = () => {
        if(selected.length === 0) {
            return (
                <div
                className={'px-3 py-2'}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    height: 36,
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        flexGrow: 1
                    }}>{'No Call Logs Selected'}</span>
                </div>
            )
        }

        return selected.filter((_, index) => {
            if(selectedPaging.number_of_pages === 1) {
                return true;
            }
            let min = (limit * selectedPaging.current_page) - limit;
            let max = limit * selectedPaging.current_page;
            return index >= min && index < max;

        }).map((log, index, logs) => {
            return (
                <div
                key={index}
                className={'px-3 py-2'}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    height: 36,
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        flexGrow: 1
                    }}>{`${Utils.ucFirst(log.direction)} ${log.method === 'phone' ? 'Phone Call' : 'Email'} for ${log.lead_full_name}`}</span>
                    <img
                    className={'text-button'}
                    src={'images/red-x-icon.png'}
                    onClick={onRemoveLog.bind(this, log)}
                    style={{
                        width: 15,
                        height: 15,
                        objectFit: 'contain',
                        marginLeft: 8
                    }}/>
                </div>
            )
        })
    }

    const getSelectionButtons = () => {

        let all_selected = selected.length === callLogs.length;
        return (
            <>
            {!all_selected && (
                <div className={selected.length > 0 ? `col-12 ${all_selected ? 'col-md-6' : 'col-md-3'} p-1 px-md-1 py-md-0` : 'col-12 p-1'}>
                    <Button
                    type={'large'}
                    color={'secondary'}
                    label={selected.length > 0 ? 'Select All' : 'Select All Search Results'}
                    loading={loading === 'results'}
                    onClick={onSelectAllResults}/>
                </div>
            )}
            {selected.length > 0 && (
                <>
                <div className={`col-12 ${all_selected ? 'col-md-6' : 'col-md-3'} p-1 px-md-1 py-md-0`}>
                    <Button
                    type={'large'}
                    color={'dark'}
                    label={`Clear`}
                    onClick={onClearCalls}/>
                </div>
                <div className={'col-12 col-md-6 p-1 px-md-1 py-md-0'}>
                    <Button
                    type={'large'}
                    color={'primary'}
                    label={`Continue with ${Utils.softNumberFormat(selected.length)} ${selected.length === 1 ? 'Entry' : 'Entries'}`}
                    onClick={onContinueClick}/>
                </div>
                </>
            )}
            </>
        )
    }

    const fetchAllResults = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading('results');
                await Utils.sleep(0.5);

                let { logs, paging } = await Request.post(utils, '/dealerships/', {
                    type: 'call_log_advanced_search',
                    search_props: _searchProps.current,
                    ...sorting
                })

                setLoading(false);
                resolve(logs.map(log => CallLog.create(log)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    };

    const fetchResults = async () => {
        try {
            let { logs, paging } = await Request.post(utils, '/dealerships/', {
                type: 'call_log_advanced_search',
                limit: limit,
                offset: offset,
                search_props: _searchProps.current,
                filter_props: _filterProps.current,
                upcoming: upcoming,
                ...sorting
            })
            setLoading(false);
            setPaging(paging);
            setResults(logs.map(log => CallLog.create(log)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue searching for call logs. ${e.message || 'An unknown error occurred'}`
            });
        }
    };

    const fetchCallLogs = async () => {
        try {
            // prevent multiple use effect requests from firing at once
            if(_searchProps.current.in_progress === true) {
                return;
            }
            _searchProps.current.in_progress = true;
            let { logs } = await Request.get(utils, '/dealerships/', {
                type: 'call_logs',
                search_text: _searchProps.current.text,
                upcoming: upcoming,
                print: true, // disables limit and paging
                ...sorting
            });

            _searchProps.current.in_progress = false;
            setCallLogs(logs.map(log => CallLog.create(log)));

        } catch(e) {
            _searchProps.current.in_progress = false;
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the dealership list of call logs. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setLoading(true);
        fetchResults();
    }, [filterProps, offset, searchProps, sorting]);

    useEffect(() => {
        _filterProps.current = filterProps || {};
    }, [filterProps]);

    useEffect(() => {
        _searchProps.current = searchProps || {};
    }, [searchProps]);

    useEffect(() => {
        fetchCallLogs();
    }, [_searchProps.current.text]);

    useEffect(() => {
        setSelectedPaging({
            current_page: (selectedOffset / limit) + 1,
            number_of_pages: selected.length > limit ? Math.ceil(selected.length / limit) : 1
        })
    }, [selected, selectedOffset]);

    useEffect(() => {
        fetchCallLogs();
        utils.content.subscribe(panelID, ['call_log'], {
            onFetch: () => {
                fetchResults();
                fetchCallLogs();
            },
            onUpdate: () => {
                fetchResults();
                fetchCallLogs();
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={upcoming ? 'Upcoming Calls and Emails' : 'Calls and Emails'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            search: {
                placeholder: 'Search by first or last name...',
                onChange: onTextChange,
                filters: {
                    onClick: onShowFilters,
                    values: filterProps
                }
            },
            buttons: [{
                key: 'selecting',
                title: `${selecting ? 'Select One' : 'Select Batches'}`,
                style: selecting ? 'cancel' : 'default',
                onClick: () => setSelecting(val => !val)
            }]
        }}>
            <div
            className={'row p-0 m-0'}
            style={{
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const Leads = ({ filter_props, id, name, props }, { index, options, utils }) => {

    const filterProps = useRef(filter_props || {});
    const hasAutoFetched = useRef(false);
    const limit = 15;
    const loadingRef = useRef(false);
    const offset = useRef(0);
    const searchProps = useRef({});
    const _selecting = useRef(false);
    const sorting = useRef({ 
        sort_key: props && props.transfers ? 'transfer_date' : 'created' ,
        sort_type: Content.sorting.type.descending
    });
    const _transferDirection = useRef('inbound');

    const [allSelected, setAllSelected] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState([]);
    const [selecting, setSelecting] = useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedOffset, setSelectedOffset] = useState(0);
    const [selectedPaging, setSelectedPaging] = useState(null);
    const [showOptions, setShowOptions] = useState(false);
    const [transferDirection, setTransferDirection] = useState('inbound');
    const [updateAvailable, setUpdateAvailable] = useState(false);

    const onCancelTransfers = () => {
        utils.alert.show({
            title: 'Cancel Transfers',
            message: `Are you sure that you want to cancel these transfers? This will cancel all selected transfers that have not been accepted or declined.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onCancelTransfersConfirm();
                    return;
                }
            }
        })
    }

    const onCancelTransfersConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            let { count } = await Request.post(utils, '/leads/', {
                type: 'set_batch_transfer_status',
                transfer_ids: selected.map(lead => lead.transfer.id),
                status: Lead.Transfer.status.inactive
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The transfers for ${count === 1 ? 'this Lead has' : `these Leads have`} been cancelled.`,
                onClick: () => utils.content.fetch('lead')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling these transfers. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onClearLeads = () => {
        utils.alert.show({
            title: 'Clear Leads',
            message: 'Are you sure that you want to clear your list of selected Leads?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Clear',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setSelected([]);
                    setAllSelected(false);
                    return;
                }
            }
        })
    }

    const onContentClick = () => {
        setShowOptions(false);
    }

    const onContinueClick = evt => {

        // show specialty options if the batches of leads are part of a lead transfer
        if(props && props.transfers) {
            utils.sheet.show({
                items: [{
                    key: 'accepted',
                    title: 'Accept Leads',
                    style: 'default',
                    visible: transferDirection === 'inbound'
                },{
                    key: 'declined',
                    title: 'Decline Leads',
                    style: 'destructive',
                    visible: transferDirection === 'inbound'
                },{
                    key: 'inactive',
                    title: 'Cancel Transfers',
                    style: 'destructive',
                    visible: transferDirection === 'outbound'
                }],
                target: evt.target
            }, key => {
                if(key === 'inactive') {
                    onCancelTransfers();
                    return;
                }
                if(key === 'accepted' || key === 'declined') {
                    onSetTransferStatus(Lead.Transfer.status[key]);
                    return;
                }
            });
            return;
        }

        // fallback to presenting default lead batch options
        showBatchLeadOptions({
            evt: evt,
            leads: selected,
            onChange: onResetBatchSelection,
            props: props,
            selectAll: allSelected,
            setLoading: setLoading,
            utils: utils
        });
    }

    const onDealershipChange = () => {
        conditionalLeadFetch();
        reconnectSockets();
    }

    const onDownloadLeads = async () => {
        try {
            setLoading(true);
            let { url } = await Request.post(utils, '/leads/', {
                all_leads: true,
                type: 'download'
            });

            setLoading(false);
            window.open(url);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your download. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onFormatFilterProps = () => {

        // no additional logic is required if no filter props are available
        if(!filterProps.current) {
            return {};
        }

        // remove unsupported request keys from props
        let props = update(filterProps.current, {
            $unset: ['referred_by_lead']
        });

        // prepare base level props
        let { poi, radius, selection } = filterProps.current.location_radius || {};
        if(poi && radius) {
            props.location_radius = {
                coordinates: {
                    lat: poi.location.latitude,
                    long: poi.location.longitude
                },
                poi: poi,
                radius: radius,
                selection: selection
            }
        }

        // determine if a referral lead id needs to be prepare
        if(filterProps.current.referred_by_lead) {
            props.referred_by_lead_id = filterProps.current.referred_by_lead.id;
        }
        return props;
    }

    const onFormatSearchProps = () => {

        // remove in_progress flag before sending search props to sever
        return update(searchProps.current || {}, {
            $unset: ['in_progress']
        });
    }

    const onLeadCandidateClick = lead => {

        // add to list of selected leads if "selecting" is enabled
        if(selecting) {
            if(selected.find(l => l.id === lead.id)) {
                return;
            }
            let nextLeads = update(selected, { $unshift: [lead] });
            setSelected(nextLeads);
            setAllSelected(false);
            return;
        }

        // fallback to a normal lead click
        onLeadClick(lead);
    }

    const onLeadClick = async target => {
        try {

            // prevent moving forward if feature is disabled for current user
            if(utils.user.permissions.get('leads.details') === false) {
                return utils.user.permissions.reject();
            }
            
            // fetch details for lead
            // only partial details are included through the filter based search for performance purposes
            // port over transfer information if applicable
            setLoading(target.id);
            let lead = await Lead.get(utils, target.id);

            // set transfer object 
            setLoading(false);
            lead.transfer = target.transfer;

            // open details and lead script if lead script is available and current user level matches valid levels
            if(lead.lead_script && [User.levels.get().marketing_director, User.levels.get().safety_advisor].includes(utils.user.get().level)) {

                // fetch details for lead script
                let script = await Lead.Script.get(utils, lead.lead_script.id);

                // send request to open multiple layers
                utils.layer.openMultiple([{
                    abstract: Abstract.create({
                        object: lead,
                        type: 'lead'
                    }),
                    Component: LeadDetails,
                    id: `lead_details_${lead.id}`,
                    permissions: ['leads.details']
                },{
                    Component: LeadScriptEditor.bind(this, {
                        utils: utils,
                        lead: lead,
                        value: script.text
                    }),
                    id: `lead_script_editor_${lead.id}`
                }]);
                return;
            }

            // open standard details for lead
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead',
                }),
                Component: LeadDetails,
                id: `lead_details_${lead.id}`,
                permissions: ['leads.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onLeadListDataChange = evt => {
        try {

            // no additional logic is needed if the update is not meant for this panel
            let { initiated_by_user_id, key } = evt;
            if(!props.cache_key || key !== `list.${props.cache_key}` || loadingRef.current === true) {
                return;
            }

            // prevent a duplicate fetch if loading is already in progress
            if(loadingRef.current === true) {
                console.warn(`[leads.list.${props.cache_key}]: load already in progress`);
                return;
            }

            // automatically update panel contents if the change was made by the current user
            // otherwise show a button on the panel that allows the user to manually refresh the contents of the panel
            if(initiated_by_user_id === utils.user.get().user_id) {
                console.log(`[leads.list.${props.cache_key}]: requesting auto fetch for user who initiated data change`);
                conditionalLeadFetch();
            } else if(shouldAutoFetch() === true) {
                console.log(`[leads.list.${props.cache_key}]: update available`);
                setUpdateAvailable(true);
            }

        } catch(e) {
            console.error(`[on_lead_list_data_change]: ${e.message}`);
        }
    }

    const onNewLead = () => {

        // prepare new lead target and set attribution user
        let lead = Lead.new();
        lead.user = utils.user.get();

        // open lead editing layer
        utils.layer.open({
            abstract: Abstract.create({
                object: lead,
                type: 'lead'
            }),
            Component: AddEditLead.bind(this, {
                isNewTarget: true
            }),
            id: 'new_lead'
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'download',
                permissions: ['leads.actions.download'],
                title: 'Download',
                style: 'dark-grey',
                visible: utils.user.get().level <= User.levels.get().dealer && props.cache_key === 'general' ? true : false
            },{
                key: 'duplicates',
                permissions: ['leads.duplicates'],
                title: 'Manage Duplicates',
                style: 'default',
                visible: props && props.manage_duplicates
            }],
            position: 'bottom',
            target: evt.target
        }, key => {
            if(key === 'download') {
                onDownloadLeads();
                return;
            }
            if(key === 'duplicates') {
                onShowDuplicates();
                return;
            }
        });
    }

    const onRemoveLead = (lead, evt) => {
        
        evt.stopPropagation();
        let nextLeads = update(selected, {
            $apply: leads => leads.filter(prevLead => {
                return prevLead.id !== lead.id;
            })
        });
        setAllSelected(false);
        setSelected(nextLeads);
    }

    const onResetBatchSelection = () => {
        onSetSelectingFlag(false);
        setSelected([]);
    }

    const onResetLeadFilters = () => {
        filterProps.current = {};
        offset.current = 0;
        fetchResults();
    }

    const onSelectAllResults = async () => {
        try {

            // prevent moving forward if too many leads are targeted
            if(paging && paging.total > 50000) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'It looks like the list that you are working with is too large. Please use filters to reduce you list to a total of 50,000 leads or less to continue.'
                });
                return;
            }

            // fetch all matching leads from the server
            let results = await fetchAllResults({ 
                cache_key: null,
                mass_selection: true 
            });

            // update selection flag and selection paging
            setAllSelected(true);
            setSelectedPaging({
                current_page: (selectedOffset / limit) + 1,
                number_of_pages: results.length > limit ? Math.ceil(results.length / limit) : 1
            });

            // update local state with selected leads
            setSelected(leads => {
                return leads.concat(results.filter(lead => {
                    return leads.find(p => p.id === lead.id) ? false : true;
                }))
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your search results. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetSelectingFlag = val => {

        // update selecting ref
        let next = val === true || val === false ? val : !_selecting.current;
        _selecting.current = next;

        // update selecting state and fetch results
        setSelecting(next);
    }

    const onSetTransferStatus = status => {
        utils.alert.show({
            title: `${status === Lead.Transfer.status.accepted ? 'Accept' : 'Decline'} Lead`,
            message: `Are you sure that you want to ${status === Lead.Transfer.status.accepted ? 'accept' : 'decline'} these leads? ${status === Lead.Transfer.status.accepted ? `This will add all of the in-progress leads to your dealership and remove them from the dealership who sent them` : `This will return the leads back to the dealership who sent them`}`,
            buttons: [{
                key: 'confirm',
                title: status === Lead.Transfer.status.accepted ? 'Accept' : 'Decline',
                style: status === Lead.Transfer.status.accepted ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: `Do Not ${status === Lead.Transfer.status.accepted ? 'Accept' : 'Decline'}`,
                style: status === Lead.Transfer.status.accepted ? 'destructive' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetTransferStatusConfirm(status);
                    return;
                }
            }
        });
    }

    const onSetTransferStatusConfirm = async code => {
        try {

            // set loading flag and small timeout to prevent alert overlaps
            setLoading(true);
            await Utils.sleep(0.25);

            // prepare target leads for transfer
            let targets = selected.filter(lead => lead.transfer_id);
            if(targets.length === 0) {
                throw new Error('It looks like all the selected leads have already been accepted, declined, or deactivated');
            }

            // send transfer request to server
            let { count } = await Request.post(utils, '/leads/', {
                type: 'set_batch_transfer_status',
                transfer_ids: targets.map(lead => lead.transfer_id),
                status: code
            });

            setLoading(false);
            onSetSelectingFlag(false);
            utils.alert.show({
                title: 'All Done!',
                message: `${count === 1 ? '1 lead has' : `${count} leads have`} been ${code === Lead.Transfer.status.accepted ? `accepted and added to your dealership` : `declined and returned back to the dealership who sent them`}`,
                onClick: utils.content.fetch.bind(this, 'lead')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${code === Lead.Transfer.status.accepted ? 'accepting' : 'declining'} these leads. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowDuplicates = () => {
        utils.layer.open({
            Component: LeadDuplicates,
            id: 'lead_duplicates',
            permissions: ['leads.duplicates']
        });
    }

    const onShowLeadFilters = () => {
        utils.layer.open({
            id: 'lead_filters',
            Component: LeadFilters.bind(this, {
                value: filterProps.current,
                onChange: result => {

                    // update filter props ref and reset offset ref
                    filterProps.current = result;
                    offset.current = 0;

                    // fetch new lead results
                    fetchResults();
                }
            })
        });
    }

    const onTextChange = text => {
        offset.current = 0;
        setLoading(true);
        onUpdateSearchProps('text', text);
    }

    const onUpdateSearchProps = (key, value) => {
        let nextProps = update(searchProps.current, {
            ...value && {
                [key]: {
                    $set: value
                }
            },
            ...value === null && {
                $unset: [key]
            }
        });
        searchProps.current = nextProps;
        fetchResults();
    }

    const getButtons = () => {

        if(props && props.transfers) {
            return [{
                key: 'toggle',
                title: `Show ${transferDirection === 'outbound' ? 'Inbound' : 'Outbound'}`,
                style: 'default',
                onClick: () => {
                    let next = transferDirection === 'outbound' ? 'inbound' : 'outbound';
                    _transferDirection.current = next;
                    setTransferDirection(next);
                    fetchResults();
                }
            },{
                key: 'selecting',
                title: `${selecting ? 'Select One' : 'Select Batches'}`,
                style: selecting ? 'cancel' : 'secondary',
                onClick: onSetSelectingFlag,
                visible: results.length > 0
            }]
        }

        return [{
            key: 'new',
            permissions: ['leads.actions.new'],
            title: 'New Lead',
            style: 'default',
            onClick: onNewLead
        },{
            key: 'selecting',
            title: `${selecting ? 'Select One' : 'Select Batches'}`,
            style: selecting ? 'cancel' : 'secondary',
            onClick: onSetSelectingFlag,
            visible: results.length > 0
        },{
            key: 'options',
            title: 'Options',
            style: 'dark-grey',
            onClick: onOptionsClick
        }];
    }

    const getContent = () => {

        // determine if results have been auto fetched
        if(hasAutoFetched.current === false) {

            // return nothing if panel supports auto fetch but the flag has not yet been updated
            if(shouldAutoFetch() === true) {
                return null;
            }

            // return placeholder if auto leads fetch has not taken place 
            return (
                <div 
                className={'text-button'}
                onClick={() => {
                    hasAutoFetched.current = true;
                    fetchResults();
                }}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 12,
                    width: '100%'
                }}>
                    <img 
                    src={window.theme === 'dark' ? 'images/lead-panel-click-white.png' : 'images/lead-panel-click-grey.png'}
                    style={{
                        height: 50,
                        marginBottom: 8,
                        objectFit: 'contain',
                        width: 50
                    }} />
                    <span style={{
                        ...Appearance.textStyles.title(),
                        marginBottom: 2,
                        textAlign: 'center'
                    }}>{'Click to Load Leads'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        maxWidth: 250,
                        textAlign: 'center',
                        whiteSpace: 'wrap'
                    }}>{'We automatically hide certain lead lists to improve the overall performance of your dealership.'}</span>
                </div>
            )
        }

        // return standard list of leads if "selecting" is not enabled
        if(selecting === false) {
            return (
                <div className={`col-12 p-0 m-0`}>
                    <div style={Appearance.styles.unstyledPanel()}>
                        {getLeads()}
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset}
                        onClick={next => {
                            offset.current = next;
                            fetchResults();
                        }}/>
                    </div>
                </div>
            )
        }

        // return selection resources if "selecting" is enabled
        return (
            <>
            <div className={`col-12 col-md-6 col-lg-8 pr-md-1 p-0 m-0`}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {getLeads()}
                    <PageControl
                    data={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => {
                        offset.current = next;
                        fetchResults();
                    }}/>
                </div>
            </div>

            <div className={'col-12 col-md-6 col-lg-4 p-0 pl-md-1 m-0'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <div
                    className={'px-3 py-2'}
                    style={{
                        borderBottom: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                        }}>{'Selected Leads'}</span>
                    </div>
                    <div style={{
                        flexGrow: 1
                    }}>
                        {getSelectedLeads()}
                    </div>
                    <PageControl
                    data={selectedPaging}
                    limit={limit}
                    offset={selectedOffset}
                    onClick={nextOffset => setSelectedOffset(nextOffset)}/>

                    <div
                    className={'row m-0'}
                    style={{
                        borderTop: `1px solid ${Appearance.colors.divider()}`,
                        padding: 12,
                        width: '100%'
                    }}>
                        {getSelectionButtons()}
                    </div>
                </div>
            </div>
            </>
        )
    }

    const getFields = (lead, index, leads) => {
        
    }

    const getLeads = () => {
        if(results.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No leads were found from your search',
                    title: 'No Leads Found'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    <LeadTableEntry
                    key={index}
                    props={props}
                    onSortingChange={props => {
                        sorting.current = props;
                        fetchResults();
                    }}
                    sorting={sorting.current}
                    utils={utils} />
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {results.map((lead, index, leads) => {
                        return (
                            <LeadTableEntry
                            isLastItem={index === leads.length - 1}
                            key={index}
                            lead={lead}
                            loading={loading === lead.id} 
                            onLeadClick={onLeadCandidateClick}
                            props={props}
                            utils={utils} />
                        )
                    })}
                </tbody>
            </table>
        )
    }

    const getPanelName = () => {

        // return panel name as-is without modifications if panel is not meant for lead transfers
        if(!props || !props.transfers) {
            return name || 'Leads';
        }

        // return transfer name with direction if panel content has been fetched, otherwise return name without direction
        return hasAutoFetched.current === true ? `${Utils.ucFirst(transferDirection)} ${name}` : name;
    }

    const getSelectionButtons = () => {
        return (
            <>
            {allSelected === false && (
                <div className={selected.length > 0 ? `col-12 ${allSelected ? 'col-md-6' : 'col-md-3'} p-1 px-md-1 py-md-0` : 'col-12 p-1'}>
                    <Button
                    type={'large'}
                    color={'secondary'}
                    label={selected.length > 0 ? 'Select All' : 'Select All Search Results'}
                    loading={loading === 'results'}
                    onClick={onSelectAllResults}/>
                </div>
            )}
            {selected.length > 0 && (
                <>
                <div className={`col-12 ${allSelected ? 'col-md-6' : 'col-md-3'} p-1 px-md-1 py-md-0`}>
                    <Button
                    type={'large'}
                    color={'dark'}
                    label={`Clear`}
                    onClick={onClearLeads}/>
                </div>
                <div className={'col-12 col-md-6 p-1 px-md-1 py-md-0'}>
                    <Button
                    type={'large'}
                    color={'primary'}
                    label={`Continue with ${Utils.softNumberFormat(selected.length)} ${selected.length === 1 ? 'Lead' : 'Leads'}`}
                    onClick={onContinueClick}/>
                </div>
                </>
            )}
            </>
        )
    }

    const getSelectedLeads = () => {
        if(selected.length === 0) {
            return (
                <div
                className={'px-3 py-2'}
                style={{
                    alignItems: 'center',
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'row',
                    height: 36,
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        flexGrow: 1
                    }}>{'No Leads Selected'}</span>
                </div>
            )
        }

        return selected.filter((_, index) => {
            if(selectedPaging.number_of_pages === 1) {
                return true;
            }
            let min = (limit * selectedPaging.current_page) - limit;
            let max = limit * selectedPaging.current_page;
            return index >= min && index < max;

        }).map((lead, index) => {
            return (
                <div
                key={index}
                className={`view-entry ${window.theme} px-3 py-2`}
                onClick={onLeadClick.bind(this, lead)}
                style={{
                    alignItems: 'center',
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'row',
                    height: 36,
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        flexGrow: 1
                    }}>{lead.full_name || 'Customer name not available'}</span>
                    <img
                    className={'text-button'}
                    src={'images/red-x-icon.png'}
                    onClick={onRemoveLead.bind(this, lead)}
                    style={{
                        height: 15,
                        marginLeft: 8,
                        objectFit: 'contain',
                        width: 15
                    }}/>
                </div>
            )
        })
    }

    const getSubTitleProps = () => {
        if(updateAvailable) {
            return {
                onClick: fetchResults,
                style: {
                    ...Appearance.textStyles.subTitle(),
                    color: Appearance.colors.green,
                    fontWeight: 700
                },
                text: 'New Content Available'
            }
        }
        return {
            text: lastUpdated ? `Last Updated: ${Utils.formatDate(lastUpdated)}` : 'Awaiting content...'
        }
    }

    const conditionalLeadFetch = () => {

        // determine if panel allows an auto fetch of the lead list
        if(shouldAutoFetch() === true) {
            hasAutoFetched.current = true;
            fetchResults();
        }
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.persistOn('leads', 'on_lead_list_data_change', onLeadListDataChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('leads', 'on_lead_list_data_change', onLeadListDataChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchAllResults = async data => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading('results');
                await Utils.sleep(0.5);

                let response = await Request.post(utils, '/leads/', {
                    filter_props: onFormatFilterProps(),
                    search_props: onFormatSearchProps(),
                    type: 'advanced_search',
                    ...props.cache_key === 'transfers' && {
                        transfer_direction: _transferDirection.current,
                    },
                    ...sorting.current,
                    ...props,
                    ...data
                });

                setLoading(false);
                resolve(response.leads);

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        });
    };

    const fetchResults = async () => {
        try {
            setLoading(true);
            let { last_updated, leads, paging } = await Request.post(utils, '/leads/', {
                filter_props: onFormatFilterProps(),
                limit: limit,
                offset: offset.current,
                search_props: onFormatSearchProps(),
                type: 'advanced_search',
                ...props.cache_key === 'transfers' && {
                    transfer_direction: _transferDirection.current,
                },
                ...sorting.current,
                ...props
            });
           
            setLastUpdated(last_updated && moment.utc(last_updated).local());
            setLoading(false);
            setPaging(paging);
            setResults(leads);
            setUpdateAvailable(false);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the ${getPanelName().toLowerCase()} list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const reconnectSockets = async () => {
        try {
            await utils.sockets.off('leads', 'on_lead_list_data_change', onLeadListDataChange);
            await utils.sockets.on('leads', 'on_lead_list_data_change', onLeadListDataChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const shouldAutoFetch = () => {

        // allow the panel to auto fetch leads if the user has manually shown the content
        if(hasAutoFetched.current === true) {
            return true;
        }

        // fallback to only allowing auto fetch for specific panels
        return ['archived_leads', 'do_not_call_leads', 'follow_up_leads', 'out_of_service_area_leads', 'transfer_leads', 'unreleased_leads'].includes(id) ? false : true;
    }

    useEffect(() => {
        loadingRef.current = loading;
    }, [loading]);

    useEffect(() => {
        setSelectedPaging({
            current_page: (selectedOffset / limit) + 1,
            number_of_pages: selected.length > limit ? Math.ceil(selected.length / limit) : 1
        })
    }, [selected, selectedOffset]);

    useEffect(() => {
        if(selecting === false) {
            setSelected([]);
        }
    }, [selecting]);

    useEffect(() => {

        // connect to lead list subscriptions
        connectToSockets();

        // determine if panel warrants and auto fetch of the lead list
        conditionalLeadFetch();

        // setup event and content listeners 
        utils.events.on(id, 'dealership_change', onDealershipChange);
        utils.events.on(id, 'dealership_preferences_update', conditionalLeadFetch);

        return () => {
            disconnectFromSockets();
            return () => {
                utils.events.off(id, 'dealership_change', onDealershipChange);
                utils.events.off(id, 'dealership_preferences_update', conditionalLeadFetch);
            }
        }
    }, []);

    return (
        <Panel
        panelID={id}
        name={getPanelName()}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading === true,
            onRefreshContent: {
                onClick: fetchResults,
                pulse: {
                    color: Appearance.colors.green,
                    enabled: updateAvailable
                }
            },
            total: paging && paging.total,
            search: hasAutoFetched.current === true && {
                onChange: text => onTextChange(text && text.length > 0 ? text : null),
                placeholder: 'Search by id, customer name, phone number, or assignment name...',
                filters: {
                    onClick: onShowLeadFilters,
                    onReset: onResetLeadFilters,
                    values: filterProps.current
                }
            },
            subTitle: getSubTitleProps()
        }}>
            <div
            onClick={showOptions ? onContentClick : null}
            className={'row p-0 m-0'}
            style={{
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const LeadBoardDetails = ({ action, filter, value }, { index, options, utils }) => {

    const layerID = `lead_board_details_${action.key}-${action.index}`;
    const limit = 10;
    const offset = useRef(0);

    const [layerState, setLayerState] = useState(null);
    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState('init');
    const [paging, setPaging] = useState(null);

    const onLeadClick = async id => {
        try {
            setLoading(id);
            let lead = await Lead.get(utils, id);

            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead'
                }),
                Component: LeadDetails,
                id: `lead_details_${id}`,
                permissions: ['leads.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowBatchLeadOptions = async evt => {
        try {
            setLoading(true);
            let { leads } = await Request.get(utils, '/leads/', {
                type: 'lead_board',
                action: action,
                filter: filter,
                value: value
            });

            setLoading(false);
            showBatchLeadOptions({
                evt: evt,
                leads: leads.map(lead => Lead.create(lead)),
                props: { released: action.key === 'unreleased' ? false : true },
                selectAll: true,
                setLoading: setLoading,
                utils: utils
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the leads for this category. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return leads.length > 0 && [{
            key: 'options',
            text: `Continue with ${leads.length === 1 ? 'This Lead' : 'These Leads'}`,
            color: 'primary',
            loading: loading === 'options',
            onClick: onShowBatchLeadOptions
        }]
    }

    const getContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {leads.map((lead, index) => {
                    return (
                        Views.entry({
                            bottomBorder: index !== leads.length - 1,
                            key: index,
                            icon: {
                                path: lead.lead_type && lead.lead_type.icon
                            },
                            loading: loading === lead.id,
                            onClick: onLeadClick.bind(this, lead.id),
                            rightContent: getLeadStatus(utils, lead),
                            subTitle: lead.phone_number || 'Phone number not available',
                            title: lead.full_name || 'Customer name not available'
                        })
                    )
                })}
                {paging && (
                    <PageControl
                    data={paging}
                    limit={limit}
                    offset={offset.current}
                    onClick={next => {
                        offset.current = next;
                        setLoading(true);
                        fetchLeads();
                    }} />
                )}
            </div>
        )
    }

    const fetchLeads = async () => {
        try {
            let { leads, paging } = await Request.get(utils, '/leads/', {
                action: action,
                filter: filter,
                limit: limit,
                offset: offset.current,
                type: 'lead_board',
                value: value
            });
            
            setLoading(false);
            setPaging(paging);
            setLeads(leads.map(lead => Lead.create(lead)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the leads for this category. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchLeads();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Lead Board Details (${action.key === 'to_sort' ? 'To Sort' : Utils.ucFirst(action.key)})`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            {getContent()}
        </Layer>
    )
}

export const LeadBoard = ({ index, options, utils }) => {

    const panelID = 'lead_board';
    const filter = useRef({ id: 'locality', title: 'City' });
    const loadingRef = useRef(false);
    const limit = 10;
    const offset = useRef(0);
    const sorting = useRef(null);

    const [lastUpdated, setLastUpdated] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState([]);
    const [updateAvailable, setUpdateAvailable] = useState(false);

    const onDealershipChange = () => {
        fetchLeads();
        reconnectSockets();
    }

    const onFilterSelectionChange = item => {

        // update ref for selected filter item
        filter.current = item;

        // reset sorting back to defaults
        sorting.current = null;

        // set loaing flag to represent filter change and fetch leads
        setLoading('filter');
        fetchLeads();
    }

    const onLeadListDataChange = evt => {
        try {

            // no additional logic is needed if the update is not meant for this panel
            let { initiated_by_user_id, key } = evt;
            if(key !== 'list.board' || loadingRef.current === true) {
                return;
            }

            // prevent a duplicate fetch if loading is already in progress
            if(loadingRef.current === true) {
                console.warn(`[leads.list.board]: load already in progress`);
                return;
            }

            // automatically update panel contents if the change was made by the current user
            // otherwise show a button on the panel that allows the user to manually refresh the contents of the panel
            if(initiated_by_user_id === utils.user.get().user_id) {
                console.log(`[leads.list.board]: requesting auto fetch for user who initiated data change`);
                fetchLeads();
            } else {
                console.log(`[leads.list.board]: update available`);
                setUpdateAvailable(true);
            }

        } catch(e) {
            console.error(`[on_lead_list_data_change]: ${e.message}`);
        }
    }

    const onPrintBoard = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { results, paging } = await Request.get(utils, '/leads/', {
                    type: 'lead_board',
                    filter: filter.current.id,
                    limit: limit,
                    offset: offset.current,
                    ...sorting.current,
                    ...props
                })

                setLoading(false);
                resolve(results);

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onValueClick = async (key, index, value, evt) => {
        evt.stopPropagation();
        if(!results[index][key]) {
            return;
        }
        utils.layer.open({
            id: `lead_board_details_${key}-${index}`,
            Component: LeadBoardDetails.bind(this, {
                action: {
                    index: index,
                    key: key
                },
                filter: filter.current.id,
                value: value
            })
        });
    }

    const getFields = (lead, index) => {

        let target = lead || {};
        let fields = [{
            key: 'filter',
            title: filter.current.title,
            value: target[filter.current.id] && target[filter.current.id].value ? target[filter.current.id].value : 'None',
            icon: target[filter.current.id] && target[filter.current.id].icon
        },{
            key: 'available',
            title: 'Available',
            value: getValueBadge('available', target, index)
        },{
            key: 'unreleased',
            title: 'Unreleased',
            value: getValueBadge('unreleased', target, index)
        },{
            key: 'to_sort',
            title: 'To Sort',
            value: getValueBadge('to_sort', target, index)
        }];

        // return table header if no lead target was supplied
        if(!lead) {
            return (
                <TableListHeader
                fields={fields}
                value={sorting.current}
                onChange={props => {
                    sorting.current = props;
                    setLoading(true);
                    fetchLeads();
                }} />
            )
        }

        // prevent showing rows if content is loading
        if(loading === 'init') {
            return null;
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: index !== results.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
            }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                {typeof(field.icon) === 'string' && (
                                    <img
                                    src={field.icon}
                                    style={{
                                        width: 20,
                                        height: 20,
                                        objectFit: 'contain',
                                        marginRight: 8
                                    }} />
                                )}
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    width: '100%'
                                }}>{field.value}</span>
                            </div>
                        </td>
                    )
                })}
            </tr>
        )
    }

    const getFilters = () => {
        return [{
            id: 'assignment_user_id',
            title: 'Assigned To'
        },{
            id: 'locality',
            title: 'City'
        },{
            id: 'homeowner_status',
            title: 'Homeowner Status'
        },{
            id: 'user_id',
            title: 'Lead Credit'
        },{
            id: 'lead_script',
            title: 'Lead Script'
        },{
            id: 'lead_sub_type',
            title: 'Lead Sub Type'
        },{
            id: 'lead_type',
            title: 'Lead Type'
        },{
            id: 'marital_status',
            title: 'Marital Status'
        },{
            id: 'marketing_director_user_id',
            title: 'Marketing Director'
        },{
            id: 'occupational_status',
            title: 'Occupational Status'
        },{
            id: 'priority',
            title: 'Priority'
        },{
            id: 'administrative_area_level_1',
            title: 'State'
        },{
            id: 'status',
            title: 'Status'
        },{
            id: 'program_credit',
            title: 'Survey'
        },{
            id: 'enrollment_user_id',
            title: 'Survey Credit'
        },{
            id: 'postal_code',
            title: 'Zipcode'
        }];
    }

    const getPrintProps = () => {
        return {
            headers: [{
                key: 'filter',
                title: filter.current.title
            },{
                key: 'available',
                title: 'Available'
            },{
                key: 'unreleased',
                title: 'Unreleased'
            },{
                key: 'to_sort',
                title: 'To Sort'
            }],
            onFetch: onPrintBoard,
            onRenderItem: item => ({
                filter: item[filter.current.id] && item[filter.current.id].value ? item[filter.current.id].value : 'None',
                available: item.available || '0',
                unreleased: item.unreleased || '0',
                to_sort: item.to_sort || '0'
            }),
            permissions: ['leads.board.actions.print']
        }
    }

    const getSubTitleProps = () => {
        if(updateAvailable) {
            return {
                onClick: fetchLeads,
                style: {
                    ...Appearance.textStyles.subTitle(),
                    color: Appearance.colors.green,
                    fontWeight: 700
                },
                text: 'New Content Available'
            }
        }
        return {
            text: lastUpdated ? `Last Updated: ${Utils.formatDate(lastUpdated)}` : 'Awaiting content...'
        }
    }

    const getValueBadge = (key, result, index) => {
        let label = result[key] || 0;
        let value = result[filter.current.id] ? (result[filter.current.id].id || result[filter.current.id].value) : null;
        return (
            <div
            onClick={onValueClick.bind(this, key, index, value)}
            style={{
                display: 'inline-block'
            }}>
                <AltBadge
                labelStyle={{ fontSize: 10 }}
                content={{
                    text: label === 0 ? '0' : label,
                    color: label > 0 ? (key === 'available' ? Appearance.colors.primary() : Appearance.colors.secondary()) : Appearance.colors.grey()
                }} />
            </div>
        )
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('leads', 'on_lead_list_data_change', onLeadListDataChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('leads', 'on_lead_list_data_change', onLeadListDataChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchLeads = async () => {
        try {
            setLoading(true);
            let { paging, results } = await Request.get(utils, '/leads/', {
                type: 'lead_board',
                filter: filter.current.id,
                limit: limit,
                offset: offset.current,
                ...sorting.current
            });

            setLoading(false);
            setLastUpdated(moment());
            setPaging(paging);
            setResults(results);
            setUpdateAvailable(false);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the lead board. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const reconnectSockets = async () => {
        try {
            await utils.sockets.off('leads', 'on_lead_list_data_change', onLeadListDataChange);
            await utils.sockets.on('leads', 'on_lead_list_data_change', onLeadListDataChange);
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        loadingRef.current = loading;
    }, [loading]);

    useEffect(() => {

        fetchLeads();
        connectToSockets();

        utils.events.on(panelID, 'dealership_change', onDealershipChange);
        return () => {
            disconnectFromSockets();
            utils.events.off(panelID, 'dealership_change', fetchLeads);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Lead Board'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            removePadding: true,
            onRefreshContent: {
                onClick: fetchLeads,
                pulse: {
                    color: Appearance.colors.green,
                    enabled: updateAvailable
                }
            },
            print: getPrintProps(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    setLoading(true);
                    fetchLeads();
                }
            },
            subTitle: getSubTitleProps()
        }}>
            <div style={{
                padding: 15,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <ListField
                items={getFilters()}
                placeholderItem={false}
                value={filter.current.title}
                onChange={onFilterSelectionChange} />
            </div>
            <div style={{
                padding: 15
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    <table
                    className={'px-3 py-2 m-0'}
                    style={{
                        width: '100%'
                    }}>
                        <thead style={{
                            width: '100%'
                        }}>
                            {getFields()}
                        </thead>
                        <tbody style={{
                            width: '100%'
                        }}>
                            {loading !== 'filter' && results.map(getFields)}
                        </tbody>
                    </table>
                </div>
            </div>
        </Panel>
    )
}

export const LeadLocations = ({ index, options, utils }) => {

    const panelID = 'lead_locations';
    const filterContainer = useRef(null);
    const [loading, setLoading] = useState(null);
    const [filters, setFilters] = useState(Object.values(Lead.status.get()));
    const [locationData, setLocationData] = useState(null);
    const [region, setRegion] = useState(null);

    const onLeadClick = async props => {
        try {
            let lead = await Lead.get(utils, props.id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead'
                }),
                Component: LeadDetails,
                id: `lead_details_${lead.id}`,
                permissions: ['leads.details']
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getFeatures = () => {
        if(!locationData) {
            return null;
        }
        return {
            id: 'lead_locations',
            region: region,
            onClick: onLeadClick,
            icons: [{
                key: 'location-icon-grey',
                path: 'images/location-icon-grey.png'
            }],
            data: {
                ...locationData,
                features: locationData.features.filter(feature => {
                    return filters.includes(feature.properties.status);
                })
            },
            layer: {
                type: 'symbol',
                layout: {
                    'icon-size': 0.2,
                    'icon-anchor': 'center',
                    'icon-image': 'location-icon-grey',
                    'icon-allow-overlap': true
                },
                paint: {
                    'icon-color': [ 'get', 'color' ]
                }
            },
            onHover: feature => {
                try {
                    let { id, full_name, address } = feature.properties;
                    return {
                        title: full_name,
                        subTitle: address
                    }
                } catch(e) {
                    console.error(e.message);
                }
            }
        }
    }

    const getFilters = () => {
        return (
            <StatusCodeFilters
            categories={['leads']}
            onChange={setFilters} 
            storage={'lead'}
            utils={utils}/>
        )
    }

    const fetchLocations = async () => {
        try {
            let { data, region } = await Request.get(utils, '/leads/', {
                type: 'locations'
            });
            setRegion(region);
            setLocationData(data);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the Leads map. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchLocations();
        utils.events.on(panelID, 'dealership_change', fetchLocations);
        utils.events.on(panelID, 'dealership_preferences_update', fetchLocations);
        return () => {
            utils.events.off(panelID, 'dealership_change');
            utils.events.off(panelID, 'dealership_preferences_update', fetchLocations);
        }

    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Lead Locations'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
        }}>
            <div className={'row p-0 m-0'}>
                <div
                className={'col-12 col-md-8 col-lg-9 col-xl-10 p-0'}
                style={{
                    height: '100%'
                }}>
                    <Map
                    isZoomEnabled={true}
                    isScrollEnabled={true}
                    useShadows={false}
                    features={getFeatures()}
                    style={{
                        width: '100%',
                        height:  filterContainer.current ? filterContainer.current.clientHeight : 350
                    }}/>
                </div>
                <div
                ref={filterContainer}
                className={'col-12 col-md-4 col-lg-3 col-xl-2 p-0 px-md-2 py-md-0'}
                style={{
                    position: 'relative',
                    borderLeft: `1px solid ${Appearance.colors.divider()}`
                }}>
                    {getFilters()}
                </div>
            </div>
        </Panel>
    )
}

// Layers
export const AddEditCallLog = ({ canSetStatus = true, isNewTarget, lead, onClose }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new_call_log_${lead.id}` : `edit_call_log_${abstract.getID()}`;
    const [callLog, setCallLog] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const onDoneClick = async () => {
        try {

            setLoading('done');
            await Utils.sleep(0.25);
            await validateRequiredFields(getFields);

            // create target
            if(isNewTarget) {

                let { status } = await abstract.object.submit(utils, { status: selectedStatus && selectedStatus.id });
                setLoading(false);

                // update lead status if applicable
                if(status) {
                    lead.status = status;
                    utils.content.update({
                        object: lead,
                        type: 'lead'
                    });

                    // present option to set a demo if the status was updated to "set"
                    if(status.code === Lead.status.get().set) {
                        utils.alert.show({
                            title: 'All Done!',
                            message: `Your new ${callLog.method === 'email' ? 'email' : 'phone call'} has been saved. Would you like to set a Demo for this lead?`,
                            buttons: [{
                                key: 'confirm',
                                title: 'Yes',
                                style: 'default'
                            },{
                                key: 'cancel',
                                title: 'Maybe Later',
                                style: 'cancel'
                            }],
                            onClick: async key => {
                                try {
                                    setLayerState('close');
                                    if(key === 'confirm') {
                                        await Utils.sleep(0.5);
                                        onSetDemoFromLead();
                                    }
                                } catch(e) {
                                    console.error(e.message);
                                }

                            }
                        });
                        return;
                    }
                }

                utils.alert.show({
                    title: 'All Done!',
                    message: `Your new ${callLog.method === 'email' ? 'email' : 'phone call'} has been saved for this lead`,
                    onClick: () => setLayerState('close')
                });
                return;
            }

            // update target
            await abstract.object.update(utils);
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The ${callLog.method === 'email' ? 'email' : 'phone call'} for this lead has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this ${callLog.method === 'email' ? 'email' : 'phone call'}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSetDemoFromLead = () => {
        if(!lead) {
            return;
        }
        utils.layer.open({
            id: `book_demo_${lead.id}`,
            abstract: Abstract.create({
                type: 'lead',
                object: lead
            }),
            Component: BookDemoFromLead.bind(this, {})
        })
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props)
        setCallLog(edits);
    }

    const getFields = () => {

        if(!callLog) {
            return [];
        }

        let items = [{
            key: 'date',
            title: 'Date and Time',
            items: [{
                key: 'start_date',
                title: 'Call Date and Time',
                description: 'When did this call occur?',
                component: 'date_duration_picker',
                value: callLog.start_date,
                onChange: date => onUpdateTarget({ start_date: date })
            },{
                key: 'end_date',
                title: 'Duration',
                description: 'How long was this call?',
                component: 'duration_picker',
                visible: callLog.start_date ? true : false,
                value: callLog.end_date ? moment(callLog.end_date).unix() - moment(callLog.start_date).unix() > 0 : false,
                onChange: seconds => {
                    onUpdateTarget({ end_date: moment(callLog.start_date).add(seconds, 'seconds') })
                },
                props: {
                    seconds: callLog.end_date ? moment(callLog.end_date).unix() - moment(callLog.start_date).unix() : null
                }
            }]
        },{
            key: 'follow_up_date',
            title: 'Follow Up Date and Time',
            items: [{
                key: 'follow_up_start_date',
                required: false,
                title: 'Call Date and Time',
                description: 'When would you like to schedule a follow up call? Follow up calls are not required.',
                component: 'date_duration_picker',
                value: callLog.follow_up_start_date,
                onChange: date => onUpdateTarget({ follow_up_start_date: date }),
                props: {
                    requireInteraction: true
                }
            },{
                key: 'follow_up_end_date',
                required: false,
                title: 'Duration',
                description: 'How much time would you like to allocate for the follow up call?',
                component: 'duration_picker',
                visible: callLog.follow_up_start_date ? true : false,
                value: callLog.follow_up_end_date ? moment(callLog.follow_up_end_date).unix() - moment(callLog.follow_up_start_date).unix() > 0 : false,
                onChange: seconds => {
                    onUpdateTarget({ follow_up_end_date: moment(callLog.follow_up_start_date).add(seconds, 'seconds') })
                },
                props: {
                    seconds: callLog.follow_up_end_date ? moment(callLog.follow_up_end_date).unix() - moment(callLog.follow_up_start_date).unix() : null
                }
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'direction',
                title: 'Direction',
                description: 'Did you make initial the outbound conversation or did the lead initiate the inbound conversation?',
                component: 'list',
                value: callLog.direction ? Utils.ucFirst(callLog.direction) : null,
                onChange: item => onUpdateTarget({ direction: item ? item.code : null }),
                items: [{
                    code: 'inbound',
                    title: 'Inbound'
                },{
                    code: 'outbound',
                    title: 'Outbound'
                }]
            },{
                key: 'method',
                title: 'Method',
                description: 'How did you contact this Lead?',
                component: 'list',
                value: callLog.method ? Utils.ucFirst(callLog.method) : null,
                onChange: item => onUpdateTarget({ method: item ? item.code : null }),
                items: [{
                    code: 'email',
                    title: 'Email'
                },{
                    code: 'phone',
                    title: 'Phone'
                }]
            }]
        },{
            key: 'additional',
            title: 'Additional Information',
            items: [{
                key: 'assign_to',
                title: 'Assign To',
                required: false,
                description: 'Would you like to assign this call to someone in your Dealership?',
                component: 'user_lookup',
                onChange: user => onUpdateTarget({ assign_to: user }),
                value: callLog.assign_to,
                props: {
                    levels: [
                        User.levels.get().region_director,
                        User.levels.get().division_director,
                        User.levels.get().area_director,
                        User.levels.get().dealer,
                        User.levels.get().safety_advisor,
                        User.levels.get().safety_associate
                    ]
                }
            },{
                key: 'status',
                visible: canSetStatus,
                title: 'Status',
                required: false,
                description: `If needed, you can set the status for this lead based on the results of this ${callLog.method === 'email' ? 'email' : 'phone call'}.`,
                component: 'list',
                value: selectedStatus ? selectedStatus.title : null,
                onChange: setSelectedStatus,
                items: utils.dealership.status_codes.get().map(entry => ({
                    id: entry.code,
                    title: entry.text
                }))
            },{
                key: 'notes',
                title: 'Notes',
                required: false,
                description: 'Add any information in this space that has not already been covered in the fields above.',
                component: 'textview',
                value: callLog.notes,
                onChange: text => onUpdateTarget({ notes: text }),
            }]
        }]

        return items;
    }

    const setupTarget = () => {

        // init target edits
        let edits = abstract.object.open();

        // update lead for target and set default duration if applicable
        if(isNewTarget) {
            let date = Utils.conformDate(moment(), 5);
            abstract.object.lead = lead;
            edits = abstract.object.set({
                assign_to: utils.user.get(),
                direction: 'outbound',
                end_date: moment(date).add(60, 'seconds'),
                method: 'phone',
                start_date: date
            });
        }

        // update call log state with edits
        setCallLog(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New Call or Email` : `Editing ${abstract.getTitle()}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState,
            sizing: 'medium',
            onCloseLayer: onClose
        }}
        buttons={[{
            key: 'done',
            text: isNewTarget ? 'Done' : 'Save Changes',
            color: 'primary',
            loading: loading === 'done',
            onClick: onDoneClick
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditFollowUpCall = ({ isNewTarget, onClose }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_follow_up_call' : `edit_follow_up_call_${abstract.getID()}`;
    const [callLog, setCallLog] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDoneClick = async () => {
        try {
            setLoading('done');
            await Utils.sleep(0.25);
            await validateRequiredFields(getFields);
            setLayerState('close');
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this ${callLog.method === 'email' ? 'email' : 'phone call'}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props)
        setCallLog(edits);
    }

    const getFields = () => {

        if(!callLog) {
            return [];
        }

        let items = [{
            key: 'follow_up_date',
            title: 'Recall Date and Time',
            items: [{
                key: 'follow_up_start_date',
                required: false,
                title: 'Call Date and Time',
                description: 'When would you like to schedule a follow up call? Follow up calls are not required.',
                component: 'date_duration_picker',
                value: callLog.follow_up_start_date,
                onChange: date => onUpdateTarget({ follow_up_start_date: date }),
                props: {
                    requireInteraction: true
                }
            },{
                key: 'follow_up_end_date',
                required: false,
                title: 'Duration',
                description: 'How much time would you like to allocate for the follow up call?',
                component: 'duration_picker',
                visible: callLog.follow_up_start_date ? true : false,
                value: callLog.follow_up_end_date ? moment(callLog.follow_up_end_date).unix() - moment(callLog.follow_up_start_date).unix() > 0 : false,
                onChange: seconds => {
                    onUpdateTarget({ follow_up_end_date: moment(callLog.follow_up_start_date).add(seconds, 'seconds') })
                },
                props: {
                    seconds: callLog.follow_up_end_date ? moment(callLog.follow_up_end_date).unix() - moment(callLog.follow_up_start_date).unix() : null
                }
            },{
                key: 'notes',
                title: 'Notes',
                required: false,
                description: 'Add any information in this space that has not already been covered in the fields above.',
                component: 'textview',
                value: callLog.notes,
                onChange: text => onUpdateTarget({ notes: text }),
            }]
        }]

        return items;
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setCallLog(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New Follow Up Call` : `Editing ${abstract.getTitle()}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState,
            sizing: 'medium',
            onCloseLayer: () => onClose(callLog)
        }}
        buttons={[{
            key: 'done',
            text: isNewTarget ? 'Done' : 'Save Changes',
            color: 'primary',
            loading: loading === 'done',
            onClick: onDoneClick
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditLead = ({ isNewTarget, onAddLead }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new_lead` : `edit_lead_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState('init');

    const getButtons = () => {
        return [{
            key: 'done',
            text: isNewTarget ? 'Done' : 'Save Changes',
            color: 'primary',
            loading: loading === 'submit',
            onClick: () => setLoading('submit')
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? `New Lead` : `Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AddEditLeadContent
            abstract={abstract}
            isNewTarget={isNewTarget}
            loading={loading}
            onAddLead={onAddLead}
            setLayerState={setLayerState}
            setLoading={setLoading}
            utils={utils} />
        </Layer>
    )
}

export const AddEditLeadContent = ({ abstract, dialer, editTargets, isNewTarget, loading, onAddLead, onUpdateLead, extendedProps, setLayerState, setLoading, utils }) => {

    const [edits, setEdits] = useState({});
    const [homeownerStatusCodes, setHomeownerStatusCodes] = useState([]);
    const [maritalStatusCodes, setMaritalStatusCodes] = useState([]);
    const [occupationalStatusCodes, setOccupationalStatusCodes] = useState([]);

    const canEditLeadCredit = () => {

        let dealership = utils.dealership.get();
        let user = utils.user.get();

        // allow admins to make changes to any dealership
        if(user.level <= User.levels.get().admin) {
            return true;
        }
        // allow directors and dealers to change lead credit for their own dealership
        if(user.dealership_id === dealership.id) {
            return user.level <= User.levels.get().dealer || user.level === User.levels.get().marketing_director;
        }
        return false;
    }

    const onDoneClick = async props => {
        try {

            // sleep briefly and validate fields
            await validateRequiredFields(getFields);
            await Utils.sleep(0.25);

            // create new target
            if(isNewTarget) {

                // submit changes
                await abstract.object.submit(utils, props);
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `The lead for ${abstract.object.full_name} has been created`,
                    onClick: () => {
                        if(typeof(setLayerState) === 'function') {
                            setLayerState('close');
                        }
                    }
                });

                // notify subscribers of new lead if applicable
                if(typeof(onAddLead) === 'function') {
                    onAddLead(abstract.object);
                }
                return;
            }

            // update target
            await abstract.object.update(utils, extendedProps);
            setLoading(false);

            // show confirmation if marketing is not enabled
            if(dialer !== true) {
                utils.alert.show({
                    title: 'All Done!',
                    message: `The lead for ${abstract.object.full_name} has been updated`,
                    onClick: () => {
                        if(typeof(setLayerState) === 'function') {
                            setLayerState('close');
                        }
                    }
                });
            }

            // notify subscribers of new lead if applicable
            if(typeof(onUpdateLead) === 'function') {
                onUpdateLead(abstract.object);
            }

        } catch(e) {

            // check for do not call list conflicts
            if(e.code === 409) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: `There was an issue creating this lead. ${e.message || 'An unknown error occurred'}`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Continue',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Add Lead',
                        style: 'default'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            onDoneClick({
                                ...props,
                                ...e.response.duplicate === true && {
                                    duplicate_override: true
                                },
                                ...e.response.do_not_call === true && {
                                    do_not_call_override: true
                                }
                            });
                            return;
                        }
                        setLoading(false);
                    }
                });
                return;
            }

            // fallback to standard request error
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onTextMessageClick = async () => {
        try {
            utils.layer.open({
                id: 'lead_text_message_manager',
                Component: LeadTextMessageManager.bind(this, {
                    targets: [{
                        id: abstract.getID(),
                        phone_number: abstract.object.edits.phone_number
                    }]
                })
            });
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the text message process. ${e.message || 'An unknown error occured'}`
            })
        }
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setEdits(edits);
    }

    const getContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }
        return (
            <AltFieldMapper
            dialer={dialer}
            fields={getFields()} 
            utils={utils}/>
        )
    }

    const getFields = () => {

        if(!edits) {
            return [];
        }

        return [{
            key: 'customer',
            title: 'Customer',
            items: [{
                key: 'first_name',
                title: 'First Name',
                description: 'The first name for the primary customer on this lead',
                component: 'textfield',
                value: edits.first_name,
                onChange: text => onUpdateTarget({ first_name: text })
            },{
                key: 'last_name',
                title: 'Last Name',
                description: 'The last name for the primary customer on this lead',
                component: 'textfield',
                value: edits.last_name,
                onChange: text => onUpdateTarget({ last_name: text })
            },{
                key: 'phone_number',
                required: !edits.email_address && !edits.address ? true : false,
                title: 'Phone Number',
                description: 'The phone number for the primary customer on this lead. Each lead must provide a phone number, email address, OR physical address. Please provide all three fields if the information is available.',
                component: 'textfield',
                value: edits.phone_number,
                onChange: text => onUpdateTarget({ phone_number: text }),
                props: {
                    format: 'phone_number',
                    appendContent: dialer && edits.phone_number && (
                        <img
                        className={'text-button'}
                        src={'images/text-message-icon-blue.png'}
                        onClick={onTextMessageClick}
                        style={{
                            height: 20,
                            marginLeft: 8,
                            width: 20
                        }} />
                    )
                }
            },{
                key: 'email_address',
                required: !edits.phone_number && !edits.address ? true : false,
                title: 'Email Address',
                description: 'The email address for the primary customer on this lead. Each lead must provide a phone number, email address, OR physical address. Please provide all three fields if the information is available.',
                component: 'textfield',
                value: edits.email_address,
                onChange: text => onUpdateTarget({ email_address: text }),
                props: {
                    format: 'email'
                }
            },{
                key: 'address',
                required: !edits.phone_number && !edits.email_address ? true : false,
                title: 'Physical Address',
                description: 'The physical address for the primary customer on this lead. Each lead must provide a phone number, email address, OR physical address. Please provide all three fields if the information is available.',
                component: 'address_lookup',
                onChange: response => onUpdateTarget(response),
                value: edits.address && {
                    address: edits.address,
                    location: edits.location
                }
            },{
                key: 'homeowner_status',
                required: false,
                title: 'Homeowner Status',
                description: `What is the status of this customer's home ownership? You can skip this field if you do not know this information.`,
                component: 'list',
                value: edits.homeowner_status ? edits.homeowner_status.text : null,
                items: homeownerStatusCodes,
                onChange: item => onUpdateTarget({ homeowner_status: item })
            },{
                key: 'marital_status',
                required: false,
                title: 'Marital Status',
                description: `What is the status of this customer's relationship with their spouse or partner? You can skip this field if you do not know this information.`,
                component: 'list',
                value: edits.marital_status ? edits.marital_status.text : null,
                items: maritalStatusCodes,
                onChange: item => onUpdateTarget({ marital_status: item })
            },{
                key: 'occupational_status',
                required: false,
                title: 'Occupational Status',
                description: `What is the status of this customer's employement? You can skip this field if you do not know this information.`,
                component: 'list',
                value: edits.occupational_status ? edits.occupational_status.text : null,
                items: occupationalStatusCodes,
                onChange: item => onUpdateTarget({ occupational_status: item })
            }]
        },{
            key: 'spouse',
            title: 'Spouse',
            items: [{
                key: 'spouse_first_name',
                required: false,
                title: 'First Name',
                dialer_title: 'Spouse First Name',
                description: 'The first name for the secondary customer on this lead',
                component: 'textfield',
                value: edits.spouse_first_name,
                onChange: text => onUpdateTarget({ spouse_first_name: text })
            },{
                key: 'spouse_last_name',
                required: false,
                title: 'Last Name',
                dialer_title: 'Spouse Last Name',
                description: 'The last name for the secondary customer on this lead',
                component: 'textfield',
                value: edits.spouse_last_name,
                onChange: text => onUpdateTarget({ spouse_last_name: text })
            },{
                key: 'spouse_phone_number',
                required: false,
                title: 'Phone Number',
                dialer_title: 'Spouse Phone Number',
                description: 'The phone number for the secondary customer on this lead. Leave this field blank if the primary and secondary customer share the same phone number.',
                component: 'textfield',
                value: edits.spouse_phone_number,
                onChange: text => onUpdateTarget({ spouse_phone_number: text }),
                props: {
                    format: 'phone_number',
                }
            },{
                key: 'spouse_email_address',
                required: false,
                title: 'Email Address',
                dialer_title: 'Spouse Email Address',
                description: 'The email address for the secondary customer on this lead. Leave this field blank if the primary and secondary customer share the same email address.',
                component: 'textfield',
                value: edits.spouse_email_address,
                onChange: text => onUpdateTarget({ spouse_email_address: text }),
                props: {
                    format: 'email'
                }
            }]
        },{
            key: 'lead',
            title: 'About this Lead',
            items: [{
                key: 'lead_type',
                dialer: false,
                title: 'Type',
                description: 'Choosing a lead type helps us sort your leads into specific categories.',
                component: 'lead_type_picker',
                value: edits.lead_type ? edits.lead_type.text : null,
                onChange: lead_type => onUpdateTarget({ lead_type: lead_type })
            },{
                key: 'lead_sub_type',
                required: false,
                dialer: false,
                title: 'Sub-Type',
                description: 'Choosing a sub lead type helps us sort your leads into deeper, more specific categories.',
                component: 'lead_sub_type_picker',
                value: edits.lead_sub_type ? edits.lead_sub_type.text : null,
                onChange: lead_sub_type => onUpdateTarget({ lead_sub_type: lead_sub_type })
            },{
                key: 'lead_script',
                required: false,
                dialer: false,
                title: 'Script',
                description: 'Lead scripts are used by Marketing Directors when they reach out to a lead. The script provides a customized narrative to help move the converstation in a productive direction.',
                component: 'lead_script_picker',
                value: edits.lead_script ? edits.lead_script.title : null,
                onChange: script => onUpdateTarget({ lead_script: script })
            }]
        },{
            key: 'users',
            title: 'Users and Credit',
            items: [{
                key: 'user',
                required: false,
                dialer: false,
                visible: canEditLeadCredit(),
                title: 'Lead Credit',
                description: 'This should be the person adding the Lead to the Global Data. You may change this to whomever you would like to associate this entry with.',
                component: 'user_lookup',
                value: edits.user,
                onChange: user => onUpdateTarget({ user: user }),
                props: {
                    placeholder: 'Search by first or last name...'
                }
            },{
                key: 'assignment_user',
                required: false,
                dialer: false,
                title: 'Assigned To',
                description: 'You can assign this Lead to a specific employee in your Dealership. This is not required and can be done at a later date.',
                component: 'user_lookup',
                value: edits.assignment_user,
                onChange: user => onUpdateTarget({ assignment_user: user }),
                props: {
                    placeholder: 'Search by first or last name...'
                }
            },{
                key: 'marketing_director_user',
                required: false,
                dialer: false,
                title: 'Marketing Director',
                description: 'You can assign this Lead to a specific Marketing Director in your Dealership. This is not required and can be done at a later date.',
                component: 'user_lookup',
                value: edits.marketing_director_user,
                onChange: user => onUpdateTarget({ marketing_director_user: user }),
                props: {
                    placeholder: 'Search by first or last name...',
                    levels: [ User.levels.get().marketing_director ]
                }
            },{
                key: 'enrollment_user',
                required: false,
                dialer: false,
                title: 'Survey or Program Credit',
                description: 'Should a Safety Associate or Safety Advisor receieve credit for this lead? If so, search for their account below',
                component: 'user_lookup',
                value: edits.enrollment_user,
                onChange: user => onUpdateTarget({ enrollment_user: user }),
                props: {
                    placeholder: 'Search by first or last name...',
                    levels: [
                        User.levels.get().safety_advisor,
                        User.levels.get().safety_associate
                    ]
                }
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'dealership',
                required: isNewTarget && utils.user.get().level <= User.levels.get().admin,
                visible: isNewTarget && utils.user.get().level <= User.levels.get().admin,
                dialer: false,
                title: 'Dealership',
                description: 'Each lead must be assigned to a specific Dealership. This will determine who can interact with this lead.',
                component: 'dealership_lookup',
                value: edits.dealership,
                onChange: dealership => onUpdateTarget({ dealership: dealership })
            },{
                key: 'affiliate',
                dialer: false,
                required: false,
                title: 'Referred By Other Lead',
                description: 'Was the lead created from a referral through another lead in your Dealership?',
                component: 'lead_lookup',
                value: edits.affiliate,
                onChange: affiliate => onUpdateTarget({ affiliate: affiliate }),
                props: {
                    placeholder: 'Search by first or last name...'
                }
            },{
                key: 'program_credit',
                dialer: false,
                required: false,
                title: 'Received through Program',
                description: 'Should this lead be associate with a specific Program? If so, search for the Program below',
                placeholder: 'Search by name...',
                component: 'program_lookup',
                value: edits.program_credit,
                onChange: program => onUpdateTarget({ program_credit: program })
            },{
                key: 'priority',
                required: false,
                title: 'Priority',
                description: 'Should this lead be prioritized over other leads in your Dealership?',
                component: 'bool_list',
                value: edits.priority,
                onChange: val => onUpdateTarget({ priority: val })
            },{
                key: 'out_of_service_area',
                required: false,
                title: 'Out of Service Area',
                description: 'Does this Lead fall outside of the typical areas that your Dealership would service?',
                component: 'bool_list',
                value: edits.out_of_service_area,
                onChange: val => onUpdateTarget({ out_of_service_area: val })
            },{
                key: 'tags',
                required: false,
                title: 'Tags',
                description: 'Attaching one or more tags to a lead can help organize your Dealership\'s lead catalog. Type a word and press enter when you are done',
                component: 'tag_lookup',
                value: edits.tags,
                onChange: tags => onUpdateTarget({ tags: tags })
            },{
                key: 'notes',
                required: false,
                dialer: false,
                title: 'Comments',
                description: 'Use this area to attach any information that has not already been covered.',
                component: 'textview',
                value: edits.notes,
                onChange: text => onUpdateTarget({ notes: text })
            }]
        }];
    }

    const setupTarget = async () => {
        try {

            // fetch dealership edit targets from the server if needed
            // edit targets are already present if the parent component is the marketing dialer
            let targets = editTargets;
            if(!targets) {
                setLoading('init');
                await Utils.sleep(0.25);
                targets = await Request.get(utils, '/leads/', {
                    id: abstract.getID(),
                    type: 'edit_targets'
                });
            }

            // set edit targets for editing process
            let { default_lead_type, default_lead_script, homeowner_status_codes, marital_status_codes, occupational_status_codes } = targets;
            setHomeownerStatusCodes(homeowner_status_codes);
            setMaritalStatusCodes(marital_status_codes);
            setOccupationalStatusCodes(occupational_status_codes);

            // open edits for abstract target
            let edits = abstract.object.open();

            // declare currently selected dealership
            let dealership = utils.dealership.get();

            // set dealership if target is not new and a dealership is not set
            if(isNewTarget === false && !abstract.object.dealership) {
                let next = abstract.object.dealership_id === dealership.id ? dealership : await Dealership.get(utils, abstract.object.dealership_id);
                edits = abstract.object.set({ dealership: next });
            }
            
            // determine if pre-edit actions are needed for new abstract targets
            if(isNewTarget) {

                // set current dealership for target if target is new
                edits = abstract.object.set({ dealership });

                // set default lead type and lead script if applicable
                if(default_lead_type || default_lead_script) {
                    edits = abstract.object.set({
                        lead_script: default_lead_script,
                        lead_type: default_lead_type
                    })
                }
            }

            // end loading and set edits
            setLoading(false);
            setEdits(edits);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading === 'submit') {
            onDoneClick();
        }
    }, [loading]);

    useEffect(() => {
        setupTarget();
    }, [abstract.object]);

    return getContent();
}

export const AddEditLeadScriptRebuttal = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_lead_script_rebuttal' : `edit_lead_script_rebuttal_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rebuttal, setRebuttal] = useState(null);

    const onDoneClick = async () => {
        try {

            setLoading('done');
            await Utils.sleep(0.25);
            await validateRequiredFields(getFields);

            // create target
            if(isNewTarget) {
                await abstract.object.submit(utils);
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `Your new rebuttal has been created and is ready to use.`,
                    onClick: () => setLayerState('close')
                });
                return;
            }

            // update target
            await abstract.object.update(utils);
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This rebuttal has been updated and will be available during the next use of a lead script.`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this rebuttal. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props)
        setRebuttal(edits);
    }

    const getFields = () => {
        if(!rebuttal) {
            return [];
        }
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: 'The title is used to represent this rebuttal across your dealership.',
                component: 'textfield',
                value: rebuttal.title,
                onChange: text => onUpdateTarget({ title: text }),
            },{
                key: 'content',
                title: 'Content',
                description: 'The content is the information presented to the marketer when interacting with this lead script rebuttal.',
                component: 'textview',
                value: rebuttal.content,
                onChange: text => onUpdateTarget({ content: text }),
            }]
        }]

        return items;
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setRebuttal(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New Lead Script Rebuttal` : `Editing ${abstract.getTitle()}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState,
            sizing: 'medium',
        }}
        buttons={[{
            key: 'done',
            text: isNewTarget ? 'Done' : 'Save Changes',
            color: 'primary',
            loading: loading === 'done',
            onClick: onDoneClick
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AssignLeads = ({ leads, onClose }, { index, options, utils }) => {

    const layerID = 'assign_leads';

    const [count, setCount] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [targets, setTargets] = useState(leads);
    const [user, setUser] = useState(null);

    const onAssignLeads = () => {
        if(!user) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please select a user before moving on.'
            });
            return;
        }
        if(count && count > targets.length) {
            utils.alert.show({
                title: 'Just a Second',
                message: `You have selected ${targets.length} ${targets.length === 1 ? 'lead' : 'leads'} and requested a custom amount of ${count} ${count === 1 ? 'assignment' : 'assignments'}. Please choose an assignment amount less than or equal to ${targets.length} or leave the field blank to assign all the selected leads.`
            });
            return;
        }

        utils.alert.show({
            title: 'Assign Leads',
            message: `Are you sure that you want to assign ${count ? `${count} ${count === 1 ? 'lead' : 'leads'}` : (targets.length === 1 ? 'the selected lead' : 'all the selected leads')} to ${user.full_name}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Assign',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onAssignLeadsConfirm();
                    return;
                }
            }
        });
    }

    const onAssignLeadsConfirm = async () => {
        try {
            setLoading(true);

            // slice targets array down to count and set next selection of leads
            let submitted = count || targets.length;
            let ids = targets.map(lead => lead.id).slice(0, submitted);
            let next = targets.slice(submitted, targets.length - 1);

            // submit selection to server
            let response = await Request.post(utils, '/leads/', {
                ids: ids,
                type: 'batch_assign_user',
                user_id: user.user_id
            });

            setLoading(false);
            setCount(null);
            setTargets(next);

            // notify user that background mode is enabled
            let message = response.background ? `We have received your request to assign ${Utils.softNumberFormat(submitted)} ${submitted === 1 ? 'lead' : 'leads'} to ${user.full_name} and will notify you when it has completed. The estimated time to create your assignments is about ${Utils.parseDuration(response.estimated_duration)}` : `We have assigned ${Utils.softNumberFormat(submitted)} ${submitted === 1 ? 'lead' : 'leads'} to ${user.full_name}`;

            // determine if an additional assignment cycle should be requested
            if(count && count > 0 && next.length > 0) {
                utils.alert.show({
                    title: 'All Done!',
                    message: `${message}. Would you like to make another assignment?`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Yes',
                        style: 'default'
                    },{
                        key: 'cancel',
                        title: 'No',
                        style: 'cancel'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            return;
                        }
                        setLayerState('close');
                        if(typeof(onClose) === 'function') {
                            onClose();
                        }
                    }
                });
                return;
            }

            // fallback to default confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: message,
                onClick: () => {
                    setLayerState('close');
                    if(typeof(onClose) === 'function') {
                        onClose();
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating these assignments. ${e.message || 'An unknown error occurred'}`
            });
        }
    }


    const getButtons = () => {
        return [{
            key: 'submit',
            text: `Assign ${count && count > 0 ? `${Utils.softNumberFormat(count)} out of ` : ''}${Utils.softNumberFormat(targets.length)} ${targets.length === 1 ? 'lead' : 'leads'}`,
            color: 'primary',
            loading: loading === 'submit',
            onClick: onAssignLeads
        }];
    }

    const getFields = () => {
        return [{
            key: 'options',
            title: 'Options',
            items: [{
                key: 'user',
                title: 'User',
                description: 'This will be the user that we assign to the selected leads.',
                component: 'user_lookup',
                value: user,
                onChange: setUser
            },{
                key: 'count',
                required: false,
                title: 'Custom Amount',
                description: 'If needed, you can assign a preset amount of leads to this user. We start from the beginning of your list of selected leads and assign the amount of leads that you specify. If left blank, we will assign all selected leads to the user that you choose.',
                component: 'textfield',
                value: count,
                onChange: val => setCount(isNaN(val) ? null : parseInt(val)),
                props: {
                    format: 'integer'
                }
            }]
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Assign Leads to User'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const BatchUpdateLeadDetails = ({ ids, onClose }, { index, options, utils }) => {

    const layerID = 'batch_update_lead_details';
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [homeownerStatusCodes, setHomeownerStatusCodes] = useState([]);
    const [maritalStatusCodes, setMaritalStatusCodes] = useState([]);
    const [occupationalStatusCodes, setOccupationalStatusCodes] = useState([]);
    const [props, setProps] = useState({});

    const onSubmitSelections = async () => {
        try {
            // prepare request payload
            let payload = {
                affiliate_id: props.affiliate && props.affiliate.id,
                assignment_user_id: props.assignment_user && props.assignment_user.user_id,
                enrollment_user_id: props.enrollment_user && props.enrollment_user.user_id,
                homeowner_status: props.homeowner_status && props.homeowner_status.code,
                lead_script: props.lead_script && props.lead_script.id,
                lead_sub_type: props.lead_sub_type && props.lead_sub_type.id,
                lead_type: props.lead_type && props.lead_type.id,
                marital_status: props.marital_status && props.marital_status.code,
                marketing_director_user_id: props.marketing_director_user && props.marketing_director_user.user_id,
                priority: props.priority ? props.priority.id === 'yes' : null,
                occupational_status: props.occupational_status && props.occupational_status.code,
                out_of_service_area: props.out_of_service_area ? props.out_of_service_area.id === 'yes' : null,
                user_id: props.user && props.user.user_id,
                program_credit: props.program_credit && props.program_credit.id,
                status: props.status && props.status.code,
                tags: props.tags && props.tags.map(tag => tag.id)
            }

            // check that at least one valid value was provided for batch updates
            let values = Object.values(payload).filter(value => Array.isArray(value) ? value.length > 0 : (value !== null && value !== undefined));
            if(values.length === 0) {
                throw new Error('Please fill out at least one area before submitting your changes');
            }

            // submit request to server
            setLoading('submit');
            await Utils.sleep(0.25);
            await Request.post(utils, '/leads/', {
                type: 'batch_update',
                ids: ids,
                props: payload
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `We have made the changes to the ${ids.length} ${ids.length === 1 ? 'lead' : 'leads'} that you had selected.`,
                onClick: () => {

                    // close layer and notify layer subscribers 
                    setLayerState('close');
                    if(typeof(onClose) === 'function') {
                        onClose();
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your updates. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = async props => {
        setProps(prev => ({
            ...prev,
            ...props
        }));
    }

    const canEditLeadCredit = () => {

        let dealership = utils.dealership.get();
        let user = utils.user.get();

        // allow admins to make changes to any dealership
        if(user.level <= User.levels.get().admin) {
            return true;
        }
        // allow directors and dealers to change lead credit for their own dealership
        if(user.dealership_id === dealership.id) {
            return user.level <= User.levels.get().dealer || user.level === User.levels.get().marketing_director;
        }
        return false;
    }

    const getButtons = () => {
        return [{
            key: 'submit',
            text: 'Submit Changes',
            color: 'primary',
            onClick: onSubmitSelections,
            loading: loading === 'submit'
        }]
    }

    const getFields = () => {
        return [{
            key: 'customer',
            title: 'Customer',
            items: [{
                key: 'homeowner_status',
                required: false,
                title: 'Homeowner Status',
                component: 'list',
                value: props.homeowner_status && props.homeowner_status.text,
                items: homeownerStatusCodes,
                onChange: item => onUpdateTarget({ homeowner_status: item })
            },{
                key: 'marital_status',
                required: false,
                title: 'Marital Status',
                component: 'list',
                value: props.marital_status && props.marital_status.text,
                items: maritalStatusCodes,
                onChange: item => onUpdateTarget({ marital_status: item })
            },{
                key: 'occupational_status',
                required: false,
                title: 'Occupational Status',
                component: 'list',
                value: props.occupational_status && props.occupational_status.text,
                items: occupationalStatusCodes,
                onChange: item => onUpdateTarget({ occupational_status: item })
            }]
        },{
            key: 'lead',
            title: 'About this Lead',
            items: [{
                key: 'lead_type',
                required: false,
                title: 'Type',
                component: 'lead_type_picker',
                value: props.lead_type && props.lead_type.text,
                props: { canAddNewTarget: false },
                onChange: lead_type => onUpdateTarget({ lead_type: lead_type })
            },{
                key: 'lead_sub_type',
                required: false,
                title: 'Sub-Type',
                component: 'lead_sub_type_picker',
                value: props.lead_sub_type && props.lead_sub_type.text,
                props: { canAddNewTarget: false },
                onChange: lead_sub_type => onUpdateTarget({ lead_sub_type: lead_sub_type })
            },{
                key: 'lead_script',
                required: false,
                title: 'Script',
                component: 'lead_script_picker',
                value: props.lead_script && props.lead_script.title,
                props: { canAddNewTarget: false },
                onChange: script => onUpdateTarget({ lead_script: script })
            }]
        },{
            key: 'users',
            title: 'Users and Credit',
            items: [{
                key: 'user',
                required: false,
                visible: canEditLeadCredit(),
                title: 'Lead Credit',
                component: 'user_lookup',
                value: props.user,
                onChange: user => onUpdateTarget({ user: user }),
                props: {
                    placeholder: 'Search by first or last name...'
                }
            },{
                key: 'assignment_user',
                required: false,
                title: 'Assigned To',
                component: 'user_lookup',
                value: props.assignment_user,
                onChange: user => onUpdateTarget({ assignment_user: user }),
                props: {
                    placeholder: 'Search by first or last name...'
                }
            },{
                key: 'marketing_director_user',
                required: false,
                title: 'Marketing Director',
                component: 'user_lookup',
                value: props.marketing_director_user,
                onChange: user => onUpdateTarget({ marketing_director_user: user }),
                props: {
                    placeholder: 'Search by first or last name...',
                    levels: [ User.levels.get().marketing_director ]
                }
            },{
                key: 'enrollment_user',
                required: false,
                title: 'Survey or Program Credit',
                component: 'user_lookup',
                value: props.enrollment_user,
                onChange: user => onUpdateTarget({ enrollment_user: user }),
                props: {
                    placeholder: 'Search by first or last name...',
                    levels: [
                        User.levels.get().safety_advisor,
                        User.levels.get().safety_associate
                    ]
                }
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'program_credit',
                required: false,
                title: 'Received through Program',
                placeholder: 'Search by name...',
                component: 'program_lookup',
                value: props.program_credit,
                onChange: program => onUpdateTarget({ program_credit: program })
            },{
                key: 'priority',
                required: false,
                title: 'Priority',
                component: 'list',
                value: props.priority && props.priority.title,
                onChange: item => onUpdateTarget({ priority: item }),
                items: [{
                    id: 'yes',
                    title: 'Yes'
                },{
                    id: 'no',
                    title: 'No'
                }]
            },{
                key: 'out_of_service_area',
                required: false,
                title: 'Out of Service Area',
                component: 'list',
                value: props.out_of_service_area && props.out_of_service_area.title,
                onChange: item => onUpdateTarget({ out_of_service_area: item }),
                items: [{
                    id: 'yes',
                    title: 'Yes'
                },{
                    id: 'no',
                    title: 'No'
                }]
            },{
                key: 'affiliate',
                required: false,
                title: 'Referred By Other Lead',
                component: 'lead_lookup',
                value: props.affiliate,
                onChange: affiliate => onUpdateTarget({ affiliate: affiliate }),
                props: {
                    placeholder: 'Search by first or last name...'
                }
            },{
                key: 'status',
                required: false,
                title: 'Status',
                component: 'list',
                value: props.status && props.status.title,
                onChange: item => onUpdateTarget({ status: item }),
                items: utils.dealership.status_codes.get().map(entry => ({
                    ...entry,
                    id: entry.code,
                    title: entry.text
                }))
            },{ 
                key: 'tags',
                required: false,
                title: 'Tags',
                component: 'tag_lookup',
                value: props.tags,
                onChange: tags => onUpdateTarget({ tags: tags })
            }]
        }]
    }

    const setupTarget = async () => {
        try {
            let { homeowner_status_codes, marital_status_codes, occupational_status_codes } = await Request.get(utils, '/leads/', {
                type: 'edit_targets'
            });

            setHomeownerStatusCodes(homeowner_status_codes);
            setMaritalStatusCodes(marital_status_codes);
            setOccupationalStatusCodes(occupational_status_codes);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the update process. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            });
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Batch Update Lead Details'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const CallEmailFilters = ({ onChange, upcoming, value = {} }, { index, options, utils }) => {

    const layerID = 'call_email_filters';

    const [assignedUsers, setAssignedUsers] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [callLogs, setCallLogs] = useState([]);
    const [didFetchFilterOptions, setDidFetchFilterOptions] = useState(false);
    const [directions, setDirections] = useState([]);
    const [filterValues, setFilterValues] = useState(null);
    const [followUps, setFollowUps] = useState([]);
    const [layerState, setLayerState] = useState(false);
    const [loading, setLoading] = useState(true);
    const [methods, setMethods] = useState([]);
    const [searchProps, setSearchProps] = useState(value || {});

    const onUpdateSearchProps = props => {
        setSearchProps(prev => ({
            ...prev,
            ...props
        }));
    }

    const getButtons = () => {
        return [{
            key: 'reset',
            text: 'Reset Filters',
            color: 'dark',
            onClick: () => {
                setLayerState('close');
                if(typeof(onChange) === 'function') {
                    onChange({});
                }
            }
        },{
            key: 'apply',
            text: 'Apply Filters',
            color: 'primary',
            onClick: () => {
                setLayerState('close');
                if(typeof(onChange) === 'function') {
                    onChange(searchProps);
                }
            }
        }];
    }

    const getFilterContent = () => {
        if(loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 100,
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 40,
                        width: 40
                    }}/>
                </div>
            )
        }

        let fields = [{
            key: 'details',
            title: 'About the Call/Email',
            items: [{
                key: 'direction',
                required: false,
                title: 'Direction',
                component: 'multiple_list',
                value: searchProps.directions && directions.filter(val => searchProps.directions.includes(val.id)),
                items: directions,
                onChange: items => onUpdateSearchProps({ 'directions': items && items.map(item => item.id) })
            },{
                key: 'follow_up',
                required: false,
                visible: upcoming === false,
                title: 'Follow Up',
                component: 'multiple_list',
                value: searchProps.follow_up && followUps.filter(val => searchProps.follow_up.includes(val.id)),
                items: followUps,
                onChange: items => onUpdateSearchProps({ 'follow_up': items && items.map(item => item.id) })
            },{
                key: 'method',
                required: false,
                title: 'Method',
                component: 'multiple_list',
                value: searchProps.methods && methods.filter(val => searchProps.methods.includes(val.id)),
                items: methods,
                onChange: items => onUpdateSearchProps({ 'methods': items && items.map(item => item.id) })
            }]
        },{
            key: 'users',
            title: 'Users',
            items: [{
                key: 'assigned_users',
                required: false,
                title: 'Assigned To',
                component: 'multiple_list',
                value: searchProps.assigned_users && assignedUsers.filter(val => searchProps.assigned_users.includes(val.id)),
                items: assignedUsers,
                onChange: items => onUpdateSearchProps({ 'assigned_users': items && items.map(item => item.id) })
            },{
                key: 'author',
                required: false,
                title: 'Created By',
                component: 'multiple_list',
                value: searchProps.authors && authors.filter(val => searchProps.authors.includes(val.id)),
                items: authors,
                onChange: items => onUpdateSearchProps({ 'authors': items && items.map(item => item.id) })
            }]
        }].map(section => {
            section.items = section.items.filter(item => item.items ? item.items.length > 0 : true);
            return section;
        });

        return (
            <AltFieldMapper
            utils={utils}
            fields={fields} />
        )
    }

    const fetchFilterTargets = async () => {
        try {
            await Utils.sleep(0.5);
            await Utils.sleep(0.5);
            let { users } = await Request.get(utils, '/dealerships/', {
                type: 'call_log_filter_targets'
            })

            setAuthors(users.map(target => ({
                id: target.user_id,
                title: target.full_name
            })));
            setAssignedUsers(users.map(target => ({
                id: target.user_id,
                title: target.full_name
            })).concat([{
                id: null,
                title: 'Unassigned',
                selected: false
            }]));
            setDirections([{
                id: 'inbound',
                title: 'Inbound'
            },{
                id: 'outbound',
                title: 'Outbound'
            }]);
            setFollowUps([{
                id: 'scheduled',
                title: 'Scheduled'
            },{
                id: 'not_scheduled',
                title: 'Not Scheduled'
            }]);
            setMethods([{
                id: 'email',
                title: 'Email'
            },{
                id: 'phone',
                title: 'Phone Call'
            }]);

            setLoading(false);
            setDidFetchFilterOptions(true);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the filter options. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchFilterTargets();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Call and Email Filters'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState
        }}>
            {getFilterContent()}
        </Layer>
    )
}

export const CallLogDetails = ({ abstract, index, options, utils }) => {

    const layerID = `call_log_details_${abstract.getID()}`;
    const eventsLimit = 5;

    const [events, setEvents] = useState([]);
    const [eventsOffset, setEventsOffset] = useState(0);
    const [eventsPaging, setEventsPaging] = useState(null);
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);

    const onEditClick = () => {
        utils.layer.open({
            abstract: abstract,
            Component: AddEditCallLog.bind(this, {
                canSetStatus: false,
                isNewTarget: false,
                lead: abstract.object.lead
            }),
            id: `edit_call_log_${abstract.getID()}`,
            permissions: ['calls.actions.edit']
        })
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'delete',
                permissions: ['calls.actions.delete'],
                title: 'Delete',
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            if(key === 'delete') {
                onDeleteCallLog();
                return;
            }
        })
    }

    const onDeleteCallLog = () => {
        utils.alert.show({
            title: 'Delete Call',
            message: 'Are you sure that you want to delete this call? This action can not be undone',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteCallLogConfirm();
                    return;
                }
            }
        });
    }

    const onDeleteCallLogConfirm = async () => {
        try {

            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/demos/', {
                type: 'delete_call_log',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('call_log');

            utils.alert.show({
                title: 'All Done!',
                message: `This ${abstract.object.method === 'email' ? 'email' : 'phone call'} has been deleted`,
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this ${abstract.object.method === 'email' ? 'email' : 'phone call'}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNewSystemEvent = data => {
        console.log(data);
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system_event_details_${evt.id}`,
            abstract: Abstract.create({
                type: 'system_event',
                object: evt
            }),
            Component: SystemEventDetails
        })
    }

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }];
    }

    const getFields = () => {

        // prepare field mapper items
        let callLog = abstract.object;
        let items = [{
            key: 'date',
            permissions: ['calls.details.dates'],
            title: 'Date and Time',
            items: [{
                key: 'start_date',
                title: 'Call Date and Time',
                value: callLog.start_date ? moment(callLog.start_date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'duration',
                title: 'Duration',
                value: callLog.start_date && callLog.end_date ? Utils.parseDuration(moment(callLog.end_date).unix() - moment(callLog.start_date).unix()) : null,
                visible: callLog.end_date ? true : false
            }]
        },{
            key: 'date',
            permissions: ['calls.details.follow_up'],
            title: 'Follow Up Date and Time',
            items: [{
                key: 'follow_up_start_date',
                title: 'Call Date and Time',
                value: callLog.follow_up_start_date ? moment(callLog.follow_up_start_date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'duration',
                title: 'Duration',
                value: callLog.follow_up_start_date && callLog.follow_up_end_date ? Utils.parseDuration(moment(callLog.follow_up_end_date).unix() - moment(callLog.follow_up_start_date).unix()) : null,
                visible: callLog.follow_up_end_date ? true : false
            }]
        },{
            key: 'details',
            permissions: ['calls.details.general'],
            title: 'Details',
            items: [{
                key: 'direction',
                title: 'Direction',
                value: callLog.direction ? Utils.ucFirst(callLog.direction) : null
            },{
                key: 'method',
                title: 'Method',
                value: callLog.method ? Utils.ucFirst(callLog.method) : null
            }]
        },{
            key: 'additional',
            permissions: ['calls.details.ext'],
            title: 'Additional Information',
            items: [{
                key: 'assign_to',
                title: 'Assignment',
                value: callLog.assign_to ? callLog.assign_to.full_name : null
            },{
                key: 'notes',
                title: 'Notes',
                value: callLog.notes
            }]
        }];
        
        // prevent moving forward if no valid items were provided
        let count = formatFields(utils, items).length;
        if(count === 0) {
            return null;
        }

        return (
            <FieldMapper 
            fields={items} 
            utils={utils} />
        )
    }

    const getSystemEvents = () => {
        return (
            <SystemEventsLayerItem 
            abstract={abstract} 
            permissions={['calls.details.system_events']}
            utils={utils} />
        )
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.emit('system', 'join_events', { tag: abstract.getTag()} );
            await utils.sockets.on('system', 'on_new_event', onNewSystemEvent);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('system', 'on_new_event', onNewSystemEvent);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchEvents = async () => {
        try {
            setLoading('system_events');
            let { events, paging } = await Request.get(utils, '/utils/', {
                type: 'system_events',
                limit: eventsLimit,
                offset: eventsOffset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setEventsPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchEvents();
    }, [eventsOffset]);

    useEffect(() => {
        connectToSockets();
        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {getFields()}
            {getSystemEvents()}
        </Layer>
    )
}

export const Dialer = ({ index, options, utils }) => {

    const layerID = 'dialer';
    const limit = 15;

    const extendedProps = useRef({});
    const leadEdits = useRef(null);
    const leadsHeader = useRef(null);
    const offset = useRef(0);
    const rebuttals = useRef(null);
    const searchText = useRef(null);
    const sorting = useRef(null);

    const [affiliate, setAffiliate] = useState(null);
    const [callStartDate, setCallStartDate] = useState(null);
    const [defaultScript, setDefaultScript] = useState(null);
    const [editTargets, setEditTargets] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [leads, setLeads] = useState([]);
    const [leadsHeaderHeight, setLeadsHeaderHeight] = useState(0);
    const [loading, setLoading] = useState(true);
    const [notes, setNotes] = useState(null);
    const [paging, setPaging] = useState(null);
    const [rebuttalsHeight, setRebuttalsHeight] = useState(0);
    const [recalls, setRecalls] = useState([]);
    const [selectedLead, setSelectedLead] = useState(null);
    const [selectedLeadIndex, setSelectedLeadIndex] = useState(null);
    const [showRecalls, setShowRecalls] = useState(false);
    const [status, setStatus] = useState({
        id: Lead.status.get().not_home,
        title: 'Not Home'
    });
    const [statusCodes, setStatusCodes] = useState([]);

    const onAffiliateClick = async () => {
        try {
            let lead = await Lead.get(utils, affiliate.id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead'
                }),
                Component: LeadDetails,
                id: `lead_details_${lead.id}`,
                permissions: ['leads.details']
            });
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onChangeRecallToggle = val => {
        setShowRecalls(val);
        setSelectedLead(null);
        setSelectedLeadIndex(0);
    }

    const onLeadClick = index => {
        setSelectedLeadIndex(index);
    }

    const onOpenSelectedLead = async () => {
        try {

            // set loading flag and fetch lead details
            setLoading('lead_details');
            let lead = await Lead.get(utils, selectedLead.id);

            // end loading and show lead details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead'
                }),
                Component: LeadDetails,
                id: `lead_details_${lead.id}`,
                permissions: ['leads.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onRequestSetDemo = async () => {
        return new Promise(resolve => {
            if(status.id !== Lead.status.get().set) {
                resolve();
                return;
            }
            let target = Abstract.create({
                object: selectedLead,
                type: 'lead'
            });
            utils.layer.open({
                abstract: target,
                Component: BookDemoFromLead.bind(this, {
                    onCloseLayer: resolve
                }),
                id: `book_demo_${selectedLead.id}`
            });
        })
    }

    const onRequestRecall = async () => {
        return new Promise(resolve => {
            if(status.id !== Lead.status.get().recall) {
                resolve();
                return;
            }

            utils.alert.show({
                title: 'Schedule Recall',
                message: 'Would you like to setup a time and date for the recall?',
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Later',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key !== 'confirm') {
                        resolve();
                        return;
                    }

                    let call = CallLog.new();
                    call.assign_to = utils.user.get();
                    call.author = utils.user.get();
                    call.direction = 'outbound';
                    call.end_date = moment();
                    call.lead = selectedLead;
                    call.method = 'phone';
                    call.start_date = moment(callStartDate);

                    utils.layer.open({
                        id: 'new_follow_up_call',
                        abstract: Abstract.create({
                            type: 'call_log',
                            object: call
                        }),
                        Component: AddEditFollowUpCall.bind(this, {
                            isNewTarget: true,
                            onClose: resolve
                        })
                    });
                }
            });
        })
    }

    const onSaveClick = async () => {

        // check if a recall should be scheduled
        let recall = await onRequestRecall();

        // update extended props for lead update
        extendedProps.current = {
            affiliate: affiliate,
            call_start_date: callStartDate,
            dialer: true,
            notes: notes,
            status: status.id,
            ...recall && {
                call_follow_up_end_date: recall.follow_up_end_date,
                call_follow_up_notes: recall.notes,
                call_follow_up_start_date: recall.follow_up_start_date
            }
        }
        setLoading('submit');
    }

    const onSearchTextChange = text => {

        // update offset and search text refs
        offset.current = 0;
        searchText.current = text;

        // fetch content based on which content toggle is active
        fetchDialerContent();
    }

    const onSetLeadTarget = () => {
        try {
            // set next lead selection
            let targets = showRecalls ? recalls : leads;
            if(selectedLeadIndex >= 0 && targets.length > 0) {
                let target = targets[selectedLeadIndex || 0];
                if(!target) {
                    throw new Error('unable to locate next lead/recall target');
                }
                let lead = showRecalls ? target.lead : target;
                setAffiliate(lead.affiliate);
                setCallStartDate(moment().format('YYYY-MM-DD HH:mm:ss'));
                setNotes(lead.notes);
                setStatus({
                    id: Lead.status.get().not_home,
                    title: 'Not Home'
                });
                setSelectedLead(lead);
            }
        } catch(e) {
            setSelectedLead(null);
            console.error(e.message);
        }
    }

    const onSetNextLead = async () => {
        try {

            // check if status was changed to set and request that a demo be set if applicable
            await onRequestSetDemo();

            // filter out leads that are no longer actionable
            let targets = leads.filter(lead => lead.status.code === Lead.status.get().assigned);
            setLeads(targets);

            // prevent moving forward if no more leads are available
            if((selectedLeadIndex + 1) > targets.length) {
                utils.alert.show({
                    title: 'Congratulations!',
                    message: 'You have reached the end of your assigned leads list.'
                });
                return;
            }

            // add to list of recalls if status was set to recall
            // full recall object not needed since data is fetched when focus is changed to recalls
            if(status.id === Lead.status.get().recall) {
                setRecalls(prev => update(prev, {
                    $push: [{
                        call: {},
                        lead: selectedLead
                    }]
                }));
            }

            // advance to next lead if no leads were filtered out
            // the index auto advancese when the previous lead was filtered out
            if(targets.length === leads.length) {
                setSelectedLeadIndex(val => val + 1);
            }

        } catch(e) {
            console.error(e.message);
        }
    }
    
    const onShowNextLead = evt => {
        evt.stopPropagation();
        onSetNextLead();
    }

    const onShowPreviousLead = evt => {
        evt.stopPropagation();
        if(selectedLeadIndex === 0) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'You have reached the beginning of your assigned leads list.'
            });
            return;
        }
        setSelectedLeadIndex(val => val - 1);
    }

    const onSortingChange = props => {
        sorting.current = props;
        setLoading('sorting');
        fetchDialerContent();
    }

    const getContent = () => {
        return (
            <>
            <RebuttalsCollection
            ref={rebuttals}
            lead={selectedLead}
            title={true}
            utils={utils}
            style={{
                marginBottom: 15
            }}/>

            <div className={'row m-0'}>
                <div className={'col-12 col-lg-4 p-0'}>
                    {getLeadsList()}
                </div>
                <div
                className={'col-12 col-lg-8'}
                style={{
                    padding: 0,
                    paddingLeft: 15
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: getLayerSizingHeight('fullscreen') - rebuttalsHeight - 64,
                        overflow: 'hidden',
                        width: '100%'
                    }}>
                        {getLeadDetails()}
                        {getLeadTarget() && getLeadScript()}
                    </div>
                </div>
            </div>
            </>
        )
    }

    const getPrependValue = (lead, recall) => {
        if(recall && moment(recall.date) < moment()) {
            return (
               <div style={{
                   width: 10,
                   height: 10,
                   minWidth: 10,
                   minHeight: 10,
                   borderRadius: 5,
                   backgroundColor: Appearance.colors.red,
                   overflow: 'hidden',
                   marginRight: 8
               }} />
           )
        }
        if(lead.priority) {
            return (
               <div style={{
                   width: 10,
                   height: 10,
                   minWidth: 10,
                   minHeight: 10,
                   borderRadius: 5,
                   backgroundColor: Appearance.colors.primary(),
                   overflow: 'hidden',
                   marginRight: 8
               }} />
           )
        }
        return null;
    }

    const getFields = (lead, recall) => {
        return [{
            key: 'full_name',
            title: 'Name',
            value: lead.full_name || 'Customer name not available',
            prepend: getPrependValue(lead, recall)
        },{
            key: 'follow_up_start_date',
            title: 'Recall Date',
            value: recall && Utils.formatDate(recall.date),
            visible: showRecalls && recall ? true : false
        },{
            key: 'phone_number',
            title: 'Phone Number',
            sortable: false,
            value: lead.phone_number
        },{
            key: 'address',
            title: 'Address',
            value: lead.address ? lead.address.locality : null
        },{
            key: 'lead_type',
            title: 'Lead Type',
            value: lead.lead_type && (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {lead.lead_type.icon && (
                        <img
                        src={lead.lead_type.icon.url}
                        style={{
                            width: 20,
                            height: 20,
                            objectFit: 'contain',
                            marginRight: 8
                        }} />
                    )}
                    <span>{lead.lead_type.text}</span>
                </div>
            )
        },{
            key: 'status',
            title: 'Status',
            sortable: false,
            value: getLeadStatus(utils, lead)
        }].filter(entry => {
            return entry.visible !== false;
        });
    }

    const getHeaderFields = () => {
        return [{
            key: 'full_name',
            title: 'Name'
        },{
            key: 'follow_up_start_date',
            title: 'Recall Date',
            visible: showRecalls ? true : false
        },{
            key: 'phone_number',
            title: 'Phone Number',
            sortable: false
        },{
            key: 'locality',
            title: 'City'
        },{
            key: 'lead_type',
            title: 'Lead Type'
        },{
            key: 'status',
            title: 'Status'
        }];
    }

    const getLeads = () => {
        if(leads.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No leads are currently assigned to your account',
                    title: 'No Leads Found'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <tbody style={{
                    width: '100%'
                }}>
                    <TableListHeader
                    fields={getHeaderFields()}
                    flexible={false}
                    onChange={onSortingChange} />
                    {leads.map((lead, index) => {
                        return (
                            <tr
                            key={index}
                            className={`view-entry ${window.theme} ${selectedLead && selectedLead.id === lead.id ? 'selected' : ''}`}
                            onClick={onLeadClick.bind(this, index)}
                            style={{
                                borderBottom: index !== leads.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                            }}>
                                {getFields(lead).map((field, index) => {
                                    return (
                                        <td
                                        key={index}
                                        className={'px-3 py-2'}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}>
                                                {field.prepend}
                                                <span style={{
                                                    ...Appearance.textStyles.subTitle(),
                                                    width: '100%'
                                                }}>{field.value}</span>
                                            </div>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    const getLeadDetails = () => {
        let abstract = getLeadTarget();
        return (
            <div
            ref={leadEdits}
            style={{
                width: '100%'
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: 15
                }}>
                    <div className={'row p-0 m-0'}>
                        <div className={'col-12 col-lg-9 px-0 pt-0 pb-2 pr-lg-2 pb-lg-0'}>
                            {getLeadDetailsContent()}
                        </div>
                        <div className={'col-12 col-lg-3 p-0 pl-lg-2 pr-lg-3 pt-lg-2'}>
                            {abstract && loading !== true && (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%'
                                }}>
                                    <AltFieldItem
                                    utils={utils}
                                    item={{
                                        key: 'notes',
                                        required: false,
                                        title: 'Comments',
                                        component: 'textview',
                                        value: notes,
                                        onChange: text => setNotes(text),
                                        props: {
                                            fieldStyle: {
                                                minHeight: 95
                                            }
                                        }
                                    }} />
                                    <AltFieldItem
                                    utils={utils}
                                    containerStyle={{
                                        marginBottom: 0,
                                        marginTop: 10
                                    }}
                                    item={{
                                        key: 'affiliate',
                                        required: false,
                                        title: 'Referred By Other Lead',
                                        component: 'lead_lookup',
                                        value: affiliate,
                                        onChange: affiliate => setAffiliate(affiliate),
                                        props: {
                                            inline: false,
                                            placeholder: 'Search by first or last name...',
                                            appendContent: affiliate && (
                                                <img
                                                className={'text-button'}
                                                src={'images/details-button-light-grey.png'}
                                                onClick={onAffiliateClick}
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                    marginLeft: 8
                                                }} />
                                            )
                                        }
                                    }} />
                                    <AltFieldItem
                                    utils={utils}
                                    containerStyle={{
                                        marginBottom: 0,
                                        marginTop: 20
                                    }}
                                    item={{
                                        component: 'list',
                                        disablePlaceholder: true,
                                        key: 'status',
                                        items: statusCodes,
                                        onChange: setStatus,
                                        required: false,
                                        title: 'Status',
                                        value: status && status.title
                                    }} />
                                    <div style={{
                                        marginBottom: 15,
                                        marginTop: 50
                                    }}>
                                        <Button
                                        type={'large'}
                                        label={'Save'}
                                        color={'primary'}
                                        loading={loading === 'submit'}
                                        onClick={onSaveClick} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getLeadDetailsContent = () => {
        let abstract = getLeadTarget();
        if(loading === true || !abstract) {
            return (
                <div style={{
                    flexGrow: 1
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        hideIcon: true,
                        subTitle: 'Please select a lead to continue...',
                        title: 'Waiting on Lead...'
                    })}
                </div>
            )
        }
        return (
            <AddEditLeadContent
            abstract={abstract}
            dialer={true}
            editTargets={editTargets}
            loading={loading}
            isNewTarget={false}
            onUpdateLead={onSetNextLead}
            setLoading={setLoading}
            utils={utils}
            extendedProps={extendedProps.current} />
        )
    }

    const getLeadsList = () => {
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                <div ref={leadsHeader}>
                    <div style={{
                        width: '100%',
                        padding: 12,
                        borderBottom: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        <BoolToggle
                        enabled={'Recalls'}
                        enabledBadge={recalls.length}
                        disabled={'Leads'}
                        disabledBadge={leads.length}
                        isEnabled={showRecalls}
                        onChange={onChangeRecallToggle}
                        labelStyle={Appearance.textStyles.title()}
                        style={{
                            marginBottom: 8
                        }}/>
                        {selectedLead && loading !== true && (
                            <div
                            className={'text-button'}
                            onClick={onOpenSelectedLead}
                            style={{
                                alignItems: 'center',
                                background: Appearance.colors.softGradient(Appearance.colors.primary()),
                                border: `2px solid ${Appearance.colors.primary()}`,
                                borderRadius: 10,
                                display: 'flex',
                                flexDirection: 'row',
                                height: 60,
                                justifyContent: 'space-between',
                                overflow: 'hidden',
                                padding: '6px 10px 6px 10px',
                                position: 'relative'
                            }}>
                                <img
                                className={'text-button'}
                                onClick={onShowPreviousLead}
                                src={'images/back-arrow-icon-white-small.png'}
                                style={{
                                    height: 30,
                                    marginRight: 8,
                                    objectFit: 'contain',
                                    width: 30,
                                }} />
                                {loading === 'lead_details' && (
                                    <LottieView
                                    autoPlay={true}
                                    loop={true}
                                    source={require('files/lottie/dots-white.json')}
                                    style={{
                                        height: 40,
                                        width: 40
                                    }}/>
                                )}
                                {loading === false && (
                                    <div style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <span style={{
                                            ...Appearance.textStyles.subHeader(),
                                            color: 'white',
                                            fontWeight: 800
                                        }}>{selectedLead.full_name || 'Customer name not available'}</span>
                                        <span style={{
                                            ...Appearance.textStyles.title(),
                                            color: 'white',
                                            fontWeight: 600
                                        }}>{selectedLead.phone_number || 'No phone number provided'}</span>
                                    </div>
                                )}
                                <img
                                className={'text-button'}
                                onClick={onShowNextLead}
                                src={'images/next-arrow-icon-white-small.png'}
                                style={{
                                    width: 30,
                                    height: 30,
                                    objectFit: 'contain',
                                    marginLeft: 8
                                }} />
                            </div>
                        )}
                    </div>
                    {loading !== true && (
                        <div style={{
                            padding: 12,
                            borderBottom: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            <TextField
                            useDelay={true}
                            value={searchText.current}
                            placeholder={'Search by customer name or phone number...'}
                            onChange={onSearchTextChange} />
                        </div>
                    )}
                </div>
                {getTargetContent()}
            </div>
        )
    }

    const getLeadScript = () => {
        let target = selectedLead && selectedLead.lead_script || defaultScript;
        if(!target || loading === true) {
            return null;
        }
        return (
            <div
            className={`custom-scrollbars ${window.theme}`}
            style={{
                ...Appearance.styles.unstyledPanel(),
                flexGrow: 1,
                padding: '8px 12px 8px 12px',
                width: '100%',
                height: '100%',
                overflowY: 'scroll'
            }}>
                {formatLeadScriptContent({
                    lead: selectedLead,
                    editable: false,
                    text: target.text,
                    utils: utils
                })}
            </div>
        )
    }

    const getLeadTarget = () => {
        return selectedLead && Abstract.create({
            type: 'lead',
            object: selectedLead
        });
    }

    const getRecalls = () => {
        if(recalls.length === 0) {
            return (
                Views.entry({
                    title: 'No Recalls Found',
                    subTitle: 'No recalls are currently assigned to your account',
                    hideIcon: true,
                    bottomBorder: false
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <tbody style={{
                    width: '100%'
                }}>
                    <TableListHeader
                    fields={getHeaderFields()}
                    flexible={false}
                    onChange={onSortingChange} />
                    {recalls.map((entry, index) => {
                        return (
                            <tr
                            key={index}
                            className={`view-entry ${window.theme} ${selectedLead && selectedLead.id === entry.lead.id ? 'selected' : ''}`}
                            onClick={onLeadClick.bind(this, index)}
                            style={{
                                borderBottom: index !== recalls.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                            }}>
                                {getFields(entry.lead, entry.call_log).map((field, index) => {
                                    return (
                                        <td
                                        key={index}
                                        className={'px-3 py-2'}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}>
                                                {field.prepend}
                                                <span style={{
                                                    ...Appearance.textStyles.subTitle(),
                                                    width: '100%'
                                                }}>{field.value}</span>
                                            </div>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    const getTargetContent = () => {
        if(loading === true) {
            return (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    padding: 24
                }}>
                    <LottieView
                    loop={true}
                    autoPlay={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        width: 50,
                        height: 50
                    }}/>
                </div>
            )
        }
        return (
            <>
            <div
            className={`custom-scrollbars ${window.theme}`}
            style={{
                width: '100%',
                maxHeight: getLayerSizingHeight('fullscreen') - ToolbarHeight - rebuttalsHeight - leadsHeaderHeight - 45,
                overflowY: 'scroll'
            }}>
                {showRecalls === false && getLeads()}
                {showRecalls === true && getRecalls()}
            </div>
            {paging && (
                <PageControl
                data={paging}
                limit={limit}
                offset={offset.current}
                onClick={next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchDialerContent();
                }} />
            )}
            </>
        )
    }

    const fetchDialerContent = () => {
        if(showRecalls) {
            fetchRecalls();
        } else {
            fetchLeads();
        }
    }

    const fetchLeads = async () => {
        try {
            // add delay if search text is present
            if(searchText.current) {
                await Utils.sleep(0.25);
            }

            // send request to server
            let { leads, paging } = await Request.get(utils, '/leads/', {
                limit: 250,
                search_props: {
                    general_assignment: utils.user.get().user_id,
                    text: searchText.current
                },
                type: 'dialer',
                ...sorting.current
            });

            // end loading for ui and set paging
            setLoading(false);
            setPaging(paging);

            // format recalls and set selected target index
            setLeads(leads.map(lead => Lead.create(lead)));
            if(leads.length > 0 && selectedLeadIndex === null) {
                setSelectedLeadIndex(0);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of leads. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchRecalls = async () => {
        try {
            // add delay if search text is present
            if(searchText.current) {
                await Utils.sleep(0.25);
            }

            // send request to server
            let { paging, recalls } = await Request.get(utils, '/leads/', {
                limit: 250,
                search_text: searchText.current,
                type: 'recalls',
                ...sorting.current
            });

            // end loading for ui and set paging
            setLoading(false);
            setPaging(paging);

            // format recalls and set selected target index
            setRecalls(recalls.map(recall => {
                recall.call_log.date = moment(recall.call_log.date);
                recall.lead = Lead.create(recall.lead);
                return recall;
            }));
            if(recalls.length > 0 && selectedLeadIndex === null) {
                setSelectedLeadIndex(0);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of recalls. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchEditTargets = async () => {
        try {
            let results = await Request.get(utils, '/leads/', {
                type: 'edit_targets'
            });
            setEditTargets(results);
            setDefaultScript(results.default_lead_script);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the lead editing process. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupStatusCodes = () => {
        let codes = utils.dealership.status_codes.get().filter(entry => {
            return entry.capabilities.leads;
        }).map(entry => ({
            id: entry.code,
            title: entry.text
        }));
        setStatusCodes(codes);
    }

    useEffect(() => {
        if(editTargets) {
            setLoading(true);
            fetchDialerContent();
        }
    }, [editTargets, showRecalls]);

    useEffect(() => {
        if(leadsHeader.current) {
            setLeadsHeaderHeight(leadsHeader.current.clientHeight);
        }
    }, [leadsHeader.current]);

    useEffect(() => {
        if(rebuttals.current) {
            setRebuttalsHeight(rebuttals.current.clientHeight);
        }
    }, [rebuttals.current]);

    useEffect(() => {
        onSetLeadTarget();
    }, [leads, recalls, selectedLeadIndex, showRecalls]);

    useEffect(() => {
        fetchEditTargets();
        setupStatusCodes();
    }, []);

    return (
        <Layer
        id={layerID}
        index={index}
        title={'Marketing Dialer'}
        utils={utils}
        style={{
            padding: 15
        }}
        options={{
            ...options,
            loading: loading === true,
            layerState: layerState,
            onCloseLayer: utils.content.fetch.bind(this, 'lead'),
            sizing: 'fullscreen'
        }}>
            {getContent()}
        </Layer>
    )
}

export const EnrollLeadProgram = ({ abstract, index, options, utils }) => {

    const layerID = `enroll_lead_program_${abstract.getID()}`;
    const [loading, setLoading] = useState(true);
    const [layerState, setLayerState] = useState(null);
    const [programs, setPrograms] = useState([]);
    const [program, setProgram] = useState(null);

    const onEnrollProgram = async () => {
        if(!program) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please choose a Program before moving on`
            });
            return;
        }

        try {
            setLoading(true);
            await Request.post(utils, '/leads/', {
                type: 'enroll_program',
                id: abstract.getID(),
                program_id: program.id
            });

            setLoading(false);
            utils.content.fetch('lead_program');

            utils.alert.show({
                title: 'All Done!',
                message: `The ${abstract.getTitle()} has been enrolled in the Program "${program.name}"`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue enrolling in this Program. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            onClick: onEnrollProgram,
            text: 'Enroll'
        }];
    }

    const getProgramList = () => {
        return (
            <select
            className={`custom-select ${window.theme}`}
            value={program ? program.name : 'Choose a Program'}
            style={{
                marginBottom: 8
            }}
            onChange={e => {
                let id = Utils.attributeForKey.select(e, 'id');
                setProgram(programs.find(program => program.id === parseInt(id)));
            }}>
                <option disabled={true}>{'Choose a Program'}</option>
                {programs.map((program, index) => {
                    return (
                        <option key={index} id={program.id}>{program.name}</option>
                    )
                })}
            </select>
        )
    }
    const fetchPrograms = async () => {
        try {
            let { programs } = await Request.get(utils, '/dealerships/', {
                type: 'programs',
                id: abstract.getID()
            })
            setLoading(false);
            setPrograms(programs.map(program => Program.create(program)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the programs for your dealership. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        fetchPrograms();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Program Enrollment'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {getProgramList()}
        </Layer>
    )
}

export const ImportLeads = ({ index, options, utils }) => {

    const layerID = 'import_leads';
    const limit = 5;

    const [customValues, setCustomValues] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [file, setFile] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [leads, setLeads] = useState(null);
    const [loading, setLoading] = useState(true);
    const [maps, setMaps] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [selectedMap, setSelectedMap] = useState({ props: {} });
    const [selectedDocument, setSelectedDocument] = useState(null);

    const onBackToUpload = () => {
        utils.alert.show({
            title: 'Just a Second',
            message: 'Are you sure that you want to return to the upload screen? Any unsaved changes will be lost.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Go Back',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setFile(null);
                    setLeads(null);
                    setSelectedDocument(null);
                    return;
                }
            }
        });
    }

    const onConfirmUpload = async () => {
        try {

            setLoading('done');
            await Utils.sleep(0.25);

            // check that at least one map selection has been made
            let keys = selectedMap ? Object.keys(selectedMap.props) : [];
            if(keys.length === 0) {
                throw new Error('It looks like you have not made any selections for your import. Click at least one drop down menu above a column to let us know what to do with the information in that column before moving on.');
            }

            // submit selections to server
            let { count, status } = await Request.post(utils, '/leads/', {
                type: 'import',
                document_id: selectedDocument.id,
                mapping: selectedMap.props
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: status === 'queued' ? 'Your lead import has been submitted. We will notify you when the import process has completed.' : `Your lead import has been completed. We were able to import ${count} ${count === 1 ? 'lead' : 'leads'} into your dealership.`,
                onClick: () => {
                    utils.content.fetch(['lead', 'lead_type', 'lead_sub_type', 'status']);
                    setLayerState('close');
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your lead import preferences. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onCreateNewCustomColumn = async (index, title) => {
        try {
            setLoading(true);
            await Utils.sleep(0.5);
            let { id } = await Request.post(utils, '/dealerships/', {
                type: 'new_lead_column',
                title: title
            });

            setLoading(false);
            onUpdateHeaderMapValues(index, { id });
            setCustomValues(values => {
                return values.concat([{ 
                    id: id,
                    removable: true, 
                    title: title 
                }])
            });

        } catch(e) {
            setLoading(false);
            onUpdateHeaderMapValues(index);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your custom column. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onHeaderListItemChange = (index, item) => {

        // prevent moving forward if no item was selected
        if(!item || !item.id) {
            return;
        }

        // set selected item if item is not requesting a custom column configuration
        if(item.id !== 'custom') {
            onUpdateHeaderMapValues(index, item);
            return;
        }

        // request a column name from the user
        let title = null;
        utils.alert.show({
            title: 'Custom Column',
            message: 'What would you like to call this column of data?',
            content: (
                <div style={{
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    width: '100%'
                }}>
                    <TextField
                    placeholder={'Column Name'}
                    onChange={text => title = text}/>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm' && title) {
                    onCreateNewCustomColumn(index, title);
                    return;
                }
            }
        });
    }

    const onNewMapClick = () => {
        let name = null;
        utils.alert.show({
            title: 'Save Layout as a Preset',
            message: 'We can save your current import layout as a preset that you can use during a future import. What would you like to call your preset?',
            content: (
                <div style={{
                    width: '100%',
                    padding: 12
                }}>
                    <TextField
                    placeholder={'Preset Name'}
                    onChange={text => name = text} />
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Save',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(name && key === 'confirm') {
                    onSaveMapLayout(name);
                    return;
                }
            }
        });
    }

    const onRemoveCustomLeadColumn = item => {
        utils.alert.show({
            title: 'Remove Column',
            message: `Are you sure that you want to remove the "${item.title}" column? This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveCustomLeadColumnConfirm(item.id);
                    return;
                }
            }
        });
    }

    const onRemoveCustomLeadColumnConfirm = async id => {
        try {
            setLoading(true);
            await Request.post(utils, '/dealerships/', {
                id: id,
                type: 'remove_lead_column'
            });

            setLoading(false);
            setCustomValues(values => values.filter(value => value.id !== id));
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing your column. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onRemoveDocument = (doc, evt) => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Remove Imported Document',
            message: 'When removing a document, we can remove all the leads that were imported from the document as well. Would you like to remove the document and the leads associated with it or keep the leads in your dealership? Neither of these actions can be undone.',
            buttons: [{
                key: 'document',
                title: 'Remove Document',
                style: 'destructive'
            },{
                key: 'leads',
                title: 'Remove Document and Leads',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(['document', 'leads'].includes(key)) {
                    onRemoveDocumentConfirm(doc, key === 'leads');
                    return;
                }
            }
        });
    }

    const onRemoveDocumentConfirm = async (doc, removeLeads) => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/leads/', {
                document_id: doc.id,
                remove_leads: removeLeads,
                type: 'remove_import_document'
            });

            setLoading(false);
            setDocuments(docs => docs.filter(prev => prev.id !== doc.id));
            utils.alert.show({
                title: 'All Done!',
                message: `This document has been removed ${removeLeads ? 'along with all leads imported from this document.' : 'but the leads have been preserved in your dealership.'}`,
                onClick: utils.content.fetch.bind(this, 'lead')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this document from your history of imported documents. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSaveMapLayout = async name => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            let keys = selectedMap ? Object.keys(selectedMap.props) : [];
            if(keys.length === 0) {
                throw new Error('It looks like you have not made any selections for your layout. Click at least one of the drop down menus above a column to let us know what to do with the information in that column.');
            }

            // submit selection to server
            let { id } = await Request.post(utils, '/leads/', {
                type: 'new_lead_import_map',
                custom: customValues,
                name: name,
                props: selectedMap.props
            });

            setLoading(false);
            setMaps(maps => update(maps, {
                $push: [{
                    ...selectedMap,
                    id: id,
                    name: name
                }],
                $apply: maps => maps.sort((a,b) => {
                    return a.name.localeCompare(b.name)
                })
            }));

            utils.alert.show({
                title: 'All Done!',
                message: 'Your layout preset has been saved for future use.'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your layout preset. ${e.message || 'An unknown error occurred'}.`
            });
        }
    }

    const onSelectDocument = doc => {
        setFile(null);
        setSelectedDocument(prev => prev && prev.id === doc.id ? null : doc);
    }

    const onUpdateHeaderMapValues = (index, item) => {
        setSelectedMap(target => {
            return {
                ...target,
                props: update(target.props, {
                    [index]: {
                        $set: {
                            index: index,
                            id: item && item.id
                        }
                    }
                })
            };
        });
    }

    const onUploadDocument = async props => {
        try {
            setLoading('next');
            await Utils.sleep(0.25);

            let response = await Request.post(utils, '/leads/', {
                document_id: selectedDocument && selectedDocument.id,
                file: file,
                type: 'new_import_document',
                ...props
            });

            setLoading(false);
            setLeads(response.leads);
            setSelectedDocument(response.document);
            if(file) {
                setDocuments(docs => {
                    return update(docs, { 
                        $unshift: [{
                            ...response.document,
                            date: moment(response.document.date)
                        }] 
                    });
                });
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue reading your document. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        if(leads) {
            return [{
                key: 'back',
                text: 'Back',
                color: 'dark',
                onClick: onBackToUpload
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                loading: loading === 'done',
                onClick: onConfirmUpload
            }]
        }
        return (selectedDocument || file) && [{
            key: 'next',
            text: 'Continue',
            color: 'primary',
            loading: loading === 'next',
            onClick: () => {

                // call function manually to remove onClick evt from arguments
                onUploadDocument();
            }
        }]
    }

    const getContent = () => {
        if(leads) {
            return getLeadsPreview();
        }
        return getDefaultContent();
    }

    const getDefaultContent = () => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 24,
                    whiteSpace: 'normal'
                }}>{getDefaultText()}</span>
                <LayerItem title={'Upload a File'}>
                    <FilePickerField
                    utils={utils}
                    value={file}
                    fileTypes={['xls', 'xlsx']}
                    onChange={file => {
                        setFile(file);
                        setSelectedDocument(null);
                    }} />
                </LayerItem>
                
                {getDocuments()}
            </div>
        )
    }

    const getDefaultText = () => {
        if(documents.length > 0) {
            return `Please choose a document from the list below or click the "Browse" button to select a spreadsheet from your device to continue. You'll have a chance to select which fields you want to include in your lead import on the next screen.`
        }
        return `Click the "Browse" button to select a spreadsheet from your device to continue. You'll have a chance to select which fields you want to include in your lead import on the next screen.`
    }

    const getDocuments = () => {
        return documents.length > 0 && (
            <LayerItem title={'Uploaded Documents'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    textAlign: 'left'
                }}>
                    {documents.map((doc, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: doc.name,
                                subTitle: Utils.formatDate(doc.date),
                                badge: {
                                    text: selectedDocument && selectedDocument.id === doc.id ? 'Selected' : null,
                                    color: Appearance.colors.primary()
                                },
                                icon: {
                                    path: window.theme === 'dark' ? 'images/xls-icon-white.png' : 'images/xls-icon-grey.png',
                                    style: {
                                        boxShadow: null
                                    },
                                    imageStyle: {
                                        backgroundColor: Appearance.colors.transparent,
                                        borderRadius: 0,
                                        objectFit: 'contain'
                                    }
                                },
                                onClick: onSelectDocument.bind(this, doc),
                                bottomBorder: index !== documents.length - 1,
                                rightContent: getDocumentAccessoryIcon(doc)
                            })
                        )
                    })}
                    {paging && (
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset}
                        onClick={next => setOffset(next)} />
                    )}
                </div>
            </LayerItem>
        )
    }

    const getDocumentAccessoryIcon = doc => {

        // only return option to remove document for the dealership's dealer
        let { id } = utils.dealership.get();
        let { dealership_id, level } = utils.user.get();
        let canEditDocuments = level <= User.levels.get().admin || (id === dealership_id && level <= User.levels.get().dealer);
        return canEditDocuments && (
            <img
            className={'text-button'}
            src={'images/red-x-icon.png'}
            onClick={onRemoveDocument.bind(this, doc)}
            style={{
                width: 25,
                height: 25,
                objectFit: 'contain',
                marginLeft: 8
            }}/>
        )
    }

    const getHeaders = () => {
        return [{
            id: 'custom',
            title: '* Custom Column *'
        },{
            id: 'assignment_user',
            title: 'Assigned User'
        },{
            id: 'locality',
            title: 'City'
        },{
            id: 'notes',
            title: 'Comments'
        },{
            id: 'date',
            title: 'Date Created'
        },{
            id: 'email_address',
            title: 'Email Address'
        },{
            id: 'first_name',
            title: 'First Name'
        },{
            id: 'last_name',
            title: 'Last Name'
        },{
            id: 'lead_credit_user',
            title: 'Lead Credit'
        },{
            id: 'lead_script',
            title: 'Lead Script'
        },{
            id: 'lead_sub_type',
            title: 'Lead Sub Type'
        },{
            id: 'lead_type',
            title: 'Lead Type'
        },{
            id: 'homeowner_status',
            title: 'Homeowner Status'
        },{
            id: 'marital_status',
            title: 'Marital Status'
        },{
            id: 'marketing_director_user',
            title: 'Marketing Director'
        },{
            id: 'out_of_service_area',
            title: 'Out of Service Area (Yes/No)'
        },{
            id: 'occupational_status',
            title: 'Occupational Status'
        },{
            id: 'phone_number',
            title: 'Phone Number'
        },{
            id: 'priority',
            title: 'Priority'
        },{
            id: 'program_credit',
            title: 'Program'
        },{
            id: 'referral_id',
            title: 'Referred By Lead (ID)'
        },{
            id: 'spouse_email_address',
            title: 'Spouse Email Address'
        },{
            id: 'spouse_first_name',
            title: 'Spouse First Name'
        },{
            id: 'spouse_last_name',
            title: 'Spouse Last Name'
        },{
            id: 'spouse_phone_number',
            title: 'Spouse Phone Number'
        },{
            id: 'administrative_area_level_1',
            title: 'State/Province'
        },{
            id: 'status',
            title: 'Status'
        },{
            id: 'street_address_1',
            title: 'Street Address'
        },{
            id: 'enrollment_user',
            title: 'Survey Credit'
        },{
            id: 'tags',
            title: 'Tags'
        },{
            id: 'postal_code',
            title: 'Zipcode'
        }].sort((a,b) => {
            return a.title.localeCompare(b.title);
        });
    }

    const getHeaderList = index => {

        // declare headers and merge in custom elements
        let headers = getHeaders().concat(customValues).sort((a,b) => a.title.localeCompare(b.title));
        let selected = selectedMap.props[index] ? headers.find(header => header.id === selectedMap.props[index].id) : null;

        return (
            <CustomListField
            containerStyle={{ 
                minWidth: 175 
            }}
            key={index}
            items={headers}
            onChange={onHeaderListItemChange.bind(this, index)} 
            onRemoveItem={onRemoveCustomLeadColumn}
            placeholder={'None'}
            removable={true}
            value={selected} />
        )
    }

    const getLeadsPreview = () => {
        return (
            <div style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
            }}>
                <div 
                className={`custom-scrollbars ${window.theme}`}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 12,
                    maxHeight: window.innerHeight - 110,
                    maxWidth: 350,
                    overflowY: 'scroll',
                    padding: 12,
                    textAlign: 'left'
                }}>
                    <LayerItem title={'How to Import Leads'}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            whiteSpace: 'normal'
                        }}>{`${leads.length === 25 ? 'The first 25 leads' : 'All the leads'} from your document are shown to the right. Click the drop down menu above each column and choose how you want the column to be used during the import process. This will let us know how to use the information in the column. Any column where no selection has been made with the drop down menu will be skipped during the import process. Before submitting your leads, check that none of the columns are repeated as each column name can only be used one time.`}</span>
                    </LayerItem>

                    <LayerItem title={'Including Dates and Times'}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            whiteSpace: 'normal'
                        }}>{`To accommodate dealerships nationally and internationally, we store dates and times using the ISO Standard Date format. For today's date and time, that format would look like this ${moment().format('YYYY-MM-DD HH:mm:ss')}. This format uses four digits for the year, two digits for the month, two digits for the day, two digits for the hour using a 24 hour scale, two digits for the minute, and two digits for the second.`}</span>
                    </LayerItem>

                    <LayerItem 
                    lastItem={true}
                    title={'Assigning Users'}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            whiteSpace: 'normal'
                        }}>{`The lead Import Wizard supports automatic assignments for the primary assignment, lead credit, marketing director, and survey credit. To make these assignments, include the user id of the user in the column you would like to assign. You can find the user id for anyone in your dealership by clickng on their user account on the Users->Accounts page.`}</span>
                    </LayerItem>
                </div>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    textAlign: 'left',
                    width: '100%'
                }}>
                    <div style={{
                        alignItems: 'center',
                        borderBottom: `1px solid ${Appearance.colors.divider()}`,
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 15
                    }}>
                        <ListField
                        disablePlaceholder={true}
                        placeholder={'Layout Presets'}
                        value={selectedMap.name}
                        onChange={item => {
                            setSelectedMap(item && maps.find(entry => entry.id === item.id));
                        }}
                        items={maps.map(entry => ({
                            id: entry.id,
                            title: entry.name
                        }))}/>
                        <div style={{
                            width: 25,
                            height: 25,
                            minWidth: 25,
                            minHeight: 25,
                            marginLeft: 8
                        }}>
                            <img
                            className={'text-button'}
                            src={'images/plus-button-blue-small.png'}
                            onClick={onNewMapClick}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                            }} />
                        </div>
                    </div>
                    <div
                    className={`custom-scrollbars ${window.theme}`}
                    style={{
                        overflow: 'scroll',
                        maxWidth: '100%',
                        maxHeight: window.innerHeight - 200
                    }}>
                        <table
                        className={'px-3 py-2 m-0'}
                        style={{
                            width: '100%'
                        }}>
                            <tbody style={{
                                width: '100%'
                            }}>
                                <tr
                                key={index}
                                style={{
                                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                                }}>
                                    {leads[0].map((_, index) => {
                                        return (
                                            <td
                                            key={index}
                                            className={'px-3 py-2'}
                                            style={{
                                                minWidth: 175
                                            }}>
                                                {getHeaderList(index)}
                                            </td>
                                        )
                                    })}
                                </tr>
                                {leads.map((fields, index) => {
                                    return (
                                        <tr
                                        key={index}
                                        style={{
                                            borderBottom: `1px solid ${Appearance.colors.divider()}`
                                        }}>
                                            {fields.map((field, index) => {
                                                return (
                                                    <td
                                                    key={index}
                                                    className={'px-3 py-2'}>
                                                        <span style={Appearance.textStyles.subTitle()}>{field}</span>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    const fetchCustomColumns = async () => {
        try {
            let { columns } = await Request.get(utils, '/dealerships/', {
                type: 'custom_lead_columns'
            });
            setCustomValues(columns.map(column => ({
                ...column,
                removable: true
            })));

        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchResources = async () => {
        try {
            let { documents, maps, paging } = await Request.get(utils, '/leads/', {
                type: 'import_resources',
                limit: limit,
                offset: offset
            });

            setLoading(false);
            setMaps(maps);
            setPaging(paging);
            setDocuments(documents.map(doc => ({ 
                ...doc, 
                date: moment(doc.date)
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of documents for your dealership. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        fetchResources();
    }, [offset]);

    useEffect(() => {
        fetchCustomColumns();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Lead Import Wizard'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: leads ? 'fullscreen' : 'medium'
        }}>
            {getContent()}
        </Layer>
    )
}

export const ImportLeadsLegacy = ({ index, options, utils }) => {

    const layerID = 'import_leads';
    const [attributionUser, setAttributionUser] = useState(null);
    const [file, setFile] = useState(null);
    const [homeownerStatusCodes, setHomeownerStatusCodes] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [leads, setLeads] = useState(null);
    const [loading, setLoading] = useState(false);
    const [maritalStatusCodes, setMaritalStatusCodes] = useState([]);
    const [occupationalStatusCodes, setOccupationalStatusCodes] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [lead, setLead] = useState(null);
    const [statusCodes, setStatusCodes] = useState([]);

    const onBackToUpload = () => {
        utils.alert.show({
            title: 'Just a Second',
            message: 'Are you sure that you want to return to the upload screen? Any unsaved changes will be lost.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Go Back',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setFile(null);
                    setLeads(null);
                    return;
                }
            }
        })
    }

    const onConfirmLeads = () => {

        let match = leads.find(entry => {
            for(var i in entry.valid) {
                if(entry.valid[i] === false) {
                    return true;
                }
            }
            return false;
        })
        if(match) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'It looks like one or more Leads may be missing some required information. Please fill out all fields that are marked in red.'
            });
            return;
        }

        let dealership = utils.dealership.get();
        let user = utils.user.get();
        utils.alert.show({
            title: 'Confirm Leads',
            message: `Uploading ${leads.length === 1 ? 'this':'these'} ${leads.length} ${leads.length === 1 ? 'lead' : 'leads'} will add them to ${dealership.name} and may take a few minutes depending on the number of Leads. Do you want to continue with adding ${leads.length === 1 ? 'this Lead':'these Leads'}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'change_dealership',
                title: 'Change Dealership',
                visible: user.level < User.levels.get().dealer,
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Upload',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'cancel') {
                    return;
                }
                if(key === 'change_dealership') {

                    const onDealershipChange = async () => {
                        try {
                            utils.events.off('selector', 'dealership_change', onDealershipChange);
                            let updatedLeads = update(leads, {
                                $apply: leads => {
                                    return leads.map(entry => {
                                        entry.lead.dealership_id = utils.dealership.get().id;
                                        return entry;
                                    });
                                }
                            });
                            setLeads(updatedLeads);
                            onSubmitLeadsToServer(updatedLeads);

                        } catch(e) {
                            console.error(e.message)
                        }
                    }

                    utils.events.on('selector', 'dealership_change', onDealershipChange);
                    utils.layer.open({
                        id: 'dealership_selector',
                        Component: DealershipSelector
                    });
                    return;
                }
                onSubmitLeadsToServer();
            }
        });
    }

    const onDownloadTemplate = async () => {
        try {
            setLoading('download');
            await Utils.sleep(0.25);

            let { url } = await Request.get(utils, '/leads/', {
                type: 'get_import_template'
            });

            setLoading(false);
            window.open(url);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue downloading the Lead template. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onFieldClick = (field, index) => {

        let props = null;
        let tmpValue = field.selection !== undefined ? field.selection : field.value;

        switch(field.key) {
            case 'address':
            props = {
                textFields: [{
                    key: field.key,
                    type: 'address_lookup',
                    placeholder: field.title,
                    value: field.value
                }]
            };
            break;

            case 'assignment_user':
            case 'enrollment_user':
            case 'lead_credit_user':
            case 'marketing_director_user':
            props = {
                content: (
                    <div style={{
                        width: '100%',
                        paddingLeft: 12,
                        paddingRight: 12,
                        marginBottom: 12
                    }}>
                        <UserLookupField
                        utils={utils}
                        user={tmpValue}
                        onChange={user => tmpValue = user}/>
                    </div>
                )
            };
            break;

            case 'lead_affiliate':
            props = {
                content: (
                    <div style={{
                        width: '100%',
                        paddingLeft: 12,
                        paddingRight: 12,
                        marginBottom: 12
                    }}>
                        <LeadLookupField
                        utils={utils}
                        value={tmpValue}
                        onChange={lead => tmpValue = lead} />
                    </div>
                )
            }
            break;

            case 'lead_type':
            props = {
                content: (
                    <div style={{
                        width: '100%',
                        paddingLeft: 12,
                        paddingRight: 12,
                        marginBottom: 12
                    }}>
                        <LeadTypePickerField
                        utils={utils}
                        value={tmpValue ? tmpValue.text : null}
                        onChange={selected => tmpValue = selected} />
                    </div>
                )
            }
            break;

            case 'lead_sub_type':
            props = {
                content: (
                    <div style={{
                        width: '100%',
                        paddingLeft: 12,
                        paddingRight: 12,
                        marginBottom: 12
                    }}>
                        <LeadSubTypePickerField
                        utils={utils}
                        value={tmpValue ? tmpValue.text : null}
                        onChange={selected => tmpValue = selected} />
                    </div>
                )
            }
            break;

            case 'out_of_service_area':
            case 'priority':
            props = {
                content: (
                    <div style={{
                        width: '100%',
                        paddingLeft: 12,
                        paddingRight: 12,
                        marginBottom: 12
                    }}>
                        <BoolToggle
                        enabled={'Yes'}
                        disabled={'No'}
                        isEnabled={tmpValue}
                        onChange={enabled => tmpValue = enabled} />
                    </div>
                )
            }
            break;

            case 'homeowner_status':
            props = {
                content: (
                    <div style={{
                        width: '100%',
                        paddingLeft: 12,
                        paddingRight: 12,
                        marginBottom: 12
                    }}>
                        <ListField
                        items={homeownerStatusCodes || []}
                        value={tmpValue ? tmpValue.text : null}
                        onChange={selected => tmpValue = selected} />
                    </div>
                )
            }
            break;

            case 'marital_status':
            props = {
                content: (
                    <div style={{
                        width: '100%',
                        paddingLeft: 12,
                        paddingRight: 12,
                        marginBottom: 12
                    }}>
                        <ListField
                        items={maritalStatusCodes || []}
                        value={tmpValue ? tmpValue.text : null}
                        onChange={selected => tmpValue = selected} />
                    </div>
                )
            }
            break;

            case 'occupational_status':
            props = {
                content: (
                    <div style={{
                        width: '100%',
                        paddingLeft: 12,
                        paddingRight: 12,
                        marginBottom: 12
                    }}>
                        <ListField
                        items={occupationalStatusCodes || []}
                        value={tmpValue ? tmpValue.text : null}
                        onChange={selected => tmpValue = selected} />
                    </div>
                )
            }
            break;

            case 'status':
            props = {
                content: (
                    <div style={{
                        width: '100%',
                        paddingLeft: 12,
                        paddingRight: 12,
                        marginBottom: 12
                    }}>
                        <ListField
                        items={statusCodes || []}
                        value={tmpValue ? tmpValue.text : null}
                        onChange={selected => tmpValue = selected} />
                    </div>
                )
            }
            break;

            case 'tags':
            props = {
                content: (
                    <div style={{
                        width: '100%',
                        paddingLeft: 12,
                        paddingRight: 12,
                        marginBottom: 12
                    }}>
                        <TagLookupField
                        utils={utils}
                        defaultTags={tmpValue}
                        onChange={tags => tmpValue = tags}/>
                    </div>
                )
            };
            break;

            default:
            props = {
                textFields: [{
                    key: field.key,
                    placeholder: field.title,
                    value: field.value
                }]
            };
        }

        utils.alert.show({
            ...props,
            title: field.title,
            message: `Please provide the ${field.alert_text || field.title.toLowerCase()} for this lead below`,
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: response => {

                // update required value if key is part of the contact information collection
                // only one piece of contact information is required per lead
                let collection = [ 'address', 'email_address', 'phone_number' ];
                let isContactInformation = collection.includes(field.key);

                // check for key based values
                // only update valid value if tmpValue was supplied
                if([ 'lead_affiliate', 'lead_type', 'lead_sub_type', 'homeowner_status', 'marital_status', 'occupational_status', 'status', 'tags' ].includes(field.key)) {
                    setLeads(leads => update(leads, {
                        [index]: {
                            ...tmpValue && {
                                valid: {
                                    [field.key]: {
                                        $set: true
                                    },
                                    ...isContactInformation && {
                                        $apply: entries => {
                                            collection.forEach(key => entries[key] = true);
                                            return entries
                                        }
                                    }
                                }
                            },
                            lead: {
                                [field.key]: {
                                    $set: tmpValue
                                }
                            }
                        }
                    }))
                    return;
                }
                // check for boolean values
                if([ 'out_of_service_area', 'priority' ].includes(field.key)) {
                    setLeads(leads => update(leads, {
                        [index]: {
                            valid: {
                                [field.key]: {
                                    $set: true
                                }
                            },
                            lead: {
                                [field.key]: {
                                    $set: tmpValue
                                }
                            }
                        }
                    }))
                    return;
                }
                // check for object based targets
                if(typeof(response) === 'object' && response[field.key]) {
                    setLeads(leads => update(leads, {
                        [index]: {
                            valid: {
                                [field.key]: {
                                    $set: true
                                },
                                ...isContactInformation && {
                                    $apply: entries => {
                                        collection.forEach(key => entries[key] = true);
                                        return entries
                                    }
                                }
                            },
                            lead: {
                                [field.key]: {
                                    $set: response[field.key]
                                }
                            }
                        }
                    }))
                }
            }
        })
    }

    const onSubmitLeadsToServer = async updatedLeads => {
        try {
            setLoading('confirm');
            await Utils.sleep(0.25);

            let dealership = utils.dealership.get();
            let target = updatedLeads || leads;
            let { count } = await Request.post(utils, '/leads/', {
                type: 'import',
                leads: target.map(entry => {
                    let code_keys = [ 'homeowner_status', 'marital_status', 'occupational_status', 'status' ];
                    let id_keys = [ 'lead_affiliate', 'lead_script', 'lead_sub_type', 'lead_type', 'program_credit' ];
                    let user_id_keys = [ 'assignment_user', 'enrollment_user', 'lead_credit_user', 'marketing_director_user' ];
                    return {
                        ...entry.lead,
                        ...entry.lead.tags && entry.lead.tags.length > 0 && {
                            tags: entry.lead.tags.map(tag => tag.id)
                        },
                        ...code_keys.reduce((object, key) => {
                            if(entry.lead[key]) {
                                object[key] = entry.lead[key].code;
                            }
                            return object;
                        }, {}),
                        ...id_keys.reduce((object, key) => {
                            if(entry.lead[key]) {
                                object[key] = entry.lead[key].id;
                            }
                            return object;
                        }, {}),
                        ...user_id_keys.reduce((object, key) => {
                            if(entry.lead[key]) {
                                object[key] = entry.lead[key].user_id;
                            }
                            return object;
                        }, {})
                    }
                })
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `We have added ${count} ${count === 1 ? 'lead' : 'leads'} to ${dealership.name}`,
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting your Leads. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUploadDocument = async props => {
        try {
            setLoading('next');
            await Utils.sleep(0.25);

            let { leads, status } = await Request.post(utils, '/leads/', {
                type: 'import',
                file: file,
                ...props
            });

            setLoading(false);
            switch(status) {
                case 'queued':
                utils.alert.show({
                    title: 'All Done!',
                    message: `Your leads have been submitted. We'll notify you when they have finished processing`
                });
                break;

                default:
                setLeads(leads);
            }

        } catch(e) {
            // check for import conflicts
            setLoading(false);
            if(e.code === 409) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: e.message || 'An unknown error occurred',
                    buttons: [{
                        key: 'confirm',
                        title: 'Continue',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Continue',
                        style: 'default'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            switch(e.category) {
                                case 'duplicates':
                                onUploadDocument({ duplicate_override: true });
                                break;

                                case 'high_volume_import':
                                onUploadDocument({ confirm_high_volume_import: true });
                                break;
                            }
                            return;
                        }
                    }
                })
                return;
            }
            // fallback to standard request error
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue reading your lead import template. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const entryHasMissingValues = entry => {
        for(var i in entry.valid) {
            if(entry.valid[i] === false) {
                return true;
            }
        }
        return false;
    }

    const getButtons = () => {
        if(leads) {
            return [{
                key: 'back',
                text: 'Back',
                color: 'dark',
                onClick: onBackToUpload
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                loading: loading === 'confirm',
                onClick: onConfirmLeads
            }]
        }
        return file && [{
            key: 'next',
            text: 'Continue',
            color: 'primary',
            loading: loading === 'next',
            onClick: () => {

                // call function manually to remove onClick evt from arguments
                onUploadDocument();
            }
        }];
    }

    const getContent = () => {
        if(leads) {
            let missingValues = getLeadMissingValuesCount();
            let color = missingValues > 0 ? Appearance.colors.grey() : Appearance.colors.primary();
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    textAlign: 'center'
                }}>
                    <span style={{
                        ...Appearance.textStyles.title(),
                        marginBottom: 4
                    }}>{'Leads Preview'}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        marginBottom: 20,
                        whiteSpace: 'normal'
                    }}>{'Listed below are all the Leads that we were able to find in your spreadsheet. Review the Leads and check that all the information looks correct before submitting. Areas that are missing required information will be highlighted in red. We will automatically attribute the creation of these Leads to you unless a user is provided in the "Lead Credit" field.'}</span>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 20,
                        width: '100%'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: 10,
                            overflow: 'hidden',
                            border: `2px solid ${color}`,
                            background: Appearance.colors.softGradient(color),
                            marginLeft: 4,
                            marginRight: 4,
                            padding: '6px 10px 6px 10px',
                            width: '100%'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                                color: 'white',
                                fontWeight: 600
                            }}>{`${leads.length} New ${leads.length === 1 ? 'lead' : 'leads'}`}</span>
                        </div>
                        {missingValues > 0 && (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                borderRadius: 10,
                                overflow: 'hidden',
                                border: `2px solid ${Appearance.colors.red}`,
                                background: Appearance.colors.softGradient(Appearance.colors.red),
                                marginLeft: 4,
                                marginRight: 4,
                                padding: '6px 10px 6px 10px',
                                width: '100%'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    color: 'white',
                                    fontWeight: 600
                                }}>{`${missingValues} ${missingValues === 1 ? 'Issue':'Issues'}`}</span>
                            </div>
                        )}
                    </div>

                    {/*<LayerItem
                    title={'Lead Credit'}
                    collapsed={false}>
                        <div style={{
                            ...Appearance.styles.unstyledPanel(),
                            padding: 12
                        }}>
                            <UserLookupField
                            utils={utils}
                            user={attributionUser}
                            icon={'search'}
                            placeholder={'Search by first or last name...'}
                            onChange={user => setAttributionUser(user)}
                            style={{
                                width: '100%'
                            }} />
                        </div>
                    </LayerItem>*/}

                    {leads.map((entry, index) => {
                        let fields = [{
                            key: 'first_name',
                            title: 'First Name',
                            value: entry.lead.first_name,
                            valid: entry.valid.first_name
                        },{
                            key: 'last_name',
                            title: 'Last Name',
                            value: entry.lead.last_name,
                            valid: entry.valid.last_name
                        },{
                            key: 'phone_number',
                            title: 'Phone Number',
                            value: entry.lead.phone_number,
                            valid: entry.valid.phone_number
                        },{
                            key: 'email_address',
                            title: 'Email Address',
                            value: entry.lead.email_address,
                            valid: entry.valid.email_address
                        },{
                            key: 'spouse_first_name',
                            title: 'Spouse First Name',
                            value: entry.lead.spouse_first_name,
                            valid: entry.valid.spouse_first_name
                        },{
                            key: 'spouse_last_name',
                            title: 'Spouse Last Name',
                            value: entry.lead.spouse_last_name,
                            valid: entry.valid.spouse_last_name
                        },{
                            key: 'spouse_phone_number',
                            title: 'Spouse Phone Number',
                            value: entry.lead.spouse_phone_number,
                            valid: entry.valid.spouse_phone_number
                        },{
                            key: 'spouse_email_address',
                            title: 'Spouse Email Address',
                            value: entry.lead.spouse_email_address,
                            valid: entry.valid.spouse_email_address
                        },{
                            key: 'address',
                            title: 'Address',
                            value: Utils.formatAddress(entry.lead.address, true),
                            valid: entry.valid.address
                        },{
                            key: 'homeowner_status',
                            title: 'Homeowner Status',
                            value: entry.lead.homeowner_status ? entry.lead.homeowner_status.text : null,
                            selection: entry.lead.homeowner_status,
                            valid: entry.valid.homeowner_status
                        },{
                            key: 'marital_status',
                            title: 'Marital Status',
                            value: entry.lead.marital_status ? entry.lead.marital_status.text : null,
                            selection: entry.lead.marital_status,
                            valid: entry.valid.marital_status
                        },{
                            key: 'occupational_status',
                            title: 'Occupational Status',
                            value: entry.lead.occupational_status ? entry.lead.occupational_status.text : null,
                            selection: entry.lead.occupational_status,
                            valid: entry.valid.occupational_status
                        },{
                            key: 'lead_type',
                            title: 'Lead Type',
                            value: entry.lead.lead_type ? entry.lead.lead_type.text : null,
                            selection: entry.lead.lead_type,
                            valid: entry.valid.lead_type
                        },{
                            key: 'lead_sub_type',
                            title: 'Lead Sub Type',
                            value: entry.lead.lead_sub_type ? entry.lead.lead_sub_type.text : null,
                            selection: entry.lead.lead_sub_type,
                            valid: entry.valid.lead_sub_type
                        },{
                            key: 'lead_script',
                            title: 'Lead Script',
                            value: entry.lead.lead_script ? entry.lead.lead_script.title : null,
                            selection: entry.lead.lead_script,
                            valid: entry.valid.lead_script
                        },{
                            key: 'lead_credit_user',
                            title: 'Lead Credit',
                            value: entry.lead.lead_credit_user ? entry.lead.lead_credit_user.full_name : null,
                            selection: entry.lead.lead_credit_user,
                            valid: entry.valid.lead_credit_user
                        },{
                            key: 'assignment_user',
                            title: 'Assigned To',
                            value: entry.lead.assignment_user ? entry.lead.assignment_user.full_name : null,
                            selection: entry.lead.assignment_user,
                            valid: entry.valid.assignment_user
                        },{
                            key: 'marketing_director_user',
                            title: 'Marketing Director',
                            value: entry.lead.marketing_director_user ? entry.lead.marketing_director_user.full_name : null,
                            selection: entry.lead.marketing_director_user,
                            valid: entry.valid.marketing_director_user
                        },{
                            key: 'enrollment_user',
                            title: 'Survey or Program Credit',
                            value: entry.lead.enrollment_user ? entry.lead.enrollment_user.full_name : null,
                            selection: entry.lead.enrollment_user,
                            valid: entry.valid.enrollment_user
                        },{
                            key: 'lead_affiliate',
                            title: 'Referred By Other Lead',
                            value: entry.lead.lead_affiliate ? entry.lead.lead_affiliate.full_name : null,
                            selection: entry.lead.lead_affiliate,
                            valid: entry.valid.lead_affiliate
                        },{
                            key: 'program_credit',
                            title: 'Received through Program',
                            value: entry.lead.program_credit ? entry.lead.program_credit.name : null,
                            selection: entry.lead.program_credit,
                            valid: entry.valid.program_credit
                        },{
                            key: 'out_of_service_area',
                            title: 'Out of Service Area',
                            value: entry.lead.out_of_service_area ? 'Yes' : 'No',
                            selection: entry.lead.out_of_service_area,
                            valid: entry.valid.out_of_service_area
                        },{
                            key: 'priority',
                            title: 'Priority',
                            value: entry.lead.priority ? 'Yes' : 'No',
                            selection: entry.lead.priority,
                            valid: entry.valid.priority
                        },{
                            key: 'status',
                            title: 'Status',
                            value: entry.lead.status ? entry.lead.status.text : null,
                            selection: entry.lead.status,
                            valid: entry.valid.status
                        },{
                            key: 'tags',
                            title: 'Tags',
                            value: entry.lead.tags ? Utils.oxfordImplode(entry.lead.tags.map(tag => tag.text)) : 'Not Added',
                            selection: entry.lead.tags,
                            valid: entry.valid.tags
                        },{
                            key: 'notes',
                            title: 'Notes',
                            value: entry.lead.notes || 'No notes added',
                            valid: entry.valid.notes
                        }];

                        let hasMissingValues = entryHasMissingValues(entry);
                        return (
                            <div
                            key={index}
                            style={{
                                width: '100%'
                            }}>
                                <LayerItem
                                title={`Lead for ${entry.lead.first_name} ${entry.lead.last_name || ''}`}
                                collapsed={false}
                                badge={entry.duplicate && {
                                    text: 'Duplicate',
                                    color: Appearance.colors.red
                                }}
                                headerStyle={{
                                    ...hasMissingValues && ({
                                        color: Appearance.colors.red
                                    })
                                }}>
                                    <div style={{
                                        ...Appearance.styles.unstyledPanel(),
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        overflow: 'hidden',
                                        ...hasMissingValues && {
                                            borderWidth: 2,
                                            borderColor: Appearance.colors.red,
                                        }
                                    }}>
                                        {fields.map((field, i) => {
                                            return (
                                                <div
                                                key={i}
                                                className={`view-entry ${window.theme}`}
                                                onClick={onFieldClick.bind(this, field, index)}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                    padding: '6px 10px 6px 10px',
                                                    borderBottom: i !== fields.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                                                }}>
                                                    <span style={{
                                                        ...Appearance.textStyles.title(),
                                                        ...field.valid === false && {
                                                            color: Appearance.colors.red
                                                        }
                                                    }}>{field.title}</span>
                                                    <span style={{
                                                        ...Appearance.textStyles.subTitle()
                                                    }}>{field.value}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </LayerItem>
                            </div>
                        )
                    })}
                </div>
            )
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 15,
                    whiteSpace: 'normal'
                }}>{'Please choose a document to upload that contains the leads from your past CRM. The document needs to be a spreadsheet that follows our spreadsheet template. You can download the spreadsheet template by clicking the "Download Template" button below'}</span>
                <FilePickerField
                utils={utils}
                fileTypes={[ 'xls', 'xlsx' ]}
                onChange={file => setFile(file)} />
            </div>
        )
    }

    const getLeadMissingValuesCount = () => {
        let matches = leads.filter(entry => {
            for(var i in entry.valid) {
                if(entry.valid[i] === false) {
                    return true;
                }
            }
            return false;
        })
        return matches.length;
    }

    const setupTarget = async () => {
        try {
            let { homeowner_status_codes, marital_status_codes, occupational_status_codes, status_codes } = await Request.get(utils, '/leads/', {
                type: 'edit_targets'
            });

            setHomeownerStatusCodes(homeowner_status_codes);
            setMaritalStatusCodes(marital_status_codes);
            setOccupationalStatusCodes(occupational_status_codes);
            setStatusCodes(status_codes);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the Lead import process. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        utils={utils}
        title={'Import Leads'}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            {getContent()}
        </Layer>
    )
}

export const LeadAffiliates = ({ abstract, index, options, utils }) => {

    const layerID = `lead_affiliates${abstract.getID()}`;
    const [loading, setLoading] = useState(true);
    const [layerState, setLayerState] = useState(null);
    const [leads, setLeads] = useState([]);

    const onLeadClick = lead => {
        utils.layer.open({
            abstract: Abstract.create({
                object: lead,
                type: 'lead'
            }),
            Component: LeadDetails,
            id: `lead_details_${lead.id}`,
            permissions: ['leads.details']
        });
    }

    const onAddNewLead = () => {

        let lead = Lead.new();
        lead.affiliate = abstract.object;
        lead.user = utils.user.get();

        utils.layer.open({
            id: 'new_lead',
            abstract: Abstract.create({
                type: 'lead',
                object: lead
            }),
            Component: AddEditLead.bind(this, {
                isNewTarget: true,
                onAddLead: nextLead => {
                    setLeads(leads => update(leads, {
                        $unshift: [nextLead]
                    }))
                }
            })
        });
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'new',
            onClick: onAddNewLead,
            text: 'Add a Lead'
        }];
    }

    const fetchLeads = async () => {
        try {
            let { leads } = await Request.get(utils, '/leads/', {
                type: 'leads',
                id: abstract.getID()
            })
            setLoading(false);
            setLeads(leads.map(lead => Lead.create(lead)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the leads for this lead. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        fetchLeads();
        utils.content.subscribe(layerID, ['lead'], {
            onFetch: fetchLeads,
            onUpdate: fetchLeads
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Leads from ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {leads.length > 0
                ?
                <div style={{
                    borderRadius: 10,
                    border: `1px solid ${Appearance.colors.divider()}`
                }}>
                    {leads.map((lead, index) => {

                        let badge = getLeadStatus(utils, lead);
                        return (
                            Views.entry({
                                key: index,
                                title: lead.full_name,
                                subTitle: `Added ${moment(lead.added).format('MMMM Do, YYYY [at] h:mma')}`,
                                badge: badge ? {
                                    text: badge.title,
                                    color: badge.color
                                } : null,
                                hideIcon: true,
                                singleItem: leads.length === 1,
                                firstItem: index === 0,
                                lastItem: index === leads.length - 1,
                                bottomBorder: true,
                                onClick: onLeadClick.bind(this, lead)
                            })
                        )
                    })}
                </div>
                :
                loading
                    ?
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: 75
                    }}>
                        <LottieView
                        loop={true}
                        autoPlay={true}
                        source={window.theme === 'dark' ? 'files/lottie/dots-white.json' : 'files/lottie/dots-grey.json'}
                        style={{
                            width: 50,
                            height: 50
                        }}/>
                    </div>
                    :
                    <div style={{
                        borderRadius: 10,
                        border: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        {Views.entry({
                            bottomBorder: false,
                            hideIcon: true,
                            subTitle: 'No leads were found in the system',
                            title: 'No Leads Found'
                        })}
                    </div>
            }
        </Layer>
    )
}

export const LeadCallLogs = ({ abstract, index, options, utils }) => {

    const layerID = `lead_call_logs_${abstract.getID()}`;
    const [loading, setLoading] = useState(true);
    const [layerState, setLayerState] = useState(null);
    const [lead, setLead] = useState(abstract.object);
    const [callLogs, setCallLogs] = useState([]);

    const getStatus = () => {
        if(abstract.object.status !== Lead.status.get().do_not_call) {
            return null;
        }
        return (
            <div style={{
                ...Appearance.styles.panel(),
                marginBottom: 12
            }}>
                {Views.entry({
                    title: 'Do Not Call',
                    subTitle: 'This lead has been marked as "Do Not Call"',
                    icon: {
                        path: 'images/do-not-call-red.png'
                    },
                    bottomBorder: false
                })}
            </div>
        )
    }

    const onNewCallLog = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: CallLog.new(),
                type: 'call_log',
            }),
            Component: AddEditCallLog.bind(this, {
                isNewTarget: true,
                lead: abstract.object
            }),
            id: `new_call_log_${abstract.getID()}`,
            permissions: ['calls.actions.edit']
        })
    }

    const fetchCallLogs = async () => {
        try {
            let { call_logs } = await Request.get(utils, '/leads/', {
                type: 'call_logs',
                id: abstract.getID()
            })
            setLoading(false);
            setCallLogs(call_logs.map(log => CallLog.create(log)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the call logs for this lead. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            })
        }
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'do_not_call',
                permissions: abstract.object.status === Lead.status.get().do_not_call ? ['leads.actions.do_not_call.delete'] : ['leads.actions.do_not_call.new'],
                title: abstract.object.status === Lead.status.get().do_not_call ? 'Remove Do Not Call Tag' : 'Mark as Do Not Call',
                style: abstract.object.status === Lead.status.get().do_not_call ? 'default' : 'destructive'
            }],
            target: evt.target
        }, key => {
            if(key === 'do_not_call') {
                onSetDoNotCall();
                return;
            }
        })
    }

    const onSetDoNotCall = () => {
        utils.alert.show({
            title: abstract.object.status === Lead.status.get().do_not_call ? 'Remove Do Not Call Tag' : 'Mark as Do Not Call',
            message: `Are you sure that you want to ${abstract.object.status === Lead.status.get().do_not_call ? 'remove the "Do Not Call" tag from this Lead' : 'mark this Lead as "Do Not Call"'}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: abstract.object.status === Lead.status.get().do_not_call ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: abstract.object.status === Lead.status.get().do_not_call ? 'cancel' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetDoNotCallConfirm();
                    return;
                }
            }
        })
    }

    const onSetDoNotCallConfirm = async () => {
        try {

            setLoading(true);
            await Utils.sleep(0.25);
            let { status } = await Request.post(utils, '/leads/', {
                id: abstract.getID(),
                status: abstract.object.status === Lead.status.get().do_not_call ? Lead.status.get().new : Lead.status.get().do_not_call,
                type: 'set_status'
            });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This lead has been ${abstract.object.status === Lead.status.get().do_not_call ? 'un-tagged as "Do Not Call"' : 'has been tagged as "Do Not Call"'}`
            });

            // update status object, remove sale date, and notify subscribers of data change
            abstract.object.sale_date = null;
            abstract.object.status = status;
            utils.content.update(abstract);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this lead ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onCallLogClick = log => {
        utils.sheet.show({
            items: [{
                key: 'view',
                title: 'View',
                style: 'default'
            },{
                key: 'edit',
                title: 'Edit',
                style: 'default'
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }]
        }, key => {

            if(key === 'view') {
                utils.layer.open({
                    abstract: Abstract.create({
                        object: log,
                        type: 'call_log'
                    }),
                    Component: CallLogDetails,
                    id: `call_log_details_${log.id}`,
                    permissions: ['calls.details']
                });
                return;
            }

            if(key === 'edit') {
                utils.layer.open({
                    abstract: Abstract.create({
                        object: log,
                        type: 'call_log'
                    }),
                    Component: AddEditCallLog.bind(this, {
                        canSetStatus: false,
                        isNewTarget: false,
                        lead: abstract.object
                    }),
                    id: `edit_call_log_${abstract.getID()}`,
                    permissions: ['calls.actions.edit']
                })
                return;
            }

            if(key === 'delete') {
                onDeleteCallLog(log);
                return;
            }
        })
    }

    const onDeleteCallLog = log => {
        utils.alert.show({
            title: 'Delete Call',
            message: 'Are you sure that you want to delete this call? This action can not be undone',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteCallLogConfirm(log);
                }
            }
        })
    }

    const onDeleteCallLogConfirm = async log => {
        try {

            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/demos/', {
                type: 'delete_call_log',
                id: log.id
            });

            setLoading(false);
            utils.content.fetch('call_log');

            utils.alert.show({
                title: 'All Done!',
                message: 'This call log has been deleted',
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this call log. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'new',
            onClick: onNewCallLog,
            text: 'New Call'
        }];
    }

    useEffect(() => {

        fetchCallLogs();
        utils.content.subscribe(layerID, ['call_log', 'lead', 'lead_status'], {
            onFetch: fetchCallLogs,
            onUpdate: abstract => {
                switch(abstract.type) {
                    case 'call_log':
                    setCallLogs(callLogs => {
                        return callLogs.map(callLog => {
                            return callLog.id === abstract.getID() ? abstract.object : callLog
                        })
                    })
                    break;

                    case 'lead':
                    setLead(lead => {
                        return lead.id === abstract.getID() ? abstract.object : lead
                    });
                    break;

                    case 'lead_status':
                    setLead(lead => {
                        if(lead.id === abstract.getID()) {
                            lead.status = abstract.object.status;
                        }
                        return lead;
                    });
                    break;
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Call and Emails for Lead #${abstract.getID()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {callLogs.length > 0
                ?
                <div>
                    {getStatus()}
                    <div style={{
                        borderRadius: 10,
                        border: `1px solid ${Appearance.colors.divider()}`
                    }}>
                    {callLogs.map((log, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: log.author ? log.author.full_name : 'Name not available',
                                subTitle: moment(log.start_date).format('MMMM Do, YYYY [at] h:mma'),
                                badge: [{
                                    text: log.direction,
                                    color: log.direction === 'outbound' ? Appearance.colors.grey() : Appearance.colors.primary()
                                },{
                                    text: log.method,
                                    color: Appearance.colors.secondary()
                                }],
                                hideIcon: true,
                                singleItem: callLogs.length === 1,
                                firstItem: index === 0,
                                lastItem: index === callLogs.length - 1,
                                bottomBorder: true,
                                onClick: onCallLogClick.bind(this, log)
                            })
                        )
                    })}
                    </div>
                </div>
                :
                loading
                    ?
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: 75
                    }}>
                        <LottieView
                        loop={true}
                        autoPlay={true}
                        source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                        style={{
                            width: 50,
                            height: 50
                        }}/>
                    </div>
                    :
                    <div style={{
                        borderRadius: 10,
                        border: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        {Views.entry({
                            title: 'No Call Logs Found',
                            subTitle: 'No call logs were found in the system',
                            bottomBorder: false,
                            hideIcon: true
                        })}
                    </div>
            }
        </Layer>
    )
}

export const LeadDetails = ({ abstract, index, options, utils }) => {

    const layerID = `lead_details_${abstract.getID()}`;
    const eventsLimit = 5;

    const [customerResponse, setCustomerResponse] = useState(null);
    const [dealership, setDealership] = useState(abstract.object.dealership);
    const [demoDetails, setDemoDetails] = useState(null);
    const [events, setEvents] = useState([]);
    const [eventsOffset, setEventsOffset] = useState(0);
    const [eventsPaging, setEventsPaging] = useState(null);
    const [hideSMS, setHideSMS] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [lead, setLead] = useState(abstract.object);
    const [tags, setTags] = useState(null);
    const [unstructuredContent, setUnstructuredContent] = useState([]);

    const onArchiveClick = () => {
        utils.alert.show({
            title: `Archive List`,
            message: `This lead was added to your Archive list. This means that this Lead will not show up on any Dealership lead lists or reports.`,
            buttons: [{
                key: 'unarchive',
                title: 'Unarchive',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: async key => {
                if(key === 'unarchive') {
                    try {
                        await Utils.sleep(0.5);
                        onSetArchiveStatus();
                    } catch(e) {
                        console.error(e.message);
                    }
                    return;
                }
            }
        })
    }

    const onBookDemoClick = () => {
        try {

            // prevent booking for archived
            if(abstract.object.active === false) {
                throw new Error('This lead is currently on the Archived list and is not available for a Demo')
            }

            // prevent booking for do not call
            if(abstract.object.status.code === Lead.status.get().do_not_call) {
                throw new Error('This lead is currently on the Do Not Call list and is not available for a Demo')
            }

            // prevent booking for leads that are being transfered
            if(abstract.object.transfer && abstract.object.transfer.status.code === Lead.Transfer.status.in_progress) {
                throw new Error('This lead is currently part of a Lead Transfer and is not available for a Demo')
            }

            // present demo booking options
            utils.layer.open({
                id: `book_demo_${abstract.getID()}`,
                abstract: abstract,
                Component: BookDemoFromLead.bind(this, {})
            })

        } catch(e) {
            utils.alert.show({
                title: 'Just a Second',
                message: e.message || 'An unknown error occurred'
            })
        }
    }

    const onDeleteClick = () => {
        utils.alert.show({
            title: 'Delete Lead',
            message: 'Are you sure that you want to delete this lead from your dealership? This will also remove any demos, demo requests, and call logs where this lead is attached. This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteConfirm();
                    return;
                }
            }
        });
    }

    const onDeleteConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(0.25);

            let { call_logs, demos, demo_requests } = await Request.post(utils, '/leads/', {
                id: abstract.getID(),
                type: 'delete'
            });

            setLoading(false);
            setTimeout(setLayerState.bind(this, 'close'), 250);

            // show confirmation alert to user where only the lead was delete if applicable
            if(call_logs === 0 && demos === 0 && demo_requests === 0) {
                utils.alert.show({
                    title: 'All Done!',
                    message: 'This lead has been deleted from your dealership',
                    onClick: utils.content.fetch.bind(this, 'lead')
                });
                return;
            }

            // prepare array of targets representing the quantity removed
            let targets = [{
                title: 'call log',
                value: call_logs
            },{
                title: 'demo',
                value: demos
            },{
                title: 'demo request',
                value: demo_requests
            }];

            // prepare an array of messages representing the targets removed
            let messages = targets.reduce((array, target) => {
                if(target.value > 0) {
                    array.push(`${target.value} ${target.value === 1 ? target.title : `${target.title}s`}`);
                }
                return array;
            }, []);
            
            // show confirmation alert to user with removal totals
            utils.alert.show({
                title: 'All Done!',
                message: `This lead has been deleted from your dealership along with ${Utils.oxfordImplode(messages)}`,
                onClick: utils.content.fetch.bind(this, 'lead')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDemoClick = async () => {
        try {
            let demo = await Demo.get(utils, demoDetails.id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: demo,
                    type: 'demo'
                }),
                Component: DemoDetails,
                id: `demo_details_${demoDetails.id}`,
                permissions: ['demos.details']
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this demo. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDoNotCall = () => {
        utils.alert.show({
            title: `${abstract.object.status.code === Lead.status.get().do_not_call ? 'Remove from' : 'Add to'} Do Not Call List`,
            message: `Are you sure that you want to ${abstract.object.status.code === Lead.status.get().do_not_call ? 'remove this Lead from' : 'add this Lead to'} the Do Not Call List?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDoNotCallConfrim();
                    return;
                }
            }
        });
    }

    const onDoNotCallClick = () => {
        utils.alert.show({
            title: `Do Not Call List`,
            message: `This lead was added to your Do Not Call list. This means that this Lead will not show up on any Dealership lead lists or reports and should not be contacted.`,
            buttons: [{
                key: 'remove',
                title: 'Remove from Do Not Call',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: async key => {
                if(key === 'remove') {
                    try {
                        await Utils.sleep(0.5);
                        onDoNotCall();
                    } catch(e) {
                        console.error(e.message);
                    }
                    return;
                }
            }
        });
    }

    const onDoNotCallConfrim = async () => {
        try {
            setLoading('options');
            await Utils.sleep(0.25);

            // prepare next status and submit request to server
            let next_status = abstract.object.status.code === Lead.status.get().do_not_call ? Lead.status.get().new : Lead.status.get().do_not_call;
            let { status } = await Request.post(utils, '/leads/', {
                id: abstract.getID(),
                status: next_status,
                type: 'set_status'
            });

            // end loading, update sale date and status object, and update local state
            setLoading(false);
            abstract.object.sale_date = null;
            abstract.object.status = status;
            setLead(abstract.object);

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `This lead has been ${status.code === Lead.status.get().do_not_call ? 'added to' : 'removed from'} the Do Not Call List`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding thins Lead to the Do Not Call List. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDisqualify = () => {
        utils.alert.show({
            title: 'Disqualify Lead',
            message: 'Are you sure that you want to disqualify this Lead?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Disqualify',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDisqualifyConfirm();
                    return;
                }
            }
        });
    }

    const onDisqualifyConfirm = async () => {
        try {
            setLoading('options');
            await Utils.sleep(0.25);

            // submit request to server
            let { status } = await Request.post(utils, '/leads/', {
                id: abstract.getID(),
                status: Lead.status.get().unqualified,
                type: 'set_status'
            })

            // end loading, update status and sale date, and notify subscribers of data change
            setLoading(false);
            abstract.object.sale_date = null;
            abstract.object.status = status;
            utils.content.update(abstract);

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: 'This lead has been disqualified'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this lead ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_lead_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditLead.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onLeadAffiliateClick = async () => {
        try {

            // set loading flag and fetch lead details
            setLoading('affiliate');
            let lead = await Lead.get(utils, abstract.object.affiliate.id);

            // end loading and show lead details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead'
                }),
                Component: LeadDetails,
                id: `lead_details_${lead.id}`,
                permissions: ['leads.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onLeadScriptClick = async () => {
        try {

            let script = await Lead.Script.get(utils, lead.lead_script.id);
            utils.layer.open({
                id: `lead_script_editor_${lead.id}`,
                Component: LeadScriptEditor.bind(this, {
                    lead: lead,
                    utils: utils,
                    value: script.text
                })
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead script. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onLeadTransferClick = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            let { lead_transfer } = await Request.get(utils, '/leads/', {
                type: 'lead_transfer_details',
                transfer_id: lead.transfer.id
            });

            setLoading(false);
            utils.layer.open({
                id: `lead_transfer_details_${lead.transfer.id}`,
                abstract: Abstract.create({
                    type: 'lead_transfer',
                    object: Lead.Transfer.create(lead_transfer)
                }),
                Component: LeadTransferDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead transfer. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onNewCallLog = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: CallLog.new(),
                type: 'call_log'
            }),
            Component: AddEditCallLog.bind(this, {
                isNewTarget: true,
                lead: abstract.object
            }),
            id: `new_call_log_${abstract.getID()}`,
            permissions: ['calls.actions.new']
        })
    }

    const onNewSystemEvent = data => {
        try {
            setEvents(events => {
                return update(events, {
                    $unshift: [SystemEvent.create(data)]
                });
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const onOptionsClick = evt => {

        // only allow lead transfering for administrators and the dealer of the current dealership
        let dealership = utils.dealership.get();
        let canTransferLeads = utils.user.get().level === User.levels.get().admin || (dealership.dealer && utils.user.get().user_id === dealership.dealer.user_id) ? true : false;

        utils.sheet.show({
            items: [{
                key: 'call_logs',
                permissions: ['calls.details'],
                title: 'Call Logs',
                style: 'default'
            },{
                key: 'disqualify',
                permissions: ['leads.actions.disqualify'],
                title: 'Disqualify',
                style: 'destructive'
            },{
                key: 'do_not_call',
                permissions: abstract.object.status.code === Lead.status.get().do_not_call ? ['leads.actions.do_not_call.delete'] : ['leads.actions.do_not_call.new'],
                title: abstract.object.status.code === Lead.status.get().do_not_call ? 'Remove from Do Not Call' : 'Add to Do Not Call',
                style: 'destructive'
            },{
                key: 'delete',
                permissions: ['leads.actions.delete'],
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'edit',
                permissions: ['leads.actions.edit'],
                title: 'Edit',
                style: 'default'
            },{
                key: 'release',
                permissions: ['leads.actions.release'],
                title: 'Release',
                visible: abstract.object.released === false,
                style: 'default'
            },{
                key: 'set_archive',
                permissions: ['leads.actions.archive'],
                title: lead.active ? 'Archive' : 'Unarchive',
                style: lead.active ? 'destructive' : 'default'
            },{
                key: 'status',
                permissions: ['leads.actions.status'],
                title: 'Set Status',
                style: 'default'
            },{
                key: 'transfer',
                permissions: ['leads.actions.transfer'],
                title: 'Transfer to Other Dealership',
                style: 'default',
                visible: canTransferLeads
            },{
                key: 'text_message',
                permissions: ['leads.actions.sms'],
                title: 'Send Text Message',
                style: 'default'
            },{
                key: 'unrelease',
                permissions: ['leads.actions.unrelease'],
                title: 'Unrelease',
                visible: abstract.object.released === true,
                style: 'destructive'
            }],
            target: evt.target
        }, key => {
            if(key === 'set_archive') {
                onSetArchiveStatus();
                return;
            }
            if(key === 'delete') {
                onDeleteClick();
                return;
            }
            if(key === 'do_not_call') {
                onDoNotCall();
                return;
            }
            if(key === 'edit') {
                onEditClick();
                return;
            }
            if(key === 'call_logs') {
                utils.layer.open({
                    id: `lead_call_logs_${abstract.getID()}`,
                    abstract: abstract,
                    Component: LeadCallLogs
                });
                return;
            }
            if(key === 'programs') {
                utils.layer.open({
                    id: `lead_programs_${abstract.getID()}`,
                    abstract: abstract,
                    Component: LeadPrograms
                });
                return;
            }
            if(key === 'disqualify') {
                onDisqualify();
                return;
            }
            if(key === 'status') {
                utils.layer.open({
                    id: `set_lead_status_${abstract.getID()}`,
                    abstract: abstract,
                    Component: SetLeadStatus
                });
                return;
            }
            if(key === 'transfer') {
                onTransferLeads();
                return;
            }
            if(key === 'text_message') {
                onSendTextMesssage();
                return;
            }
            if(key === 'release' || key === 'unrelease') {
                onSetRelease(key === 'release');
                return;
            }
        })
    }

    const onOutOfServiceAreaClick = () => {
        utils.alert.show({
            title: `Out of Service Area`,
            message: `This lead was added to your Out of Service Area list. You can change this by editing the lead and making a new selection for the area labeled "Out of Service Area".`,
            buttons: [{
                key: 'edit',
                title: 'Edit Lead',
                style: 'default',
                visible: lead.dealership_id === utils.dealership.get().id
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'edit') {
                    onEditClick();
                    return;
                }
            }
        });
    }

    const onPriorityClick = () => {
        utils.alert.show({
            title: `Priority List`,
            message: `This lead was added to your Priority list. Leads marked as a priority will have a blue dot next to them when shown with other leads. You can change this by editing the lead and making a new selection for the area labeled "Priority".`,
            buttons: [{
                key: 'edit',
                title: 'Edit Lead',
                style: 'default',
                visible: lead.dealership_id === utils.dealership.get().id
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'edit') {
                    onEditClick();
                    return;
                }
            }
        });
    }

    const onProgramClick = async id => {
        try {

            // set loading flag and fetch program details
            setLoading(true);
            let program = await Program.get(utils, id);

            // end loading and show program details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: program,
                    type: 'program'
                }),
                Component: ProgramDetails,
                id: `program_details_${id}`,
                permissions: ['programs.details']
            });
        
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retreiving the details for this program. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetRelease = release => {
        utils.alert.show({
            title: `${release ? 'Release' : 'Unrelease'}`,
            message: `Are you sure that you want to ${release ? 'release' : 'unrelease'} this Lead? ${release ? 'This will open this lead up to the rest of the dealership.' : 'This will prevent others in the dealership from viewing this lead.'}`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: release ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: `Do Not ${release ? 'Release': 'Unrelease'}`,
                style: release ? 'destructive' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetReleaseConfirm(release);
                    return;
                }
            }
        })
    }

    const onSetReleaseConfirm = async release => {
        try {
            setLoading('options');
            await Utils.sleep(0.25);
            await Request.post(utils, '/leads/', {
                type: 'set_release',
                id: abstract.getID(),
                release: release
            });

            setLoading(false);
            abstract.object.released = release;
            utils.content.update(abstract);

            setLead(abstract.object);
            utils.alert.show({
                title: 'All Done!',
                message: `This lead has been ${release ? 'released and is avaiable for others to view.' : 'unreleased and is no longer available for others to view.'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the release status for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSendTextMesssage = async () => {
        try {
            utils.layer.open({
                id: 'lead_text_message_manager',
                Component: LeadTextMessageManager.bind(this, {
                    leads: [ abstract.object ]
                })
            });
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the text message process. ${e.message || 'An unknown error occured'}`
            })
        }
    }

    const onSetArchiveStatus = () => {
        utils.alert.show({
            title: `${lead.active ? 'Archive' : 'Unarchive'} Lead`,
            message: `Are you sure that you want to ${lead.active ? 'archive' : 'unarchive'} this Lead? ${lead.active ? 'This lead will no longer show up within your pool of leads' : 'This lead will be added back to your pool of leads'}.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: lead.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: lead.active ? 'default' : 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetArchiveStatusConfrim();
                    return;
                }
            }
        })
    }

    const onSetArchiveStatusConfrim = async () => {
        try {

            setLoading('options');
            await Utils.sleep(0.25);

            let status = !abstract.object.active;
            await Request.post(utils, '/leads/', {
                type: 'set_archive_status',
                id: abstract.getID(),
                active: status
            });

            abstract.object.active = status;
            utils.content.update(abstract);

            setLoading(false);
            setLead(abstract.object);

            utils.content.fetch('lead')
            utils.alert.show({
                title: 'All Done!',
                message: `This lead has been ${status ? 'unarchived' : 'archived'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the archive status for this lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system_event_details_${evt.id}`,
            abstract: Abstract.create({
                type: 'system_event',
                object: evt
            }),
            Component: SystemEventDetails
        })
    }

    const onTransferLeads = () => {

        let dealership = null;
        utils.alert.show({
            title: 'Transfer Lead',
            message: `You may transfer this lead to another dealership if you feel that you no longer need it. We'll send a request to the other dealership that they can accept or decline. If accepted, the lead will be removed from your dealership and added to the recipient's dealership. If declined, the lead will be returned back to your dealership.`,
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <DealershipLookupField
                    utils={utils}
                    icon={'search'}
                    inline={true}
                    globalVisibility={true}
                    onChange={result => dealership = result}
                    containerStyle={{
                        width: '100%'
                    }}/>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Send Lead',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Send Lead',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm' && dealership) {
                    onTransferLeadsConfirm(dealership);
                    return;
                }
            }
        })
    }

    const onTransferLeadsConfirm = async dealership => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            let { transfer_data } = await Request.post(utils, '/leads/', {
                type: 'new_transfer',
                destination_dealership_id: dealership.id,
                ids: [ abstract.getID() ]
            });

            setLoading(false);
            abstract.object.transfer = transfer_data;
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: `We have sent this Lead to the ${dealership.name} Dealership. We'll notify your Dealership when they accept or decline this transfer.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue transfering your Lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getBottomFields = () => {

        if(!lead) {
            return null;
        }

        let items = [{
            key: 'users',
            permissions: ['leads.details.users'],
            title: 'Assignments and Credit',
            items: [{
                key: 'assignment_user',
                title: 'Assigned To',
                value: lead.assignment_user ? lead.assignment_user.full_name : null
            },{
                key: 'user',
                title: 'Lead Credit',
                selected: lead.user,
                value: lead.user ? lead.user.full_name : null
            },{
                key: 'marketing_director_user',
                title: 'Marketing Director',
                value: lead.marketing_director_user ? lead.marketing_director_user.full_name : null
            },{
                key: 'enrollment_user',
                title: 'Survey or Program Credit',
                selected: lead.enrollment_user,
                value: lead.enrollment_user ? lead.enrollment_user.full_name : null
            }]
        },{
            key: 'details',
            permissions: ['leads.details.ext'],
            title: 'Additional Details',
            items: [{
                key: 'archived',
                title: 'Archived',
                value: lead.active ? 'No' : 'Yes'
            },{
                key: 'notes',
                title: 'Comments',
                value: lead.notes
            },{
                key: 'created',
                title: 'Created',
                value: lead.created ? Utils.formatDate(lead.created) : null
            },{
                key: 'dealership',
                visible: utils.user.get().level <= User.levels.get().admin,
                title: 'Dealership',
                value: dealership ? dealership.name : null
            },{
                key: 'out_of_service_area',
                title: 'Out of Service Area',
                value: lead.out_of_service_area ? 'Yes' : 'No',
            },{
                key: 'priority',
                title: 'Priority',
                value: lead.priority ? 'Yes' : 'No'
            },{
                key: 'program_credit',
                onClick: lead.program_credit ? onProgramClick.bind(this, lead.program_credit.id) : null,
                title: 'Received through Program',
                value: lead.program_credit && lead.program_credit.name || 'No'
            },{
                key: 'affiliate',
                loading: loading === 'affiliate',
                onClick: lead.affiliate ? onLeadAffiliateClick : null,
                title: 'Referred By Other Lead',
                value: lead.affiliate ? lead.affiliate.full_name : 'No'
            },{
                key: 'status',
                title: 'Sale Date',
                value: lead.sale_date && Utils.formatDate(lead.sale_date),
                visible: lead.sale_date ? true : false
            },{
                color: lead.status && lead.status.color,
                key: 'status',
                title: 'Status',
                value: lead.status ? lead.status.text : null
            },{
                key: 'tags',
                title: 'Tags',
                value: tags ? Utils.oxfordImplode(tags.map(tag => tag.text)) : null
            }]
        }];

        // prevent moving forward if no valid items were provided
        let count = formatFields(utils, items).length;
        if(count === 0) {
            return null;
        }

        return (
            <FieldMapper
            fields={items}
            group={User.Group.categories.leads}
            utils={utils}/>
        )
    }

    const getButtons = () => {

        // determine if an inbound lead transfer is active
        let dealershipID = utils.dealership.get().id;
        if(lead.transfer && lead.transfer.status.code === Lead.Transfer.status.in_progress && lead.transfer.to_dealership === dealershipID) {
            return [{
                key: 'lead_transfer',
                text: 'Lead Transfer Options',
                color: 'secondary',
                loading: loading === 'options',
                onClick: onLeadTransferClick
            }];
        }

        // no buttons are required if the dealerships do no match
        if(lead.dealership_id !== dealershipID) {
            return null;
        }

        // return default buttons
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'dark',
            key: 'call',
            loading: loading === 'call',
            onClick: onNewCallLog,
            permissions: ['calls.actions.new'],
            text: 'Add Call'
        },{
            color: 'primary',
            key: 'book_demo',
            loading: loading === 'book_demo',
            onClick: onBookDemoClick,
            permissions: ['leads.actions.set_demo'],
            text: 'Set Demo'
        }];
    }

    const getHeaderBanner = () => {

        // generate list of available banners
        let dealershipID = utils.dealership.get().id;
        let fields = [{
            key: 'archived',
            title: 'Archived',
            value: lead.active !== true,
            color: Appearance.colors.red,
            message: 'This lead has been added to the archived list',
            onClick: onArchiveClick
        },{
            key: 'do_not_call',
            title: 'Do Not Call',
            value: lead.status.code === Lead.status.get().do_not_call,
            color: Appearance.colors.darkRed,
            message: 'This lead has been added to the do not call list',
            onClick: onDoNotCallClick
        },{
            key: 'demo_details',
            title: demoDetails && `Demo Scheduled`,
            value: demoDetails ? true : false,
            color: demoDetails && demoDetails.status.color,
            message: demoDetails && `Demo #${demoDetails.id} is scheduled for ${Utils.formatDate(demoDetails.start_date)}.`,
            onClick: onDemoClick,
            rightContent: demoDetails && (
                <AltBadge 
                content={demoDetails.status} 
                invert={true} />
            )
        },{
            key: 'lead_transfer_inbound',
            title: 'Inbound Lead Transfer',
            value: lead.transfer && lead.transfer.status.code === Lead.Transfer.status.in_progress && lead.transfer.from_dealership !== dealershipID ? true : false,
            color: Appearance.colors.darkGrey,
            message: 'This lead has been transfered to your dealership from another dealership',
            onClick: onLeadTransferClick
        },{
            key: 'lead_transfer_outbound',
            title: 'Outbound Lead Transfer',
            value: lead.transfer && lead.transfer.status.code === Lead.Transfer.status.in_progress && lead.transfer.from_dealership === dealershipID ? true : false,
            color: Appearance.colors.darkGrey,
            message: 'This lead has a pending outbound transfer to another dealership',
            onClick: onLeadTransferClick
        },{
            key: 'out_of_service_area',
            title: 'Out of Service Area',
            value: lead.out_of_service_area === true && lead.dealership_id === utils.dealership.get().id,
            color: Appearance.colors.lightGrey,
            message: `This lead has been marked as out of your dealership's service area`,
            onClick: onOutOfServiceAreaClick
        },{
            key: 'priority',
            title: 'Priority',
            value: lead.priority === true,
            color: Appearance.colors.primary(),
            message: 'This lead has been marked as a dealership priority',
            onClick: onPriorityClick
        },{
            key: 'released',
            title: 'Unreleased',
            value: lead.released === false,
            color: Appearance.colors.lightGrey,
            message: 'This lead has not been released to the rest of the dealership',
            onClick: onSetRelease
        }];

        // prevent moving forward if no banners are available to show
        if(!fields.find(field => field.value === true)) {
            return null;
        }

        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 12,
                width: '100%'
            }}>
                {fields.filter(field => {
                    return field.value === true;
                }).map((field, index, fields) => (
                    <div
                    key={index}
                    className={'text-button'}
                    onClick={field.onClick}
                    style={{
                        alignItems: 'center',
                        background: Appearance.colors.softGradient(field.color),
                        border: `2px solid ${field.color}`,
                        borderRadius: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: index === fields.length - 1 ? 12 : 8,
                        minWidth: 0,
                        overflow: 'hidden',
                        padding: '6px 10px 6px 10px',
                        width: '100%'
                    }}>
                        <img
                        src={'images/alert-icon-white-small.png'}
                        style={{
                            height: 30,
                            marginRight: 8,
                            minHeight: 30,
                            minWidth: 30,
                            width: 30
                        }} />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                                color: 'white',
                                fontWeight: 800
                            }}>{field.title}</span>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                color: 'white',
                                fontWeight: 600
                            }}>{field.message}</span>
                        </div>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft: 8
                        }}>
                            {field.rightContent}
                            <img
                            src={'images/next-arrow-white-small.png'}
                            style={{
                                height: 12,
                                objectFit: 'contain',
                                width: 12
                            }} />
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const getMessages = () => {

        // determine if user has permission to view this content
        if(hideSMS === true || utils.user.permissions.get('leads.details.sms') === false) {
            return null;
        }

        return (
            <LeadTextMessages
            abstract={abstract}
            style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 24
            }}
            utils={utils} />
        )
    }

    const getSystemEvents = () => {
        return (
            <SystemEventsLayerItem 
            abstract={abstract} 
            permissions={['leads.details.system_events']}
            utils={utils} />
        )
    }

    const getTopFields = () => {

        if(!lead) {
            return null;
        }

        let items = [{
            key: 'customer',
            permissions: ['leads.details.customer'],
            title: 'Customer Details',
            items: [{
                key: 'email_address',
                title: 'Email Address',
                value: lead.email_address
            },{
                key: 'first_name',
                title: 'First Name',
                value: lead.first_name
            },{
                key: 'homeowner_status',
                title: 'Homeowner Status',
                value: lead.homeowner_status ? lead.homeowner_status.text : null
            },{
                key: 'id',
                title: 'ID',
                value: lead.id
            },{
                key: 'last_name',
                title: 'Last Name',
                value: lead.last_name
            },{
                key: 'marital_status',
                title: 'Marital Status',
                value: lead.marital_status ? lead.marital_status.text : null
            },{
                key: 'occupational_status',
                title: 'Occupational Status',
                value: lead.occupational_status ? lead.occupational_status.text : null
            },{
                key: 'phone_number',
                title: 'Phone Number',
                value: lead.phone_number && Utils.formatPhoneNumber(lead.phone_number)
            }]
        },{
            key: 'spouse',
            permissions: ['leads.details.spouse'],
            title: 'Spouse Details',
            items: [{
                key: 'spouse_email_address',
                title: 'Email Address',
                value: lead.spouse_email_address
            },{
                key: 'spouse_first_name',
                title: 'First Name',
                value: lead.spouse_first_name
            },{
                key: 'spouse_last_name',
                title: 'Last Name',
                value: lead.spouse_last_name
            },{
                key: 'spouse_phone_number',
                title: 'Phone Number',
                value: lead.spouse_phone_number && Utils.formatPhoneNumber(lead.spouse_phone_number)
            }]
        }];

        if(customerResponse) {
            items = items.concat([{
                key: 'customer_response',
                permissions: ['leads.details.customer_response'],
                title: lead.lead_type && lead.lead_type.id === Lead.Type.static.get().recruiting ? 'Recruiting Survey Response' : 'Customer Survey Response',
                items: [{
                    key: 'date',
                    title: 'Date Submitted',
                    value: Utils.formatDate(customerResponse.date)
                }].concat(customerResponse.responses.map((item, index) => {
                    return {
                        key: index,
                        onClick: item.url ? window.open.bind(this, item.url) : null,
                        title: `${index + 1}. ${item.title}`,
                        value: item.answer
                    }
                }))
            }])
        }

        items = items.concat([{
            key: 'location',
            permissions: ['leads.details.location'],
            title: 'Location',
            visible: lead.address && lead.location ? true : false,
            items: [{
                component: 'map',
                key: 'location',
                static: lead.static_map_image_url,
                title: 'Location',
                valid: lead.location ? true : false,
                value: lead.location
            },{
                key: 'address',
                title: Utils.isAddressComplete(lead.address) ? 'Address' : 'Approximate Location',
                value: lead.address ? Utils.formatAddress(lead.address) : null
            },{
                key: 'maps',
                onClick: () => {
                    let address = Utils.formatAddress(lead.address);
                    window.open(`https://www.google.com/maps/place/${encodeURIComponent(address)}`)
                },
                title: 'Directions',
                value: 'Click to View',
                visible: Utils.isAddressComplete(lead.address)
            }]
        },{
            key: 'lead',
            permissions: ['leads.details.general'],
            title: 'About this Lead',
            items: [{
                key: 'released',
                title: 'Released',
                value: lead.released ? 'Yes' : 'No'
            },{
                key: 'lead_type',
                title: 'Script',
                value: lead.lead_script ? lead.lead_script.title : null,
                onClick: lead.lead_script ? onLeadScriptClick : null
            },{
                key: 'lead_sub_type',
                title: 'Sub-Type',
                value: lead.lead_sub_type ? lead.lead_sub_type.text : null
            },{
                key: 'lead_type',
                title: 'Type',
                value: lead.lead_type && (
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}>
                        <span>{lead.lead_type.text}</span>
                        {lead.lead_type.icon && (
                            <img
                            src={lead.lead_type.icon.url}
                            style={{
                                height: 20,
                                marginLeft: 8,
                                objectFit: 'contain',
                                width: 20
                            }} />
                        )}
                    </div>
                )
            }]
        }]);

        // prevent moving forward if no valid items were provided
        let count = formatFields(utils, items).length;
        if(count === 0) {
            return null;
        }

        return (
            <FieldMapper
            fields={items}
            group={User.Group.categories.leads}
            utils={utils}/>
        )
    }

    const getUnstructuredContent = () => {

        // no additional logic is required if no unstructured content id is available
        if(!lead.unstructured_content_id) {
            return null;
        }

        // prevent moving forward if feature is disabled for current user
        if(utils.user.permissions.get('leads.details.custom') === false) {
            return null;
        }

        // prepare section title
        let title = lead.lead_type.id === Lead.Type.static.get().survey_monkey_lead_generation ? 'Custom Survey Monkey Details' : 'Custom Details';

        // show loading component if applicable
        if(unstructuredContent.length === 0) {
            return (
                <LayerItem title={title}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 100,
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <LottieView
                        autoPlay={true}
                        loop={true}
                        source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                        style={{
                            width: 40,
                            height: 40
                        }}/>
                    </div>
                </LayerItem>
            )
        }

        // prepare field mapper items
        let items = [{
            key: 'unstructured_content',
            title: title,
            items: unstructuredContent.map(item => ({
                key: item.id,
                title: item.title,
                value: item.value
            })).sort((a,b) => {
                return a.title.localeCompare(b.title);
            })
        }];

        return (
            <FieldMapper
            fields={items}
            group={User.Group.categories.leads}
            utils={utils}/>
        )
    }

    const fetchEvents = async () => {
        try {
            setLoading('system_events');
            let { events, paging } = await Request.get(utils, '/utils/', {
                limit: eventsLimit,
                offset: eventsOffset,
                target_id: abstract.getID(),
                target_type: abstract.type,
                type: 'system_events'
            });

            setLoading(false);
            setEventsPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchDetails = async () => {
        try {
            let { customer_response, dealership, demo_details, lead, tags, unstructured_content } = await Request.get(utils, '/leads/', {
                id: abstract.getID(),
                type: 'ext_details'
            });

            // set and format customer response if applicable
            setCustomerResponse(customer_response && {
                ...customer_response,
                date: moment(customer_response.date)
            });

            // set dealership, demo details, and unstructured custom content
            setDealership(Dealership.create(dealership));
            setDemoDetails(demo_details);
            setUnstructuredContent(unstructured_content);

            // set lead affiliate referral
            abstract.object.affiliate = lead.affiliate;
            setLead(abstract.object);

            // set formatted tags
            abstract.object.tags = tags;
            setTags(tags);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retreiving some of the information for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const connectToSockets = async () => {
        try {

            // create system event subscriptions
            await utils.sockets.emit('system', 'join_events', { tag: abstract.getTag()} );
            await utils.sockets.on('system', 'on_new_event', onNewSystemEvent);

        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('system', 'on_new_event', onNewSystemEvent);
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        fetchEvents();
    }, [eventsOffset]);

    useEffect(() => {

        fetchDetails();
        connectToSockets();

        utils.content.subscribe(layerID, ['demo', 'demo_status', 'lead'], {
            onFetch: fetchDetails,
            onSetDealership: (next_abstract, dealership) => {
                if(next_abstract.getID() === abstract.getID()) {
                    setLead(next_abstract.object);
                    setDealership(dealership);
                }
            },
            onUpdate: (abstract, category) => {
                switch(abstract.type) {
                    case 'demo':
                    case 'demo_status':
                    fetchDetails();
                    break;

                    case 'lead':
                    setLead(lead => {
                        if(category === 'status') {
                            lead.status = abstract.object.status;
                            return lead;
                        }
                        return lead.id === abstract.getID() ? abstract.object : lead;
                    });
                    break;
                }
            },
        });
        return () => {
            utils.content.unsubscribe(layerID);
            disconnectFromSockets();
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={abstract.getTitle()}
        utils={utils}
        options={{
            ...options,
            extension: {
                abstract: abstract,
                permissions: {
                    delete: ['leads.notes.actions.delete'],
                    edit: ['leads.notes.actions.edit'],
                    new: ['leads.notes.actions.new'],
                    view: ['leads.notes']
                },
                type: 'notes'
            },
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            {getHeaderBanner()}
            {getMessages()}
            {getTopFields()}
            {getUnstructuredContent()}
            {getBottomFields()}
            {getSystemEvents()}
        </Layer>
    )
}

export const LeadDuplicates = ({ index, options, utils }) => {

    const layerID = 'lead_duplicates';
    const limit = 10;
    const offset = useRef(0);

    const [filters, setFilters] = useState({});
    const [groups, setGroups] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [selected, setSelected] = useState([]);

    const onArchiveLead = () => {
        utils.alert.show({
            title: 'Archive Lead',
            message: 'Are you sure that you want to archive these leads? These leads will no longer show up within your available pool of leads',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onArchiveLeadConfrim();
                    return;
                }
            }
        })
    }

    const onArchiveLeadConfrim = async () => {
        try {

            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/leads/', {
                type: 'batch_archive_duplicates',
                ids: selected
            });

            setLoading(false);
            setLayerState('close');
            utils.alert.show({
                title: 'All Done!',
                message: 'The leads that you selected have been archived. You can find these Leads in the "Archived Leads" section of Global Data if you need to reverse your decision'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue archiving these Lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onCheckboxChange = (id, checked, evt) => {
        evt.stopPropagation();
        setSelected(ids => {
            if(checked === false) {
                return ids.filter(prev_id => prev_id !== id)
            }
            return update(ids, {
                $push: [ id ]
            })
        })
    }

    const onLeadClick = async id => {
        return new Promise(async (resolve, reject) => {
            try {
                let lead = await Lead.get(utils, id);
                utils.layer.open({
                    abstract: Abstract.create({
                        object: lead,
                        type: 'lead'
                    }),
                    Component: LeadDetails,
                    id: `lead_details_${id}`,
                    permissions: ['leads.details']
                });
                resolve();
            } catch(e) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue retrieving the details for this lead. ${e.message || 'An unknown error occurred'}`
                });
                reject(e);
            }
        })
    }

    const onSelectAll = async () => {
        try {
            setLoading(true);
            let { selected } = await Request.get(utils, '/leads/', {
                type: 'duplicates',
                select_all: true
            });
            setLoading(false);
            setSelected(selected);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue selecting all duplicate leads. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onShowLeadFilters = () => {
        utils.layer.open({
            id: 'lead_filters',
            Component: LeadFilters.bind(this, {
                value: filters,
                onChange: result => {
                    offset.current = 0;
                    setFilters(result);
                },
                
            })
        });
    }

    const getButtons = () => {

        // prepare list of buttons
        let buttons = [{
            color: 'light',
            key: 'filters',
            onClick: onShowLeadFilters,
            text: 'Filters'
        },{
            color: 'dark',
            key: 'select_all',
            onClick: onSelectAll,
            text: 'Select All'
        }];

        // provide options to deselect all and archive selected if at least one lead is selected
        if(selected.length > 0) {
            buttons.push({
                color: 'danger',
                key: 'archive',
                onClick: onArchiveLead,
                permissions: ['leads.duplicates.actions.edit'],
                text: 'Archive Selected',
            });
        }
        return buttons;
    }

    const fetchLeads = async () => {
        try {
            setLoading(true);
            let { groups, paging, selected } = await Request.get(utils, '/leads/', {
                filters: filters,
                limit: limit,
                offset: offset.current,
                type: 'duplicates'
            });

            setLoading(false);
            setPaging(paging);
            setGroups(groups);
            setSelected(selected || []);

        } catch(e) {
            setLoading(false); 
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retreiving the list of duplicate leads. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchLeads();
    }, [filters]);

    useEffect(() => {
        utils.content.subscribe(layerID, ['lead', 'lead_status'], {
            onFetch: fetchLeads,
            onUpdate: abstract => {
                setGroups(groups => {
                    return groups.map(group => {
                        group.leads = group.leads.map(lead => {
                            if(lead.id === abstract.getID()) {
                                switch(abstract.type) {
                                    case 'lead':
                                    return abstract.object;

                                    case 'lead_status':
                                    lead.status = abstract.object.status;
                                    break;
                                }
                            }
                            return lead;
                        });
                        return group;
                    });
                });
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Duplicate Leads'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading
        }}>
            <div style={{
                alignItems: 'center',
                background: Appearance.colors.softGradient(Appearance.colors.red),
                border: `2px solid ${Appearance.colors.red}`,
                borderRadius: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: 12,
                overflow: 'hidden',
                padding: '6px 10px 6px 10px',
                textAlign: 'center',
                width: '100%'
            }}>
                <img
                src={'images/alert-icon-white-small.png'}
                style={{
                    height: 45,
                    marginBottom: 8,
                    width: 45
                }} />
                <span style={{
                    ...Appearance.textStyles.title(),
                    color: 'white',
                    fontWeight: 800
                }}>{'Archive Duplicate Leads'}</span>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: 600,
                    whiteSpace: 'normal'
                }}>{'These are all the duplicate leads in your Dealership. Check the box next to a Lead to mark that Lead as "Ready to Archive" and click the "Archive Selected" button when you are done.'}</span>
            </div>
            <div style={{
                maxHeight: window.innerHeight / 2,
                overflowY: 'scroll'
            }}>
                {groups && groups.length === 0 && (
                    <div 
                    key={index}
                    style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {Views.entry({
                            bottomBorder: false,
                            hideIcon: true,
                            subTitle: 'There were no duplicate leads were found using your search criteria',
                            title: 'No Leads Found'
                        })}
                    </div>
                )}
                {groups && groups.map((group, index) => {
                    return (
                        <div 
                        key={index}
                        style={{
                            ...Appearance.styles.unstyledPanel(),
                            border: `2px solid ${Appearance.colors.red}`,
                            marginBottom: index !== groups.length - 1 ? 12 : 0
                        }}>
                            <div style={{
                                background: Appearance.colors.softGradient(Appearance.colors.red),
                                padding: '6px 12px 6px 12px'
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    color: 'white'
                                }}>{`${group.leads.length - 1} ${group.leads.length - 1 === 1 ? 'Duplicate' : 'Duplicates'}`}</span>
                            </div>
                            {group.leads.map((lead, index) => {
                                return (
                                    <AsyncViewComponent
                                    badge={{
                                        color: group.max_events === lead.id ? Appearance.colors.primary() : (window.theme === 'dark' ? Appearance.colors.lightGrey : Appearance.colors.darkGrey),
                                        text: lead.events > 0 ? `${lead.events} Changes` : null
                                    }}
                                    bottomBorder={index !== group.length - 1}
                                    hideIcon={true}
                                    key={index}
                                    loading={loading === lead.id}
                                    onClick={onLeadClick.bind(this, lead.id)}
                                    rightContent={(
                                        <div style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                            <div style={{
                                                marginRight: 8,
                                                width: 100
                                            }}>
                                                {getLeadStatus(utils, lead, { editable: false })}
                                            </div>
                                            <Checkbox
                                            checked={selected.includes(lead.id)}
                                            color={Appearance.colors.red}
                                            onChange={onCheckboxChange.bind(this, lead.id)} />
                                        </div>
                                    )}
                                    subTitle={lead.phone_number}
                                    title={lead.full_name}  />
                                )
                            })}
                        </div>
                    )
                })}
            </div>
            {paging && (
                <PageControl
                data={paging}
                limit={limit}
                offset={offset}
                onClick={next => {
                    offset.current = next;
                    fetchLeads();
                }} />
            )}
        </Layer>
    )
}

export const LeadFilters = ({ onChange, value = {} }, { index, options, utils }) => {

    const layerID = 'lead_filters';

    const [administrativeAreaLevels, setAdministrativeAreaLevels] = useState([]);
    const [assignmentUsers, setAssignmentUsers] = useState([]);
    const [enrollmentUsers, setEnrollmentUsers] = useState([]);
    const [layerState, setLayerState] = useState(false);
    const [leadCreditUsers, setLeadCreditUsers] = useState([]);
    const [leadSubTypes, setLeadSubTypes] = useState([]);
    const [leadTypes, setLeadTypes] = useState([]);
    const [localities, setLocalities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [homeownerStatusCodes, setHomeownerStatusCodes] = useState([]);
    const [maritalStatusCodes, setMaritalStatusCodes] = useState([]);
    const [marketingUsers, setMarketingUsers] = useState([]);
    const [occupationalStatusCodes, setOccupationalStatusCodes] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [searchProps, setSearchProps] = useState(value || {});
    const [statusCodes, setStatusCodes] = useState([]);
    const [tags, setTags] = useState([]);
    const [userCount, setUserCount] = useState(0);

    const onUpdateSearchProps = props => {
        setSearchProps(prev => ({
            ...prev,
            ...props
        }));
    }

    const getButtons = () => {
        return [{
            key: 'reset',
            text: 'Reset Filters',
            color: 'dark',
            onClick: () => {
                setLayerState('close');
                if(typeof(onChange) === 'function') {
                    onChange({});
                }
            }
        },{
            key: 'apply',
            text: 'Apply Filters',
            color: 'primary',
            onClick: () => {
                setLayerState('close');
                if(typeof(onChange) === 'function') {
                    onChange(searchProps);
                }
            }
        }];
    }

    const getFilterContent = () => {
        if(loading === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 100,
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        width: 40,
                        height: 40
                    }}/>
                </div>
            )
        }

        let fields = [{
            key: 'customer',
            title: 'Customer',
            items: [{
                key: 'homeowner_status',
                required: false,
                title: 'Homeowner Status',
                component: 'multiple_list',
                value: searchProps.homeowner_status && homeownerStatusCodes.filter(val => searchProps.homeowner_status.includes(val.id)),
                items: homeownerStatusCodes,
                onChange: items => onUpdateSearchProps({ 'homeowner_status': items && items.map(item => item.id) })
            },{
                key: 'marital_status',
                required: false,
                title: 'Marital Status',
                component: 'multiple_list',
                value: searchProps.marital_status && maritalStatusCodes.filter(val => searchProps.marital_status.includes(val.id)),
                items: maritalStatusCodes,
                onChange: items => onUpdateSearchProps({ 'marital_status': items && items.map(item => item.id) })
            },{
                key: 'occupational_status',
                required: false,
                title: 'Occupational Status',
                component: 'multiple_list',
                value: searchProps.occupational_status && occupationalStatusCodes.filter(val => searchProps.occupational_status.includes(val.id)),
                items: occupationalStatusCodes,
                onChange: items => onUpdateSearchProps({ 'occupational_status': items && items.map(item => item.id) })
            }]
        },{
            key: 'location',
            title: 'Location',
            items: [{
                key: 'localities',
                required: false,
                title: 'City',
                component: 'multiple_list',
                value: searchProps.localities && localities.filter(val => searchProps.localities.includes(val.id)),
                items: localities,
                onChange: items => onUpdateSearchProps({ 'localities': items && items.map(item => item.id) })
            },{
                key: 'location_radius',
                required: false,
                title: 'Location Radius',
                component: 'location_radius_lookup',
                value: searchProps.location_radius,
                onChange: result => onUpdateSearchProps({ location_radius: result }),
                props: {
                    channel: 'leads'
                }
            },{
                key: 'administrative_area_levels',
                required: false,
                required: false,
                title: 'State',
                component: 'multiple_list',
                value: searchProps.administrative_area_levels && administrativeAreaLevels.filter(val => searchProps.administrative_area_levels.includes(val.id)),
                items: administrativeAreaLevels,
                onChange: items => onUpdateSearchProps({ 'administrative_area_levels': items && items.map(item => item.id) })
            }]
        },{
            key: 'details',
            title: 'Lead Details',
            items: [{
                key: 'lead_types',
                required: false,
                title: 'Lead Type',
                component: 'multiple_list',
                value: searchProps.lead_types && leadTypes.filter(val => searchProps.lead_types.includes(val.id)).map(entry => ({
                    ...entry,
                    title: entry.text
                })),
                onChange: items => onUpdateSearchProps({ 'lead_types': items && items.map(item => item.id) }),
                items: leadTypes.map(leadType => ({
                    ...leadType,
                    title: leadType.text
                }))
            },{
                key: 'lead_sub_types',
                required: false,
                title: 'Lead Sub-Type',
                component: 'multiple_list',
                value: searchProps.lead_sub_types && leadSubTypes.filter(val => searchProps.lead_sub_types.includes(val.id)).map(entry => ({
                    ...entry,
                    title: entry.text
                })),
                onChange: items => onUpdateSearchProps({ 'lead_sub_types': items && items.map(item => item.id) }),
                items: leadSubTypes.map(leadSubType => ({
                    ...leadSubType,
                    title: leadSubType.text
                }))
            },{
                key: 'priority',
                required: false,
                title: 'Priority',
                component: 'list',
                items: priorities,
                onChange: item => onUpdateSearchProps({ 'priority': item ? (item.id === 'yes' ? 1 : 0) : null }),
                value: searchProps.priority <= 1 ? (searchProps.priority ? 'Yes' : 'No') : null
            },{
                key: 'programs',
                required: false,
                title: 'Program',
                component: 'multiple_list',
                value: searchProps.programs && programs.filter(val => searchProps.programs.includes(val.id)),
                items: programs,
                onChange: items => onUpdateSearchProps({ 'programs': items && items.map(item => item.id) })
            },{
                key: 'referred_by_lead',
                required: false,
                title: 'Referred By Lead',
                component: 'lead_lookup',
                value: searchProps.referred_by_lead,
                onChange: result => onUpdateSearchProps({ 'referred_by_lead': result })
            },{
                component: 'dual_date_picker',
                endDate: searchProps.sale_dates && searchProps.sale_dates.end_date,
                items: statusCodes,
                key: 'sale_date',
                onEndDateChange: date => {
                    onUpdateSearchProps({ 
                        sale_dates: {
                            ...searchProps.sale_dates,
                            end_date: date && date.format('YYYY-MM-DD')
                        }
                    });
                },
                onStartDateChange: date => {
                    onUpdateSearchProps({ 
                        sale_dates: {
                            ...searchProps.sale_dates,
                            start_date: date && date.format('YYYY-MM-DD')
                        }
                    });
                },
                props: { 
                    deselect: true,
                    style: {
                        flexGrow: 1
                    }
                },
                required: false,
                startDate: searchProps.sale_dates && searchProps.sale_dates.start_date,
                title: 'Sale Date'
            },{
                key: 'status_codes',
                required: false,
                title: 'Status',
                component: 'multiple_list',
                value: searchProps.status_codes && statusCodes.filter(val => searchProps.status_codes.includes(val.id)),
                items: statusCodes,
                onChange: items => onUpdateSearchProps({ 'status_codes': items && items.map(item => item.id) })
            },{
                key: 'tags',
                required: false,
                title: 'Tags',
                component: 'multiple_list',
                value: searchProps.tags && tags.filter(val => searchProps.tags.includes(val.id)),
                items: tags,
                onChange: items => {
                    console.log(items);
                    onUpdateSearchProps({ 'tags': items && items.map(item => item.id) })
                }
            },{
                key: 'unread_sms',
                required: false,
                title: 'Unread Text Messages',
                component: 'bool_list',
                value: searchProps.unread_sms,
                onChange: item => {
                    onUpdateSearchProps({ 'unread_sms': typeof(item) === 'boolean' ? item : null })
                },
            }]
        },{
            key: 'users',
            title: 'Users',
            visible: userCount > 0,
            items: [{
                key: 'assignment_users',
                required: false,
                title: 'Assigned To',
                component: 'multiple_list',
                value: searchProps.assignment_users && assignmentUsers.filter(val => searchProps.assignment_users.includes(val.id)),
                items: assignmentUsers,
                onChange: items => onUpdateSearchProps({ 'assignment_users': items && items.map(item => item.id) })
            },{
                key: 'lead_credit_users',
                required: false,
                title: 'Lead Credit',
                component: 'multiple_list',
                value: searchProps.lead_credit_users && leadCreditUsers.filter(val => searchProps.lead_credit_users.includes(val.id)),
                items: leadCreditUsers,
                onChange: items => onUpdateSearchProps({ 'lead_credit_users': items && items.map(item => item.id) })
            },{
                key: 'marketing_director_users',
                required: false,
                title: 'Marketing Director',
                component: 'multiple_list',
                value: searchProps.marketing_director_users &&  marketingUsers.filter(val => searchProps.marketing_director_users.includes(val.id)),
                items: marketingUsers,
                onChange: items => onUpdateSearchProps({ 'marketing_director_users': items && items.map(item => item.id) })
            },{
                key: 'enrollment_users',
                required: false,
                title: 'Survey or Program Credit',
                component: 'multiple_list',
                value: searchProps.enrollment_users && enrollmentUsers.filter(val => searchProps.enrollment_users.includes(val.id)),
                items: enrollmentUsers,
                onChange: items => onUpdateSearchProps({ 'enrollment_users': items && items.map(item => item.id) })
            }]
        }].map(section => {
            section.items = section.items.filter(item => item.items ? item.items.length > 0 : true);
            return section;
        });

        return (
            <AltFieldMapper
            utils={utils}
            fields={fields} />
        )
    }

    const fetchFilterTargets = async () => {

        try {
            await Utils.sleep(0.5);
            let {
                administrative_area_levels, enrollment_users, homeowner_status_codes, lead_sub_types, lead_types, localities, marital_status_codes, occupational_status_codes, programs, tags, users
            } = await Request.get(utils, '/dealerships/', {
                type: 'filter_targets'
            })

            setAdministrativeAreaLevels(administrative_area_levels.filter(target => {
                return target !== null;
            }).map(target => ({
                id: target,
                title: target
            })));
            setAssignmentUsers(users.map(target => ({
                id: target.user_id,
                title: target.full_name
            })));
            setEnrollmentUsers(enrollment_users.map(target => ({
                id: target.user_id,
                title: target.full_name
            })));
            setHomeownerStatusCodes(homeowner_status_codes.map(target => ({
                ...target,
                id: target.code,
                title: target.text
            })));
            setLeadCreditUsers(users.map(target => ({
                id: target.user_id,
                title: target.full_name
            })));
            setLeadSubTypes(lead_sub_types);
            setLeadTypes(lead_types);
            setLocalities(localities.filter(target => {
                return target !== null;
            }).map(target => ({
                id: target,
                title: target
            })));
            setMaritalStatusCodes(marital_status_codes.map(target => ({
                id: target.code,
                title: target.text
            })));
            setMarketingUsers(users.filter(user => {
                return user.level === User.levels.get().marketing_director;
            }).map(target => ({
                id: target.user_id,
                title: target.full_name
            })));
            setOccupationalStatusCodes(occupational_status_codes.map(target => ({
                id: target.code,
                title: target.text
            })));
            setPriorities([{
                id: 'yes',
                title: 'Yes'
            },{
                id: 'no',
                title: 'No'
            }]);
            setPrograms(programs.map(target => ({
                ...target,
                title: target.name
            })));
            setStatusCodes(utils.dealership.status_codes.get().filter(entry => {
                return value && value.exclude_status_codes && value.exclude_status_codes.includes(entry.status) ? false : true;
            }).map(entry => ({
                id: entry.code,
                title: entry.text
            })));
            setTags(tags.map(tag => ({
                ...tag,
                title: tag.text
            })));
            setUserCount(users.length);
            setLoading(false);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the filter options. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupValues = () => {

        let next = { ...value };
        if(value && value.assignments) {
            if(!next.assignment_users) {
                next.assignment_users = [];
            }
            next.assignment_users.push(utils.user.get().user_id);

            if(!next.marketing_director_users) {
                next.marketing_director_users = [];
            }
            next.marketing_director_users.push(utils.user.get().user_id);
        }
        setSearchProps(next);
    }

    useEffect(() => {
        setupValues();
        fetchFilterTargets();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Lead Filters'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            {getFilterContent()}
        </Layer>
    )
}

export const LeadImportHistory = ({ index, options, utils }) => {

    const panelID = 'lead_import_history';
    const limit = 10;
    const offset = useRef(0);

    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState(null);
    const [tasks, setTasks] = useState([]);

    const onDealershipChange = () => {
        offset.current = 0;
        setLoading(true);
        setPaging(null);
        fetchTasks();
    }

    const onNewLeadImportClick = () => {
        utils.layer.open({
            Component: ImportLeads,
            id: 'import_leads',
            title: 'Import Leads'
        });
    }

    const onTaskClick = task => {
        utils.layer.open({
            Component: LeadImportTaskDetails.bind(this, { task }),
            id: `lead_import_task_details_${task.id}`,
        });
    }

    const getButtons = () => {
        return [{
            key: 'new',
            onClick: onNewLeadImportClick,
            title: 'Import New Leads',
            style: 'default'
        }];
    }

    const getContent = () => {
        if(loading === true) {
            return null;
        }
        if(tasks.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    icon: {
                        path: 'images/lead_import_history_icon_clear.png',
                        imageStyle: {
                            backgroundColor: Appearance.colors.grey()
                        }
                    },
                    subTitle: 'No lead import history entries were found for your dealership.',
                    title: 'No History Found'
                })
            )
        }
        return tasks.map((task, index) => {
            return (
                Views.entry({
                    bottomBorder: index !== tasks.length - 1,
                    icon: {
                        path: 'images/lead_import_history_icon_clear.png',
                        imageStyle: {
                            backgroundColor: task.status.color
                        }
                    },
                    key: index,
                    onClick: onTaskClick.bind(this, task),
                    rightContent: (
                        <AltBadge content={task.status} />
                    ),
                    subTitle: Utils.formatDate(task.date),
                    title: task.document && task.document.name || 'Document name not available'
                })
            )
        });
    }

    const fetchTasks = async () => {
        try {
            let { paging, tasks } = await Request.get(utils, '/leads/', {
                limit: limit,
                offset: offset.current,
                type: 'import_history'
            });

            setLoading(false);
            setPaging(paging);
            setTasks(tasks.map(task=> ({
                ...task,
                date: moment.utc(task.date).local()
            })))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the lead import history list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {

        fetchTasks();
        utils.events.on(panelID, 'dealership_change', onDealershipChange);
        return () => {
            utils.events.off(panelID, 'dealership_change', onDealershipChange);
        }

    }, []);

    return (
        <Panel
        index={index}
        name={'Lead Import History'}
        panelID={panelID}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            loading: loading === true,
            paging: {
                data: paging,
                limit: limit,
                loading: loading === 'paging',
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    setLoading('paging');
                    fetchTasks();
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const LeadTableEntry = ({ isLastItem = false, lead, loading, onLeadClick, onSortingChange, props, sorting, utils }) => {

    const getContent = () => {
        
        let target = lead || {};
        let fields = [{
            key: 'full_name',
            title: 'Name',
            value: target.full_name || 'Customer name not available',
            prepend: lead && (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {(lead.priority || lead.unread_sms) &&  (
                        <div style={{
                            backgroundColor: lead.unread_sms ? Appearance.colors.green : Appearance.colors.blue,
                            borderRadius: 5,
                            height: 10,
                            marginRight: 8,
                            minHeight: 10,
                            minWidth: 10,
                            overflow: 'hidden',
                            width: 10
                        }} />
                    )}
                    {lead.lead_type && lead.lead_type.icon && (
                        <img
                        src={lead.lead_type.icon.url}
                        style={{
                            height: 20,
                            marginRight: 8,
                            objectFit: 'contain',
                            width: 20
                        }} />
                    )}
                </div>
            )
        },{
            key: 'locality',
            title: 'City',
            value: target.address ? target.address.locality : null
        },{
            key: 'phone_number',
            title: 'Phone Number',
            sortable: false,
            value: target.phone_number
        },{
            key: 'lead_type.text',
            title: 'Lead Type',
            value: target.lead_type && target.lead_type.text
        },{
            key: 'last_updated',
            title: 'Last Updated',
            value: target.extended_details && target.extended_details.sys_evt_date ? Utils.formatDate(target.extended_details.sys_evt_date) : null,
            visible: props && props.follow_ups === true ? true : false
        },{
            key: 'status',
            title: 'Status',
            sortable: false,
            value: getStatusBadge(utils, target)
        }];

        // create table headers with custom sorting options
        if(!lead) {
            return (
                <TableListHeader
                fields={fields}
                onChange={onSortingChange}
                value={sorting} />
            )
        }

        // loop through results and create table row
        return (
            <tr
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: isLastItem === false && `1px solid ${Appearance.colors.divider()}`,
                position: 'relative',
            }}>
                {fields.filter(field => {
                    return field.visible !== false;
                }).map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}
                        onClick={onLeadClick.bind(this, lead)}
                        style={{
                            position: 'relative'
                        }}>
                            <div style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row'
                            }}>
                                {index === 0 && loading === true && (
                                    <img 
                                    className={'rotate'} 
                                    src={'images/blue-radial-loader.png'}
                                    style={{
                                        height: 20,
                                        marginRight: 8,
                                        width: 20
                                    }} />
                                ) || field.prepend}
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    width: '100%'
                                }}>{field.value}</span>
                            </div>
                            
                        </td>
                    )
                })}
            </tr>
        )
    }

    const getStatusBadge = (utils, target) => {

        // determine if a transfer related status badge is required
        if(props && props.transfers) {
            if(!target.transfer) {
                return null;
            }
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    maxWidth: 95,
                    textAlign: 'center',
                    border: `1px solid ${target.transfer.status.color}`,
                    background: Appearance.colors.softGradient(target.transfer.status.color),
                    borderRadius: 5,
                    overflow: 'hidden'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: 'white',
                        fontWeight: '600',
                        width: '100%'
                    }}>{target.transfer.status.text}</span>
                </div>
            )
        }

        // determine if an archive badge is required
        if(props && props.show_archived_only) {
            return (
                <div style={{
                    alignItems: 'center',
                    background: Appearance.colors.softGradient(Appearance.colors.red),
                    border: `1px solid ${Appearance.colors.red}`,
                    borderRadius: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    maxWidth: 95,
                    overflow: 'hidden',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: 'white',
                        fontWeight: '600',
                        width: '100%'
                    }}>{'Archived'}</span>
                </div>
            )
        }

        // fallback to using a standard lead status badge
        return getLeadStatus(utils, target);
    }

    return getContent();
}

export const LeadsLayer = ({ filters, id, sorting, title }, { index, options, utils }) => {

    const limit = 10;
    const offset = useRef(0);
    const sortingRef = useRef(sorting || { 
        sort_key: 'date', 
        sort_type: Content.sorting.type.descending 
    });

    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState('init');
    const [paging, setPaging] = useState(null);

    const onLeadClick = async target => {
        try {

            // prevent moving forward if feature is disabled for current user
            if(utils.user.permissions.get('leads.details') === false) {
                return utils.user.permissions.reject();
            }
            
            // fetch details for lead
            // only partial details are included through the filter based search for performance purposes
            // port over transfer information if applicable
            setLoading(target.id);
            let lead = await Lead.get(utils, target.id);

            // set transfer object 
            setLoading(false);
            lead.transfer = target.transfer;

            // open details layer for lead
            utils.layer.open({
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead',
                }),
                Component: LeadDetails,
                id: `lead_details_${lead.id}`,
                permissions: ['leads.details']
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 15
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={window.theme === 'dark' ? require('files/lottie/dots-white.json') : require('files/lottie/dots-grey.json')}
                    style={{
                        height: 50,
                        width: 50
                    }}/>
                </div>
            )
        }
        if(leads.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No leads matching your search criteria were found.',
                    title: 'No Leads Found'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    <LeadTableEntry
                    key={index}
                    onSortingChange={props => {
                        sortingRef.current = props;
                        setLoading(true);
                        fetchLeads();
                    }}
                    sorting={sortingRef.current}
                    utils={utils} />
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {leads.map((lead, index, leads) => {
                        return (
                            <LeadTableEntry
                            isLastItem={index === leads.length - 1}
                            key={index}
                            lead={lead}
                            loading={loading === lead.id} 
                            onLeadClick={onLeadClick}
                            utils={utils} />
                        )
                    })}
                </tbody>
            </table>
        )
    }
    
    const fetchLeads = async () => {
        try {

            let { leads, paging } = await Request.post(utils, '/leads/', {
                filter_props: filters,
                limit: limit,
                offset: offset.current,
                type: 'advanced_search',
                ...sortingRef.current
            });
           
            setLoading(false);
            setPaging(paging);
            setLeads(leads);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of leads. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchLeads();
    }, []);

    return (
        <Layer
        id={id}
        index={index}
        title={title}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            sizing: 'extra_large'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
                {paging && (
                    <PageControl
                    data={paging}
                    limit={limit}
                    loading={loading === 'paging'}
                    offset={offset}
                    onClick={next => {
                        offset.current = next;
                        setLoading('paging');
                        fetchLeads();
                    }}/>
                )}
            </div>
        </Layer>
    )
}

export const LeadImportTaskDetails = ({ task }, { index, options, utils }) => {

    const layerID = `lead_import_task_details_${task.id}`;

    const onDocumentClick = () => {
        utils.sheet.show({
            items: [{
                key: 'download',
                title: 'Download',
                style: 'default'
            }]
        }, key => {
            if(key === 'download') {
                window.open(task.document.url);
                return;
            }
        });
    }

    const onViewLeads = () => {
        let id = `leads_layer_tag_${task.tag.id}`;
        utils.layer.open({
            Component: LeadsLayer.bind(this, {
                filters: {
                    tags: [task.tag.id]
                },
                id: id,
                sorting: {
                    sort_key: 'full_name',
                    sort_type: Content.sorting.type.ascending
                },
                title: `Leads for "${task.tag.text}" Tag`
            }),
            id: id
        });
    }


    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'leads',
            onClick: onViewLeads,
            text: 'View Leads'
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'date',
                title: 'Date',
                value: Utils.formatDate(task.date)
            },{
                key: 'id',
                title: 'ID',
                value: task.id
            },{
                color: task.status.color,
                key: 'status',
                title: 'Status',
                value: task.status.text
            }]
        },{
            key: 'stats',
            lastItem: true,
            title: 'Statistics',
            items: [{
                key: 'tag',
                title: 'Dealership Tag',
                value: task.tag.text
            },{
                key: 'document',
                onClick: task.document ? onDocumentClick : null,
                title: 'Document',
                value: task.document && task.document.name || 'Not Available'
            },{
                key: 'elapsed',
                title: 'Duration',
                value: task.data && Utils.parseDuration(task.data.elapsed)
            },{
                key: 'error_message',
                title: 'Error Message',
                value: task.data && task.data.message,
                visible: task.data && task.data.message ? true : false
            },{
                key: 'skipped',
                title: 'Skipped Leads',
                value: task.skipped || '0'
            }]
        }];
    }

    const getTitle = () => {
        return task.document ? `Lead Import for ${task.document.name}` : `Lead Import ${task.id} Task Details`;
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={getTitle()}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium'
        }}>
            <FieldMapper
            fields={getFields()} 
            utils={utils} />
        </Layer>
    )
}

export const LeadPrograms = ({ abstract, index, options, utils }) => {

    const layerID = `lead_programs_${abstract.getID()}`;
    const [loading, setLoading] = useState(true);
    const [layerState, setLayerState] = useState(null);
    const [lead, setLead] = useState(abstract.object);
    const [programs, setPrograms] = useState([]);

    const getBadge = program => {
        switch(program.status) {
            case Lead.Program.status.in_progress:
            return {
                text: 'In Progress',
                color: Appearance.colors.primary()
            }

            case Lead.Program.status.completed:
            return {
                text: 'Completed',
                color: Appearance.colors.green
            }

            case Lead.Program.status.funded:
            return {
                text: 'Funded',
                color: Appearance.colors.secondary()
            }

            case Lead.Program.status.revoked:
            return {
                text: 'Revoked',
                color: Appearance.colors.red
            }
        }
    }
    const onEnrollProgram = () => {
        utils.layer.open({
            id: `enroll_lead_program_${abstract.getID()}`,
            abstract: abstract,
            Component: EnrollLeadProgram
        })
    }

    const fetchPrograms = async () => {
        try {
            let { programs } = await Request.get(utils, '/leads/', {
                type: 'programs',
                id: abstract.getID()
            })
            setLoading(false);
            setPrograms(programs.map(program => Lead.Program.create(program)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the programs for this lead. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    const onProgramClick = log => {
        utils.sheet.show({
            items: [{
                key: 'link',
                title: 'Link',
                style: 'default'
            },{
                key: 'leads',
                title: 'Leads',
                style: 'default'
            },{
                key: 'notes',
                title: 'Notes',
                style: 'default'
            },{
                key: 'analytics',
                title: 'Analytics',
                style: 'default'
            }]
        }, key => {

        })
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'enroll',
            onClick: onEnrollProgram,
            text: 'Enroll in Program'
        }];
    }

    useEffect(() => {

        fetchPrograms();
        utils.content.subscribe(layerID, ['lead', 'lead_program', 'lead_status'], {
            onFetch: fetchPrograms,
            onUpdate: abstract => {
                switch(abstract.type) {
                    case 'lead':
                    setLead(lead => {
                        return lead.id === abstract.getID() ? abstract.object : lead
                    });
                    break;

                    case 'lead_program':
                    setPrograms(programs => {
                        return programs.map(program => {
                            return program.id === abstract.getID() ? abstract.object : program
                        });
                    });
                    break;

                    case 'lead_status':
                    setLead(lead => {
                        if(lead.id === abstract.getID()) {
                            lead.status = abstract.object.status;
                        }
                        return lead;
                    });
                    break;
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={abstract.getTitle()}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            {programs.length > 0
                ?
                <div style={{
                    borderRadius: 10,
                    border: `1px solid ${Appearance.colors.divider()}`
                }}>
                {programs.map((program, index) => {
                    return (
                        Views.entry({
                            key: index,
                            title: program.program.name,
                            subTitle: program.program.description,
                            badge: getBadge(program),
                            hideIcon: true,
                            singleItem: programs.length === 1,
                            firstItem: index === 0,
                            lastItem: index === programs.length - 1,
                            bottomBorder: true,
                            onClick: onProgramClick.bind(this, program)
                        })
                    )
                })}
                </div>
                :
                loading
                    ?
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: 75
                    }}>
                        <LottieView
                        loop={true}
                        autoPlay={true}
                        source={window.theme === 'dark' ? 'files/lottie/dots-white.json' : 'files/lottie/dots-grey.json'}
                        style={{
                            width: 50,
                            height: 50
                        }}/>
                    </div>
                    :
                    <div style={{
                        borderRadius: 10,
                        border: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        {Views.entry({
                            title: 'No Programs Found',
                            subTitle: 'No programs were found in the system',
                            bottomBorder: false,
                            hideIcon: true
                        })}
                    </div>
            }
        </Layer>
    )
}

export const LeadTextMessages = ({ abstract, style, utils }) => {

    const scrollContainer = useRef(null);

    const [account, setAccount] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [messageText, setMessageText] = useState(null);

    const onNewMessage = data => {
        try {
            if(data.lead_id === abstract.getID()) {
                setMessages(messages => {
                    return update(messages, {
                        $push: [Lead.Message.create({
                            ...data,
                            show_name: messages.length === 0 || messages[messages.length - 1].direction !== data.direction
                        })]
                    });
                });
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    const onSendMessage = async props => {
        try {

            // prevent moving forward if a message was not provided
            if(!messageText) {
                throw new Error('Please type a message before moving on.');
            }

            // prevent moving forward if a credits method was not selected
            if(!account) {
                throw new Error('Please select your preferred credits account before moving on.');
            }

            if(account.balance <= 0) {
                throw new Error('The selected credits account has an insufficient balance.');
            }

            // set loading flag and submit request to server
            setLoading('send');
            let { balance, failed, messages } = await Request.post(utils, '/leads/', {
                lead_ids: [abstract.getID()],
                message: messageText,
                method: {
                    dealership_id: account.dealership_id,
                    user_id: account.user_id
                },
                type: 'notify_sms',
                ...props
            })

            // end loading and check if the request failed
            setLoading(false);
            if(failed > 0) {
                throw new Error('Please verify that the phone number is valid and complete.');
            }

            // add message to list of messages
            setMessages(targets => {

                // loop through message results and append messages that are not already in the local state
                messages.forEach(message => {
                    let match = targets.find(m => m.id === message.id);
                    if(!match) {
                        targets.push(Lead.Message.create(message));
                    }
                });

                // sort messages by date ascending and determine if a name should be shown for the message entry
                return targets.sort((a,b) => {
                    return a.date > b.date;
                }).map((message, index) => {
                    message.show_name = index === 0 || targets[index - 1].direction !== message.direction;
                    return message;
                });
            });

            // clear message text field
            setMessageText(null);

            // update balance for selected credits method
            setAccounts(accounts => {
                return accounts.map(act => {
                    if(act.id === account.id) {
                        act.balance = balance;
                    }
                    return act;
                });
            });

        } catch(e) {

            // end loading and check for do not call list conflicts
            setLoading(false);
            if(e.code === 409) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: `There was an issue sending your message. ${e.message || 'An unknown error occurred'}`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Continue',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Send Message',
                        style: 'default'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            onSendMessage({ do_not_call_override: true });
                            return;
                        }
                    }
                })
                return;
            }

            // fallback to standard request error
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending your message. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSettingsClick = evt => {
        utils.sheet.show({
            items: accounts.map(act => ({
                key: act.id,
                title: act.dealership_id ? 'Use Dealership Credits' : 'Use Personal Credits'
            })).concat([{
                key: 'about',
                title: 'About Text Messaging'
            },{
                key: 'manage',
                title: 'Manage Credits'
            }]),
            position: 'bottom',
            target: evt.target
        }, key => {

            // determine if a credits account was selected and save selected account for future use
            if(key.includes('dealership') || key.includes('user')) {
                Cookies.set('leads.sms.last_used_credits_account_id', key);
                setAccount(accounts.find(act => act.id === key));
                return;
            }

            if(key === 'about') {
                utils.alert.show({
                    title: 'About Text Messaging',
                    message: 'Global Data provides one-way and two-way mass text messaging capabilities to help you communicate with your leads. Lead text messaging supports the same dynamic keywords that you use with your lead scripts so you can tailor your content to each individual lead.'
                })
                return;
            }

            if(key === 'manage') {
                let user = utils.user.get();
                utils.layer.open({
                    abstract: Abstract.create({
                        object: user,
                        type: 'user',
                    }),
                    Component: PaymentsOverview,
                    id: `payments_overview_${user.user_id}`
                });
                return;
            }
        });
    }

    const onUpdateScrollOffset = () => {
        if(scrollContainer.current) {
            scrollContainer.current.scrollTo(0, scrollContainer.current.scrollHeight);
        }
    }

    const getCreditsAccount = () => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <span style={{
                    ...Appearance.textStyles.title()
                }}>{account ? (account.dealership_id ? 'Dealership Credits' : 'Personal Credits') : 'No Credits Account Selected'}</span>
                <span style={{
                    ...Appearance.textStyles.subTitle()
                }}>{`Balance: `}
                    <span style={{
                        color: account && account.balance > 0 ? Appearance.colors.green : Appearance.colors.red
                    }}>{Utils.toCurrency(account && account.balance || 0)}</span>
                </span>
            </div>
        )
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('leads', 'on_new_sms_message', onNewMessage);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('leads', 'on_new_sms_message', onNewMessage);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchCreditAccounts = async () => {
        try {

            // fetch last usedd credits account
            let accountID = Cookies.get('leads.sms.last_used_credits_account_id');

            // send request to server
            setLoading(true);
            let { credits } = await Request.get(utils, '/payments/', {
                type: 'credits'
            });

            // update local state with credits account options and set selected account as the dealership account
            setLoading(false);
            setAccounts(credits);
            setAccount(() => {
                let match = credits.find(acct => accountID && acct.id === accountID);
                if(match) {
                    return match;
                }
                return credits.find(acct => acct.dealership_id ? true : false);
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your credit account options. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchMessages = async () => {
        try {
            let { messages } = await Request.get(utils, '/leads/', {
                id: abstract.getID(),
                mark_as_read: true,
                type: 'sms_thread'
            });

            // update local state with messages
            setMessages(messages.map(message => Lead.Message.create(message)));

            // update unread sms flag for target and notify subscribers of data change if applicable
            if(abstract.object.unread_sms === true) {
                abstract.object.unread_sms = false;
                utils.content.update(abstract);
            } 

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the messages list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        onUpdateScrollOffset();
    }, [messages]);

    useEffect(() => {
        connectToSockets();
        fetchCreditAccounts();
        fetchMessages();
        return disconnectFromSockets;
    }, []);

    return messages.length > 0 && (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            ...style
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 12,
                width: '100%'
            }}>
                {getCreditsAccount()}
                <img
                className={'text-button'}
                onClick={onSettingsClick}
                src={'images/settings-button-grey.png'}
                style={{
                    height: 30,
                    width: 30
                }} />
            </div>
            <div 
            className={'custom-scrollbars'}
            ref={scrollContainer}
            style={{
                borderTop: `1px solid ${Appearance.colors.divider()}`,
                maxHeight: window.innerHeight / 3,
                overflowY: 'scroll',
                padding: 12,
                position: 'relative',
                width: '100%'
            }}>
                {messages.map((message, index) => {
                    return (
                        <MessageComponent
                        key={index}
                        message={message}
                        utils={utils}
                        style={{
                            marginBottom: index === messages.length - 1 ? 0 : 8
                        }} />
                    )
                })}
                {loading === 'send' && (
                    <div style={{
                        bottom: 0,
                        height: 2,
                        left: 0,
                        overflow: 'hidden',
                        position: 'absolute',
                        right: 0,
                        width: '100%'
                    }}>
                        <ProgressBar 
                        barColor={Appearance.colors.blue} 
                        trackColor={Appearance.colors.divider()}/>
                    </div>
                )}
            </div>
            <div style={{
                alignItems: 'center',
                borderTop: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'row',
                padding: 12,
                width: '100%'
            }}>
                <TextField
                containerStyle={{
                    marginRight: 8
                }}
                onChange={setMessageText}
                placeholder={'Type your message here...'}
                value={messageText} />
                <Button
                color={'primary'}
                label={'Send'}
                loading={loading === 'send'}
                onClick={() => onSendMessage()}
                type={'large'} />
            </div>
        </div>
    )
}

export const LeadTextMessageManager = ({ onClose, leads, targets }, { index, options, utils }) => {

    const layerID = 'lead_text_message_manager';

    const [cost, setCost] = useState(0);
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [message, setMessage] = useState(null);
    const [creditMethod, setCreditMethod] = useState(null);

    const onSendMessages = () => {
        if(!message) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please type a message to send to your Leads before moving on'
            });
            return;
        }
        if(!creditMethod) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please select your preferred credits before moving on'
            });
            return;
        }

        let items = targets || leads;
        utils.alert.show({
            title: 'Confirm',
            message: `You have requested that we send ${items.length} text ${items.length === 1 ? 'message' : 'messages'}. The service cost of ${getTotalCost()} will be deducted from your ${creditMethod.dealership_id ? `dealership's ` : ''}credits.`,
            buttons: [{
                key: 'confirm',
                title: 'Confirm and Send',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Send',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSendMessagesConfirm();
                    return;
                }
            }
        })
    }

    const onSendMessagesConfirm = async props => {
        try {
            setLoading('send');
            await Utils.sleep(0.25);

            let { balance, cost, failed, sent, total } = await Request.post(utils, '/leads/', {
                type: 'notify_sms',
                message: message,
                lead_ids: leads && leads.map(lead => lead.id),
                targets: targets,
                method: {
                    user_id: creditMethod.user_id,
                    dealership_id: creditMethod.dealership_id
                },
                ...props
            })

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `We have successfully sent ${sent} out of ${total} messages to your ${total === 1 ? 'lead' : 'leads'}.${failed > 0 ? `There was an issue sending ${failed} ${failed === 1 ? 'message':'message'} due to invalid or incomplete phone numbers.` : ''} The final cost of ${Utils.toCurrency(cost)} has been deducted from your credits and you have a remaining balance of ${Utils.toCurrency(balance)}.`,
                onClick: () => {
                    setLayerState('close');
                    if(typeof(onClose) === 'function') {
                        onClose();
                    }
                }
            });

        } catch(e) {
            // check for do not call list conflicts
            setLoading(false);
            if(e.code === 409) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: `There was an issue sending your messages. ${e.message || 'An unknown error occurred'}`,
                    buttons: [{
                        key: 'confirm',
                        title: 'Continue',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Send Messages',
                        style: 'default'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            onSendMessagesConfirm({ do_not_call_override: true });
                            return;
                        }
                    }
                })
                return;
            }
            // fallback to standard request error
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending your messages. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        let items = targets || leads;
        return [{
            key: 'send',
            text: `Send ${items.length === 1 ? 'Message':'Messages'}`,
            color: 'primary',
            loading: loading === 'send',
            onClick: onSendMessages
        }];
    }

    const getFields = () => {
        let items = targets || leads;
        return [{
            key: 'overview',
            title: 'Overview',
            items: [{
                key: 'count',
                title: 'Queued to Send',
                value: `${items.length} ${items.length === 1 ? 'message':'messages'}`
            },{
                key: 'total',
                title: 'Estimated Total Cost',
                value: getTotalCost()
            }]
        }]
    }

    const getTitle = () => {
        let items = targets || leads;
        return `Send Lead Text ${items.length === 1 ? 'Message' : 'Messages'}`
    }

    const getTotalCost = () => {
        if(cost === 0) {
            return 'Calculating...';
        }
        let items = targets || leads;
        return Utils.toCurrency(items.length * cost);
    }

    const getTotalCostValue = () => {
        let items = targets || leads;
        return items.length * cost;
    }

    const fetchSMSCost = async () => {
        try {
            let { cost } = await Request.get(utils, '/utils/', {
                type: 'sms_cost'
            })
            setCost(cost);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the sms per message cost. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            });
        }
    }

    useEffect(() => {
        fetchSMSCost();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={getTitle()}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <FieldMapper
            utils={utils}
            fields={getFields()} />

            <CreditsPicker
            utils={utils}
            cost={getTotalCostValue()}
            onChange={method => setCreditMethod(method)} />

            <LayerItem
            title={'Message Content'}>
                <TextView
                placeholder={'Type your message here'}
                onChange={text => setMessage(text)} />
            </LayerItem>
        </Layer>
    )
}

export const LeadTransferDetails = ({ abstract, index, options, utils }) => {

    const layerID = `lead_transfer_details_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onCancelTransfers = () => {
        utils.alert.show({
            title: 'Cancel Transfers',
            message: `Are you sure that you want to cancel this transfer? This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onCancelTransfersConfirm();
                    return;
                }
            }
        })
    }

    const onCancelTransfersConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            let { lead_status } = await Request.post(utils, '/leads/', {
                status: Lead.Transfer.status.inactive,
                transfer_ids: [abstract.getID()],
                type: 'set_batch_transfer_status'
            });

            // update lead transfer object
            abstract.object.status = lead_status;
            utils.content.update(abstract);

            // update transfer details for lead
            abstract.object.lead.transfer = {
                ...abstract.object.lead.transfer,
                id: abstract.getID(),
                status: lead_status
            };

            // standard update for target
            let target = Abstract.create({
                type: 'lead',
                object: abstract.object.lead
            });
            utils.content.update(target);

            setLoading(false);
            utils.content.fetch('lead')
            utils.alert.show({
                title: 'All Done!',
                message: `This lead transfer has been cancelled`,
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling this transfer. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDealershipClick = async id => {
        try {
            let dealership = await Dealership.get(utils, id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: dealership,
                    type: 'dealership'
                }),
                Component: DealershipDetails,
                id: `dealership_details_${id}`
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this Dealership. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetTransferStatus = status => {
        utils.alert.show({
            title: `${status === Lead.Transfer.status.accepted ? 'Accept' : 'Decline'} Lead`,
            message: `Are you sure that you want to ${status === Lead.Transfer.status.accepted ? 'accept' : 'decline'} this lead? ${status === Lead.Transfer.status.accepted ? `This will add the dead to your dealership and remove it from the ${abstract.object.from_dealership.name} dealership` : `This will return the lead back to the ${abstract.object.from_dealership.name} dealership`}`,
            buttons: [{
                key: 'confirm',
                title: status === Lead.Transfer.status.accepted ? 'Accept' : 'Decline',
                style: status === Lead.Transfer.status.accepted ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: `Do Not ${status === Lead.Transfer.status.accepted ? 'Accept' : 'Decline'}`,
                style: status === Lead.Transfer.status.accepted ? 'destructive' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetTransferStatusConfirm(status);
                    return;
                }
            }
        })
    }

    const onSetTransferStatusConfirm = async code => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            let { lead_status } = await Request.post(utils, '/leads/', {
                status: code,
                transfer_ids: [abstract.getID()],
                type: 'set_batch_transfer_status'
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This lead has been ${code === Lead.Transfer.status.accepted ? `accepted and has been added to your dealership` : `declined and returned back to ${abstract.object.from_dealership.name}`}`,
                onClick: () => {
                    setLayerState('close');
                }
            })

            // update lead transfer object
            abstract.object.status = lead_status;
            utils.content.update(abstract);

            // update transfer details for lead
            abstract.object.lead.transfer = {
                ...abstract.object.lead.transfer,
                id: abstract.getID(),
                status: lead_status
            };

            // update dealership details for lead if transfer was accepted
            if(code === Lead.Transfer.status.accepted) {

                abstract.object.lead.dealership = utils.dealership.get();
                abstract.object.lead.dealership_id = utils.dealership.get().id;
                abstract.object.lead.status = lead_status;

                let target = Abstract.create({
                    type: 'lead',
                    object: abstract.object.lead
                });
                utils.content.dealership.set(target, utils.dealership.get());
                return;
            }

            // standard update for target
            let target = Abstract.create({
                type: 'lead',
                object: abstract.object.lead
            });
            utils.content.update(target);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${code === Lead.Transfer.status.accepted ? 'accepting' : 'declining'} this transfer. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUserClick = async id => {
        try {
            let user = await User.get(utils, id);
            utils.layer.open({
                abstract: Abstract.create({
                    object: user,
                    type: 'user'
                }),
                Component: UserDetails,
                id: `user_details_${id}`,
                permissions: ['users.details']
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this user. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {

        if(abstract.object.status.code === Lead.Transfer.status.in_progress && abstract.object.from_dealership.id !== utils.dealership.get().id) {
            return [{
                key: 'decline',
                text: 'Decline',
                color: 'danger',
                onClick: onSetTransferStatus.bind(this, Lead.Transfer.status.declined)
            },{
                key: 'accept',
                text: 'Accept',
                color: 'primary',
                onClick: onSetTransferStatus.bind(this, Lead.Transfer.status.accepted)
            }];
        }
        if(abstract.object.from_dealership.id === utils.dealership.get().id) {
            return [{
                key: 'inactive',
                text: 'Cancel Transfer',
                color: 'danger',
                onClick: onCancelTransfers
            }];
        }
        return null;
    }

    const getDealership = () => {
        return (
            <LayerItem
            title={'From Dealership'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {Views.entry({
                        title: abstract.object.from_dealership.name,
                        subTitle: Utils.formatAddress(abstract.object.from_dealership.address),
                        hideIcon: true,
                        bottomBorder: false,
                        bottomBorder: false,
                        onClick: onDealershipClick.bind(this, abstract.object.from_dealership.id)
                    })}
                </div>
            </LayerItem>
        )
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Transfer Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: abstract.getID()
            },{
                key: 'full_name',
                title: 'Lead Name',
                value: abstract.object.lead.full_name
            },{
                key: 'date',
                title: 'Date',
                value: Utils.formatDate(abstract.object.date)
            },{
                key: 'status',
                title: 'Status',
                value: abstract.object.status.text
            }]
        }]
    }

    const getSender = () => {
        return (
            <LayerItem
            title={'Sent By'}
            collapsed={false}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: {
                            path: abstract.object.user.avatar
                        },
                        onClick: onUserClick.bind(this, abstract.object.user.user_id),
                        subTitle: abstract.object.user.email_address,
                        title: abstract.object.user.full_name
                    })}
                </div>
            </LayerItem>
        )
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Lead Transfer Deatails for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            {getSender()}
            {getDealership()}

            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const SetLeadStatus = ({ abstract, index, options, utils }) => {

    const layerID = `set_lead_status_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [items, setItems] = useState([]);

    const onArchiveLead = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.5);
            await Request.post(utils, '/leads/', {
                active: false,
                id: abstract.getID(),
                type: 'set_archive_status'
            });

            // close layer and show confirmation alert
            setLayerState('close');
            utils.alert.show({
                title: 'All Done!',
                message: 'The lead has been moved to the archive list. You can move this lead back into your active lead pool at any time by finding this lead in the archive list and selecting "Unarchive".'
            });
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue archiving this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onPromptLeadArchive = () => {
        let status = utils.dealership.status_codes.get().find(status => status.code === selectedStatus);
        utils.alert.show({
            title: 'All Done!',
            message: `The status for this lead has been updated to "${status.text}" and could benefit from being moved to the archive list. Moving non-actionable leads to the archive list helps keep your dealership running smoothly and reduces your monthly active lead list costs. Would you like to archive this lead?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onArchiveLead();
                    return;
                }
                setLayerState('close');
            }
        });
    }

    const onPromptNewCallLog = () => {
        utils.alert.show({
            title: 'All Done!',
            message: 'The status for this lead has been updated. Would you like to setup a time and date for the next call?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onClick: async key => {
                try {
                    setLayerState('close');
                    if(key === 'confirm') {
                        await Utils.sleep(0.25);
                        utils.layer.open({
                            abstract: Abstract.create({
                                object: CallLog.new(),
                                type: 'call_log'
                            }),
                            Component: AddEditCallLog.bind(this, {
                                isNewTarget: true,
                                lead: abstract.object
                            }),
                            id: `new_call_log_${abstract.getID()}`,
                            permissions: ['calls.actions.new']
                        })
                    }
                } catch(e) {
                    console.error(e.message);
                }
            }
        });
    }

    const onSetStatus = async () => {
        try {

            // send request to server
            setLoading('done');
            let { status } = await Request.post(utils, '/leads/', {
                id: abstract.getID(),
                status: selectedStatus,
                type: 'set_status'
            })

            // end loading and update abstract target with new status object
            setLoading(false);
            abstract.object.status = status;

            // set sale date value based on requested status
            abstract.object.sale_date = status === Lead.status.get().sale ? moment.utc().local() : null;

            // notify subscribers that status has changed
            utils.content.update({
                object: {
                    id: abstract.getID(),
                    status: status
                },
                type: 'lead_status'
            });

            // prompt call creation if status is "recall"
            if(selectedStatus === Lead.status.get().recall) {
                onPromptNewCallLog();
                return;
            }

            // prompt lead to be archived if a matching status was selected
            if(Lead.status.shouldRequestArchive(selectedStatus) === true) {
                onPromptLeadArchive();
                return;
            }

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: 'The status for this lead has been updated',
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this lead. ${e.message || 'An unknown error occurred'}`,
            });
        }
    }

    const getButtons = () => {
        return [{
            color: selectedStatus ? 'primary' : 'dark',
            key: 'done',
            loading: loading === 'done',
            onClick: onSetStatus,
            text: 'Save Changes'
        }];
    }

    const setupStatusCodes = () => {
        let codes = utils.dealership.status_codes.get().filter(status => {
            return status.capabilities.leads;
        }).map(status => ({
            id: status.code,
            title: status.text
        }));
        setItems(codes);
    }

    useEffect(() => {
        setupStatusCodes();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Set Lead Status'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'small'
        }}>
            <select
            className={`custom-select ${window.theme}`}
            defaultValue={'Choose a status...'}
            onChange={e => {
                let id = Utils.attributeForKey.select(e, 'id');
                setSelectedStatus(parseInt(id));
            }}
            style={{
                width: '100%'
            }}>
                <option disabled={true}>{'Choose a status...'}</option>
                {items.map((item, index) => {
                    return (
                        <option key={index} id={item.id}>{item.title}</option>
                    )
                })}
            </select>
        </Layer>
    )
}

export const UnassignLeads = ({ index, options, utils }) => {

    const layerID = 'unassign_leads';

    const [layerState, setLayerState] = useState(null);
    const [leadType, setLeadType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [statusCode, setStatusCode] = useState(null);
    const [user, setUser] = useState(null);

    const onUnassignLeads = () => {
        if(!user) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please select a user before moving on.'
            });
            return;
        }
        utils.alert.show({
            title: 'Unassign Leads',
            message: `Are you sure that you want to unassign all matching leads from ${user.full_name}? This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUnassignLeadsConfirm();
                    return;
                }
            }
        });
    }

    const onUnassignLeadsConfirm = async () => {
        try {
            setLoading('submit');
            let { background, count, estimated_duration } = await Request.post(utils, '/leads/', {
                lead_type: leadType && leadType.id,
                status_code: statusCode && statusCode.id,
                type: 'batch_unassign_user',
                user_id: user.user_id
            });

            // determine if request is going to be processed in the background
            setLoading(false);
            if(background) {
                utils.alert.show({
                    title: 'Request Submitted',
                    message: `We have received your request to unassign ${count} ${count === 1 ? 'lead' : 'leads'} from ${user.full_name} and will notify you when it has completed. The estimated time to remove your assignments is about ${Utils.parseDuration(estimated_duration)}.`
                });
                return;
            }

            // show default confirmation alert for foreground task 
            utils.alert.show({
                title: 'All Done!',
                message: `We have unassigned ${count} ${count === 1 ? 'lead' : 'leads'} from ${user.full_name}. ${count === 1 ? 'This lead is' : 'These leads are'} ready to be reassigned to another user.`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue unassigning the leads from ${user.full_name}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'submit',
            text: 'Unassign',
            color: 'danger',
            loading: loading === 'submit',
            onClick: onUnassignLeads
        }];
    }

    const getFields = () => {
        return [{
            key: 'options',
            title: 'Options',
            items: [{
                key: 'user',
                title: 'User',
                description: 'This will be the user that we look for when removing assignments from the leads in your dealership.',
                component: 'user_lookup',
                value: user,
                onChange: setUser
            },{
                key: 'lead_type',
                required: false,
                title: 'Lead Type',
                description: 'If needed, you can remove the assignments from leads matching a specific lead type while leaving other lead types untouched.',
                component: 'lead_type_picker',
                value: leadType,
                onChange: setLeadType
            },{
                key: 'status',
                required: false,
                title: 'Status',
                description: 'If needed, you can target leads that have a specific status.',
                component: 'list',
                value: statusCode && statusCode.title,
                onChange: setStatusCode,
                items: utils.dealership.status_codes.get().map(entry => ({
                    id: entry.code,
                    title: entry.text
                }))
            }]
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        utils={utils}
        title={'Unassign Leads from User'}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

// Components
export const getLeadScore = lead => {
    if(!lead || !lead.lead_score) {
        return null;
    }

    let color = Appearance.colors.grey();
    switch(lead.lead_score) {
        case 'A':
        color = Appearance.colors.green;
        break;

        case 'B':
        color = Appearance.colors.yellow;
        break;

        case 'C':
        color = Appearance.colors.orange;
        break;

        case 'D':
        color = Appearance.colors.grey();
        break;

        case 'F':
        color = Appearance.colors.red;
        break;
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            maxWidth: 95,
            textAlign: 'center',
            border: `1px solid ${color}`,
            background: Appearance.colors.softGradient(color),
            borderRadius: 5,
            overflow: 'hidden'
        }}>
            <span style={{
                ...Appearance.textStyles.subTitle(),
                color: 'white',
                fontWeight: '600',
                width: '100%'
            }}>{lead.lead_score}</span>
        </div>
    )
}

export const getLeadStatus = (utils, lead, options = {}) => {

    // prevent moving forward if no lead status was provided
    if(!lead || !lead.status) {
        return null;
    }

    // prepare status click handler
    const onStatusClick = evt => {

        // prevent root component from triggering click event
        evt.stopPropagation();

        // only allow editing if options object does not contain a false editing flag
        if(options.editable !== false) {
            utils.layer.open({
                id: `set_lead_status_${lead.id}`,
                abstract: Abstract.create({
                    object: lead,
                    type: 'lead'
                }),
                target: evt.target,
                Component: SetLeadStatus
            });
        }
    }

    return (
        <div
        className={options.editable === false ? '' : 'text-button'}
        onClick={onStatusClick}
        style={{
            alignItems: 'center',
            background: Appearance.colors.softGradient(lead.status.color),
            border: `1px solid ${lead.status.color}`,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            maxWidth: 95,
            overflow: 'hidden',
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'center',
            width: '100%'
        }}>
            <span style={{
                ...Appearance.textStyles.subTitle(),
                color: 'white',
                fontWeight: '600',
                width: '100%'
            }}>{lead.status.text}</span>
        </div>
    )
}

export const getLeadType = lead => {
    if(!lead || !lead.lead_type) {
        return null;
    }
    return lead.lead_type.text;
}

export const showBatchLeadOptions = ({ evt, leads, onChange, props, selectAll, setLoading, utils }) => {

    const onArchiveLeads = async () => {
        utils.alert.show({
            title: 'Archive Leads',
            message: `Are you sure that you want to archive the selected ${leads.length === 1 ? 'lead' : 'leads'}?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Archive',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onArchiveLeadsConfirm();
                    return;
                }
            }
        })
    }

    const onArchiveLeadsConfirm = async () => {
        try {
            setLoading(true);
            let { background, threshold } = await Request.post(utils, '/leads/', {
                ids: leads.map(lead => lead.id),
                type: 'batch_archive'
            });

            // end loading and notify listeners of change
            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange();
            }

            // determine if request is going to be processed in the background
            if(background) {
                utils.alert.show({
                    title: 'Request Submitted',
                    message: `We have received your request to archive ${leads.length} ${leads.length === 1 ? 'lead' : 'leads'} and will notify you when it has completed. Request for ${Utils.softNumberFormat(threshold)} or more can take up to a minute to complete.`
                });
                return;
            }

            // show default confirmation alert for foreground task 
            utils.alert.show({
                title: 'All Done!',
                message: `We have archived ${leads.length} ${leads.length === 1 ? 'lead' : 'leads'}. You can move ${leads.length === 1 ? 'this lead' : 'these leads'} back into your active lead pool at any time by finding ${leads.length === 1 ? 'this lead' : 'these leads'} in the archive list and selecting "Unarchive".`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue archiving ${leads.length === 1 ? 'this lead' : 'these leads'}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onAssignLeads = () => {
        utils.layer.open({
            id: 'assign_leads',
            Component: AssignLeads.bind(this, {
                leads: leads,
                onClose: onChange
            })
        });
    }

    const onAssignLeadScript = async () => {
        try {
            let selected = null;
            utils.alert.show({
                title: 'Assign Lead Script',
                message: 'Please choose a lead script from the list below to continue.',
                content: (
                    <div style={{
                        width: '100%',
                        padding: 12
                    }}>
                        <LeadScriptPickerField
                        utils={utils}
                        onChange={script => selected = script} />
                    </div>
                ),
                buttons: [{
                    key: 'confirm',
                    title: 'Done',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'default'
                }],
                onClick: key => {
                    if(selected && key === 'confirm') {
                        onAssignLeadScriptConfirm(selected);
                        return;
                    }
                }
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the lead script selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onAssignLeadScriptConfirm = async script => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/leads/', {
                type: 'batch_script_assignment',
                ids: leads.map(lead => lead.id),
                script_id: script.id
            });

            // end loading and notify listeners of change
            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange();
            }

            // notify subscribers of data change and show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `The "${script.title}" lead script has been set for ${leads.length} ${leads.length === 1 ? 'lead' : 'leads'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue assigning the lead script. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDeleteLeads = () => {
        utils.alert.show({
            title: `Delete ${leads.length} ${leads.length === 1 ? 'lead' : 'leads'}`,
            message: `Are you sure that you want to delete ${leads.length === 1 ? 'this lead' : 'these leads'} from your dealership? This will also remove any demos, demo requests, and call logs where ${leads.length === 1 ? 'this lead is' : 'these leads are'} attached. This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteLeadsConfirm();
                    return;
                }
            }
        });
    }

    const onDeleteLeadsConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            let { call_logs, demos, demo_requests } = await Request.post(utils, '/leads/', {
                ids: leads.map(lead => lead.id),
                type: 'batch_delete'
            });

            // end loading and notify listeners of change
            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange();
            }

            // show confirmation alert to user where only the lead was delete if applicable
            if(call_logs === 0 && demos === 0 && demo_requests === 0) {
                utils.alert.show({
                    title: 'All Done!',
                    message: `${leads.length === 1 ? 'This lead has' : 'These leads have'} been deleted from your dealership`,
                    onClick: utils.content.fetch.bind(this, 'lead')
                });
                return;
            }

            // prepare array of targets representing the quantity removed
            let targets = [{
                title: 'call log',
                value: call_logs
            },{
                title: 'demo',
                value: demos
            },{
                title: 'demo request',
                value: demo_requests
            }];

            // prepare an array of messages representing the targets removed
            let messages = targets.reduce((array, target) => {
                if(target.value > 0) {
                    array.push(`${target.value} ${target.value === 1 ? target.title : `${target.title}s`}`);
                }
                return array;
            }, []);
            
            // show confirmation alert to user with removal totals
            utils.alert.show({
                title: 'All Done!',
                message: `${leads.length === 1 ? 'This lead has' : 'These leads have'} been deleted from your dealership along with ${Utils.oxfordImplode(messages)}`,
                onClick: utils.content.fetch.bind(this, 'lead')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this lead. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDownloadLeads = async () => {
        try {
            setLoading(true);
            let { url } = await Request.post(utils, '/leads/', {
                all_leads: selectAll,
                lead_ids: selectAll === false && leads.map(lead => lead.id),
                type: 'download'
            });

            // end loading and notify listeners of change
            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange();
            }

            // open download url in new tab
            utils.alert.show({
                title: 'Download Ready',
                message: 'Your document has been prepare and is ready to download. Click the button below to begin your download.',
                buttons: [{
                    key: 'download',
                    title: 'Start Download',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'download') {
                        window.open(url);
                        return;
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your download. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onPrintLeads = () => {
        utils.layer.open({
            id: 'print_content',
            Component: PrintContent.bind(this, {
                title: 'Leads',
                onFetch: async () => new Promise(resolve => resolve(leads)), // promise used to conform to panel print requirements
                onRenderItem: item => ({
                    full_name: item.full_name,
                    address: item.address ? Utils.formatAddress(item.address) : null,
                    phone_number: item.phone_number ? item.phone_number : null,
                    ...props && props.follow_ups === true && {
                        last_updated: item.extended_details && item.extended_details.sys_evt_date ? Utils.formatDate(item.extended_details.sys_evt_date) : null,
                    },
                    status: getLeadStatus(utils, item)
                }),
                headers: [{
                    key: 'full_name',
                    title: 'Name'
                },{
                    key: 'address',
                    title: 'Address'
                },{
                    key: 'phone_number',
                    title: 'Phone Number'
                },{
                    key: 'last_updated',
                    title: 'Last Updated',
                    visible: props && props.follow_ups === true
                },{
                    key: 'status',
                    title: 'Status'
                }]
            })
        });
    }

    const onTransferLeads = () => {

        let dealership = null;
        utils.alert.show({
            title: 'Transfer Leads',
            message: `You can select one or more leads to transfer to another dealership. We'll send a request to the other dealership that they can accept or decline. If accepted, the leads will be removed from your dealership and added to the recipient's dealership. If declined, the leads will not be transfered out of your dealership.`,
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <DealershipLookupField
                    containerStyle={{
                        width: '100%'
                    }}
                    globalVisibility={true}
                    icon={'search'}
                    inline={true}
                    onChange={result => dealership = result}
                    utils={utils}/>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: `Send ${leads.length} ${leads.length === 1 ? 'lead' : 'leads'}`,
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Send Leads',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm' && dealership) {
                    onTransferLeadsConfirm(dealership);
                    return;
                }
            }
        })
    }

    const onTransferLeadsConfirm = async dealership => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/leads/', {
                destination_dealership_id: dealership.id,
                ids: leads.map(lead => lead.id),
                type: 'new_transfer'
            });

            // end loading and notify listeners of change
            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange();
            }

            // show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `We have sent ${leads.length} ${leads.length - 1 ? 'lead' : 'leads'} to the ${dealership.name} dealership. We'll notify your dealership when they accept or decline this transfer.`,
                onClick: utils.content.fetch.bind(this, 'lead')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue transferring your leads. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onResort = () => {
        utils.alert.show({
            title: 'Resort Leads',
            message: 'Are you sure that you want to resort these leads? This will remove the general assignment, marketing director assignment, and set the status for each lead to "New".',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onResortConfirm();
                    return;
                }
            }
        });
    }

    const onResortConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/leads/', {
                ids: leads.map(lead => lead.id),
                type: 'batch_resort'
            });

            // end loading and notify listeners of change
            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange();
            }

            // notify subscribers of data change and show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `We have successfully resorted ${leads.length} ${leads.length === 1 ? 'lead' : 'leads'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue resorting these leads. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSendSms = () => {
        utils.layer.open({
            id: 'lead_text_message_manager',
            Component: LeadTextMessageManager.bind(this, {
                leads: leads,
                onClose: onChange
            })
        });
    }

    const onSetRelease = release => {
        utils.alert.show({
            title: `${release ? 'Release' : 'Unrelease'}`,
            message: `Are you sure that you want to ${release ? 'release' : 'unrelease'} these Leads? ${release ? 'This will open these leads up to the rest of the dealership.' : 'This will prevent others in the dealership from viewing these leads.'}`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: release ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: `Do Not ${release ? 'Release': 'Unrelease'}`,
                style: release ? 'destructive' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetReleaseConfirm(release);
                    return;
                }
            }
        })
    }

    const onSetReleaseConfirm = async release => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/leads/', {
                type: 'set_release_batch',
                ids: leads.map(lead => lead.id),
                release: release
            });

            // end loading and notify listeners of change
            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange();
            }

            // notify subscribers of data change and show confirmation alert
            utils.alert.show({
                title: 'All Done!',
                message: `${leads.length === 1 ? 'This lead has' : 'These Leads have'} been ${release ? `released and ${leads.length === 1 ? 'is' : 'are'} avaiable for others to view.` : `unreleased and ${leads.length === 1 ? 'is' : 'are'} no longer available for others to view.`}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the release status for these leads. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUnarchiveLeads = async () => {
        utils.alert.show({
            title: 'Unarchive Leads',
            message: `Are you sure that you want to unarchive the selected ${leads.length === 1 ? 'lead' : 'leads'}? This will return the leads back to their leads pools.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Archive',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUnarchiveLeadsConfirm();
                    return;
                }
            }
        })
    }

    const onUnarchiveLeadsConfirm = async () => {
        try {
            setLoading(true);
            let { background, threshold } = await Request.post(utils, '/leads/', {
                type: 'batch_unarchive',
                ids: leads.map(lead => lead.id)
            });

            // end loading and notify listeners of change
            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange();
            }

            // determine if request is going to be processed in the background
            if(background) {
                utils.alert.show({
                    title: 'Request Submitted',
                    message: `We have received your request to unarchive ${leads.length} ${leads.length === 1 ? 'lead' : 'leads'} and will notify you when it has completed. Request for ${Utils.softNumberFormat(threshold)} leads or more can take up to a minute to complete.`
                });
                return;
            }
            
            // show default confirmation alert for foreground task 
            utils.alert.show({
                title: 'All Done!',
                message: `We have returned ${leads.length} ${leads.length === 1 ? 'lead' : 'leads'} back to your general lead pool.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue unarchiving ${leads.length === 1 ? 'this lead' : 'these leads'}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUnassignLeads = async () => {
        utils.alert.show({
            title: 'Unassign Leads',
            message: `Are you sure that you want to unassign the selected ${leads.length === 1 ? 'lead' : 'leads'}? This cannot be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUnassignLeadsConfirm();
                    return;
                }
            }
        })
    }

    const onUnassignLeadsConfirm = async () => {
        try {
            setLoading(true);
            let { background, count, estimated_duration } = await Request.post(utils, '/leads/', {
                ids: leads.map(lead => lead.id),
                type: 'batch_unassign'
            });

            // end loading and notify listeners of change
            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange();
            }

            // determine if request is going to be processed in the background
            if(background) {
                utils.alert.show({
                    title: 'Request Submitted',
                    message: `We have received your request to unassign ${Utils.softNumberFormat(count)} ${count === 1 ? 'lead' : 'leads'} and will notify you when it has completed. The estimated time to remove your assignments is about ${Utils.parseDuration(estimated_duration)}.`
                });
                return;
            }

            // show default confirmation alert for foreground task 
            utils.alert.show({
                title: 'All Done!',
                message: `We have removed the assignments for your selected ${leads.length === 1 ? 'lead' : 'leads'}.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue unassigning ${leads.length === 1 ? 'this lead' : 'these leads'}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateDetails = () => {
        utils.layer.open({
            id: `batch_update_lead_details`,
            Component: BatchUpdateLeadDetails.bind(this, {
                ids: leads.map(lead => lead.id),
                onClose: onChange
            })
        });
    }

    // only allow lead transfering for administrators and the dealer of the current dealership
    let dealership = utils.dealership.get();
    let canTransferLeads = utils.user.get().level === User.levels.get().admin || (dealership.dealer && utils.user.get().user_id === dealership.dealer.user_id) ? true : false;

    // standard options for leads within the current dealership
    utils.sheet.show({
        items: [{
            key: 'archive',
            title: 'Archive',
            visible: props && props.show_archived_only === true ? false : true,
            style: 'destructive'
        },{
            key: 'lead_script',
            title: 'Assign Lead Script',
            style: 'default',
            visible: false
        },{
            key: 'assign_user',
            title: 'Assign to User',
            style: 'default'
        },{
            key: 'assign_user',
            title: 'Change Assignments',
            style: 'default',
            visible: false //props && props.status_codes && props.status_codes.includes(Lead.status.get().assigned) ? true : false
        },{
            key: 'delete',
            title: 'Delete',
            style: 'destructive'
        },{
            key: 'download',
            title: 'Download',
            style: 'default',
            visible: utils.user.get().level <= User.levels.get().admin
        },{
            key: 'print',
            title: 'Print',
            style: 'default'
        },{
            key: 'sms',
            title: 'Send Text Message',
            style: 'default',
            visible: props && props.status_codes && props.status_codes.includes(Lead.status.get().do_not_call) ? false : true
        },{
            key: 'transfer',
            title: 'Transfer to Other Dealership',
            style: 'default',
            visible: canTransferLeads
        },{
            key: 'release',
            title: 'Release',
            visible: props && props.released === false ? true : false,
            style: 'default'
        },{
            key: 'resort',
            title: 'Resort',
            style: 'destructive'
        },{
            key: 'unarchive',
            title: 'Unarchive',
            visible: props && props.show_archived_only === true ? true : false,
            style: 'default'
        },{
            key: 'unrelease',
            title: 'Unrelease',
            visible: props && props.released === false ? false : true,
            style: 'destructive'
        },{
            key: 'unassign',
            title: 'Unassign',
            style: 'destructive'
        },{
            key: 'update',
            title: 'Update',
            style: 'default'
        }],
        target: evt && evt.target
    }, key => {
        switch(key) {
            case 'archive':
            onArchiveLeads();
            break;

            case 'assign_user':
            onAssignLeads();
            break;

            case 'delete':
            onDeleteLeads();
            break;

            case 'download':
            onDownloadLeads();
            break;

            case 'lead_script':
            onAssignLeadScript();
            break;

            case 'print':
            onPrintLeads();
            break;

            case 'transfer':
            onTransferLeads();
            break;

            case 'resort':
            onResort();
            break;

            case 'release':
            case 'unrelease':
            onSetRelease(key === 'release');
            break;

            case 'sms':
            onSendSms();
            break;

            case 'unarchive':
            onUnarchiveLeads();
            break;

            case 'unassign':
            onUnassignLeads();
            break;

            case 'update':
            onUpdateDetails();
            break;
        }
    });
}
